export default {
  GET_COUNTRIES: "GET_COUNTRIES",
  GET_COUNTRIES_LIST: "GET_COUNTRIES_LIST",
  GET_CITIES: "GET_CITIES",
  GET_SHIPPING_STATUS: "GET_SHIPPING_STATUS",
  GET_DETAILS: "GET_DETAILS",
  GET_DISTRIBUTION_TEMPERATURE: "GET_DISTRIBUTION_TEMPERATURE",
  GET_DESTINATION_ADDRESS: "GET_DESTINATION_ADDRESS",
  GET_DESTINATION_ADDR_LIST: "GET_DESTINATION_ADDR_LIST",
  DESTINATION_ADDR_SELECT: "DESTINATION_ADDR_SELECT",
  CLEAR_DESTINATION_ADDR_SELECT: "CLEAR_DESTINATION_ADDR_SELECT",
  GET_FINAL_RECIPIENTS: "GET_FINAL_RECIPIENTS",
  CLEAR_FINAL_RECEIPIENT_SELECT: "CLEAR_FINAL_RECEIPIENT_SELECT",
  FINAL_RECIPIENT_SELECT: "FINAL_RECIPIENT_SELECT",
  GET_DEPUTIES: "GET_DEPUTIES",
  ADD_SHIPPING_DEPUTIES_SELECT: "ADD_SHIPPING_DEPUTIES_SELECT",
  CLEAR_SHIPPING_DEPUTIES_SELECT: "CLEAR_SHIPPING_DEPUTIES_SELECT",
  GET_INTERNAL_ORDER_ID_VALUE: "GET_INTERNAL_ORDER_ID_VALUE",
  GET_SHIPMENT_SPECIFIC_VALUE: "GET_SHIPMENT_SPECIFIC_VALUE",
  GET_SHIPPING_CREATE_DATE_VALUE: "GET_SHIPPING_CREATE_DATE_VALUE",
  GET_DISTRIBUTION_TEMP_VALUE: "GET_DISTRIBUTION_TEMP_VALUE",
  GET_SHIPPING_CREATE_COMMENT_VALUE: "GET_SHIPPING_CREATE_COMMENT_VALUE",
  CREATE_SHIPPING: "CREATE_SHIPPING",
  CREATE_SHIPPING_SUBMIT: "CREATE_SHIPPING_SUBMIT",
  GET_SHIPPINGS: "GET_SHIPPINGS",
  GET_SHIPPING_METERIALS: "GET_SHIPPING_METERIALS",
  ADD_SHIPPING_MATERIAL_SELECT: "ADD_SHIPPING_MATERIAL_SELECT",
  GET_MY_SHIPPINGS: "GET_MY_SHIPPINGS",
  SHIPPING_MATERIAL_SELECTED_VALUE: "SHIPPING_MATERIAL_SELECTED_VALUE",
  ADD_MATERIAL_SHIPPING_REQUEST: "ADD_MATERIAL_SHIPPING_REQUEST",
  ADD_DEPUTIES_SHIPPING_REQUEST: "ADD_DEPUTIES_SHIPPING_REQUEST",
  SHIPPING_REQUEST_DETAILS: "SHIPPING_REQUEST_DETAILS",
  DEPUTY_SHIPPING_REQUEST_DETAILS: "DEPUTY_SHIPPING_REQUEST_DETAILS",
  MATERIAL_SHIPPING_REQUEST_DETAILS: "MATERIAL_SHIPPING_REQUEST_DETAILS",
  GET_SHIPPING_ACTION_TYPE: "GET_SHIPPING_ACTION_TYPE",
  UPDATE_SHIPPING: "UPDATE_SHIPPING",
  CLEAR_SHIPPING_MATERIAL_SELECTED_VALUE:
    "CLEAR_SHIPPING_MATERIAL_SELECTED_VALUE",
  MATERIAL_ID_SELECT: "MATERIAL_ID_SELECT",
  SAP_DELIVERY_NUM_VALUE: "SAP_DELIVERY_NUM_VALUE",
  DISPATCH_MOV_VALUE: "DISPATCH_MOV_VALUE",
  DATE_OF_RECEIP_VALUE: "DATE_OF_RECEIP_VALUE",
  DISPATCH_LEV_VALUE: "DISPATCH_LEV_VALUE",
  TRACKING_NUM_VALUE: "TRACKING_NUM_VALUE",
  STATUS_QUO_VALUE: "STATUS_QUO_VALUE",
  GET_PHASESES: "GET_PHASESES",
  PHASES_SELECT_VALUE: "PHASES_SELECT_VALUE",
  UPDATE_MATERIAL: "UPDATE_MATERIAL",
  IS_LOADER: "IS_LOADER",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  PROCESS_SHIPPMENT_LIST: "PROCESS_SHIPPMENT_LIST",
  SET_SHIPPING_SEARCH_DATA: "SET_SHIPPING_SEARH_DATA",
  SAVE_DISTRIBUTION: "SAVE_DISTRIBUTION",
  GET_SHIPPMENT_HISTORY: "GET_SHIPPMENT_HISTORY",
  SET_SHIPPING_LOG_SEARCH: "SET_SHIPPING_LOG_SEARCH",
  DELETE_SHIPPING: "DELETE_SHIPPING",
  CLEAR_SHIPPING_REQUEST: "CLEAR_SHIPPING_REQUEST",
  UPDATE_SHIPPING_REQUEST_VALID: "UPDATE_SHIPPING_REQUEST_VALID",
  GET_REQURST_BY_DEPUTY: "GET_REQURST_BY_DEPUTY",
  SET_SHIPPING_SEARCH_AUTOCOMPLETE_DATA:
    "SET_SHIPPING_SEARCH_AUTOCOMPLETE_DATA",
  SHIPPING_HISTORY: "SHIPPING_HISTORY",
  SHIPPING_COUNTRY: "SHIPPING_COUNTRY",
  SHIPPING_TWO_WEEKS: "SHIPPING_TWO_WEEKS",
  REDIRECT_ACTION: "REDIRECT_ACTION",
  DELETE_ACTION: "DELETE_ACTION",
  SET_ATTACHMENT: "SET_ATTACHMENT",
  SET_SHIPPER: "SET_SHIPPER",
  SET_EXTRA: "SET_EXTRA",
  GET_ALL_CURRENCY: "GET_ALL_CURRENCY",
  GET_ALL_UNITS: "GET_ALL_UNITS",
  GET_ALL_CONTAINER: "GET_ALL_CONTAINER",
  GET_ALL_CONTAINER_LIST:"GET_ALL_CONTAINER_LIST",
  GET_ALL_BROKERS: "GET_ALL_BROKERS",
  GET_INCOTERMS: "GET_INCOTERMS",
  GET_IMPOTER: "GET_IMPOTER",
  SET_ADDRESS_SEARCH_FIELDS: "SET_ADDRESS_SEARCH_FIELDS",
  EXPORT_DATA: "EXPORT_DATA",
  EXPORT_SELECTED_DATA: "EXPORT_SELECTED_DATA",

  DEPUTY_SHIPPING_REQUEST_DETAILS_SELECTED:
    "DEPUTY_SHIPPING_REQUEST_DETAILS_SELECTED",
  GET_DESTINATION_ADDRESS_SEARCH_OPTIONS:
    "GET_DESTINATION_ADDRESS_SEARCH_OPTIONS",
  GET_STUDY_NUMBER_DETAILS: "GET_STUDY_NUMBER_DETAILS",
  GET_SHIPPINGS_TYPE: "GET_SHIPPINGS_TYPE",
  SET_OVERALL_COMMENTS: "SET_OVERALL_COMMENTS",
  SET_MAIL_LINK_DATA: "SET_MAIL_LINK_DATA",
  REVERT_APPROVAL: "REVERT_APPROVAL",
  SET_SHIPPER_COUNTRY: "SET_SHIPPER_COUNTRY",
  SET_MATERIAL_VALID_NEED_DATE: "SET_MATERIAL_VALID_NEED_DATE",
  SAVE_ALERT_DETAILS: "SAVE_ALERT_DETAILS",
  GET_ALERTCOMMENTS_BY_SHIPPINGREQUEST_ID: "GET_ALERTCOMMENTS_BY_SHIPPINGREQUEST_ID",
  GET_ALERT_BY_USERID: "GET_ALERT_BY_USERID",
  UPDATE_COUNT_BY_USERID: "UPDATE_COUNT_BY_USERID",
  SET_NEW_SHIPPING_REQUEST_COMMENTS_LIST: "SET_NEW_SHIPPING_REQUEST_COMMENTS_LIST",
  GET_ALL_USERS: "GET_ALL_USERS",
  SET_MATERIAL_TABLE_OPTIONS: "SET_MATERIAL_TABLE_OPTIONS",
  SET_ADMIN_SHIPPING_TABLE_OPTIONS: "SET_ADMIN_SHIPPING_TABLE_OPTIONS",
  GET_UNREAD_ALERTS_LIST: "GET_UNREAD_ALERTS_LIST",
  UPDATE_SHIPPING_REVIEW_STATUS:"UPDATE_SHIPPING_REVIEW_STATUS",
  GET_LOGISTICS_PARTNER_DICTIONARY_DATA: "GET_LOGISTICS_PARTNER_DICTIONARY_DATA",
  SHIPPING_UNDER_REVIEW_REQUEST_DETAILS:"SHIPPING_UNDER_REVIEW_REQUEST_DETAILS",
  SET_CLIENT_TIMEZONE:"SET_CLIENT_TIMEZONE",
  GET_SHIPPING_REVIEWER_LIST:"GET_SHIPPING_REVIEWER_LIST"
}
