import { React, useState, forwardRef, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { get } from "lodash";
import Model from "../shared/model";
import moment from "moment";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MaterialTable from "@material-table/core";
import editIcon from "../../assests/images/editIcon.svg";
import viewIcon from "../../assests/images/infoReq.svg";
import { useTranslation } from "react-i18next";
import { addressSearchStyles } from "./addressStyles";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import * as config from "../../config";
import { DateFormat,DateWithDefaultTime,DateWithEndTime } from "../common/utils/DateUtil";
import { STATUS, USERROLES, PROCESS_MANAGER, INSPECT } from "../../constants";
import StatusFlow from "../shippingRequest/statusFlow";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import {
  dateSortFunction,
  ifRequestCompletedApprovals,
  sortFunction,
  SortFunctionNew,
} from "../../Services/util";
import { TYPE_OF_SHIPMENT ,months} from "../../constants";
import {
  tableRowFormatter,
  tableRowOrderManager,
} from "../common/utils/TableUtil";
import trans from "../../Translations/english.json";
const status=trans.status;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const ProcessShippingRequestListingComponent = (props) => {
  const [selected, setSelected] = useState("");
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  // const [disableOrderPickup, setDisableOrderPickup] = useState(true);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");
  const [isExportModalVisibleSingle, setIsExportModalVisibleSingle] =
    useState(false);
  var userType = JSON.parse(localStorage.getItem("userType"));
  const {
    processShippmentList,
    actions,
    searchFilter,
    setIsLoading,
    allMaterials,
    handleHandBack,
    handleCancelRequest,
    handleCloseRequest,
    shippingSearchAutocompleteData,
    handlerShippingInspect,
    shippingType,
    tableProcessShippingOptions
  } = props;

  const [filteredData, setfilteredData] = useState([]);
  const classes = addressSearchStyles();
  const history = useHistory();
  const [shippingDetail, setShippingDetail] = useState({});
  const [actionName, setActionName] = useState("");

  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };

  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key] && key !== "searchKey"
    );

    let matchedShippmentIds = false;

    if (validSearchKeys.includes("description")) {
      matchedShippmentIds = shippingSearchAutocompleteData?.materialData
        ?.filter((item) =>
          item.description
            .toLowerCase()
            .includes(searchFilter.description.toLowerCase())
        )
        .map((item) => item.shipping_request_id);
    }
  
    const filteredData = processShippmentList.filter((item) => {

      if((item.status===STATUS.CANCELLED)||(item.status===STATUS.IN_DELIVERY)){
        return false
      }
      if (matchedShippmentIds && !matchedShippmentIds.includes(item.id) ) {
        return false;
      }

      for (let searchParam of validSearchKeys) {

        if (["description"].includes(searchParam)) {
          continue;
        }
        if (["submitted", "approved", "valid","review_by_shipping"].includes(searchParam)) {
          if (searchParam === "review_by_shipping" && item[searchParam] !== null) {
            continue;
          }
          if (item[searchParam] !== 1) {
            return false;
          } else {
            continue;
          }
        }

        if (["startDate", "endDate"].includes(searchParam)) {
          if (!item.created_on) {
            return false;
          }
          const createDate = moment(item.created_on).startOf(
            "day"
          );
          const date = moment(searchFilter[searchParam]).startOf("day");
          if (searchParam === "startDate") {
            if (createDate.isBefore(DateWithDefaultTime(date))) {
              return false;
            }
            continue;
          } else {
            if (createDate.isAfter(DateWithEndTime(date))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["shipmentDateLevStart", "shipmentDateLevEnd"].includes(searchParam)
        ) {
          if (!item.shipment_date_lev) {
            return false;
          }
          const createDate = moment(item.shipment_date_lev).startOf("day");
          const date = moment(searchFilter[searchParam]).startOf("day");
          if (searchParam === "shipmentDateLevStart") {
            if (createDate.isBefore(DateWithDefaultTime(date))) {
              return false;
            }
            continue;
          } else {
            if (createDate.isAfter(DateWithEndTime(date))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["shipmentDateMomStart", "shipmentDateMomEnd"].includes(searchParam)
        ) {
          if (!item.shipment_date_mon) {
            return false;
          }
          const createDate = moment(item.shipment_date_mon).startOf("day");
          const date = moment(searchFilter[searchParam]).startOf("day");
          if (searchParam === "shipmentDateMomStart") {
            if (createDate.isBefore(DateWithDefaultTime(date))) {
              return false;
            }
            continue;
          } else {
            if (createDate.isAfter(DateWithEndTime(date))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["dateOfRecipientStart", "dateOfRecipientEnd"].includes(searchParam)
        ) {
          if (!item.close_date) {
            return false;
          }
          const createDate = moment(item.close_date).startOf("day");
          const date = moment(searchFilter[searchParam]).startOf("day");
          if (searchParam === "dateOfRecipientStart") {
            if (createDate.isBefore(DateWithDefaultTime(date))) {
              return false;
            }
            continue;
          } else {
            if (createDate.isAfter(DateWithEndTime(date))) {
              return false;
            }
            continue;
          }
        }

    if (["final_receipt"].includes(searchParam) && searchFilter[searchParam].length>0) {
          if (
             !searchFilter[searchParam].includes(item["firstname"] + " " + item["lastname"])
          ) {
            return false;
          }
          continue;
        }

      //   if(  searchParam=" internal_distribution_number " &&
      //   !(
      //     item[searchParam] &&
      //     item[searchParam]
      //       .toLowerCase()
      //       .includes(searchFilter[searchParam].toLowerCase())
      //   )
      // ){
      //   return false
      // }

    if (
      searchParam == "internal_distribution_number" && searchFilter[searchParam].length > 0 && (
    !item[searchParam] ||
    !(item[searchParam] + "")
      .toLowerCase()
      .includes(searchFilter[searchParam].toLowerCase())
        
      )
    ) {
      return false;
    }
     if (
      searchParam=="city" && !(
       searchFilter[searchParam].includes(item[searchParam])
     )
   ) {
     return false;
   }
   if (
    searchParam=="tracking_number" && !(
     searchFilter[searchParam]?.replace(/\s+/g,'') == (item[searchParam]?.replace(/\s+/g,''))
   )
 ) {
   return false;
 }
        if (
           searchParam=="status" && searchFilter[searchParam].length>0 && !(
            searchFilter[searchParam].includes(item[searchParam])
          )
        ) {
          return false;
        }
        if (
          searchParam=="name_english" && searchFilter[searchParam].length>0 && (
           !searchFilter[searchParam].includes(item[searchParam])
         )
       ) {
         return false;
       }
       if (
        searchParam=="country_of_shipper" && searchFilter[searchParam].length>0 && (
         !searchFilter[searchParam].includes(item[searchParam])
       )
     ) {
       return false;
     }
     if (
      searchParam=="type_of_shipment" && searchFilter[searchParam].length>0 && item[searchParam]!== null && searchFilter[searchParam] !=="" && searchFilter[searchParam]!==null &&(
        !searchFilter[searchParam].map(word=>word.toLowerCase()).includes(item[searchParam].toLowerCase())
     )
   ) {
     return false;
   }
    }
      return true;
    });

    setfilteredData(
      filteredData.map((val) => ({
        ...val,
        ...val.address_recipient_locked_data,
        ...(val.address_recipient_locked_data
          ? {
              firstname: val.address_recipient_locked_data.first_name,
              lastname: val.address_recipient_locked_data.last_name,
            }
          : null),
        ...(val.shipper_locked_data?.name_english
          ? {
              country_of_shipper: `${val.shipper_locked_data?.name_english}`,
            }
          : null),
        type_of_shipment: !ifRequestCompletedApprovals(val.status)
          ? shippingType.find(
              (item) => item.id === val.type_of_shipment && item.isactive
            )
            ? val.type_of_shipment
            : null
          : val.type_of_shipment,
      }))
    );
  }, [
    searchFilter,
    allMaterials,
    processShippmentList,
    shippingSearchAutocompleteData,
    shippingType,
  ]);
  const pathProcessShippingURLs = [
    "/requestByDelegate",
    "/approveAddress",
    "/approve/materials",
    "/approve/recipients",
    "/address",
    "/materials",
    "/recipients",
    "/shippingRequests",
    "/myShippings",
    "/regulatoryReviewer",
    "/comments",
    "/alerts",
    "/countrycatalouge",
    "/registereduser",
    "/queriesCountry",
    "/queriesCountryTwoWeeks",
    "/myAddress",
    "/my/application/materials",
    "/my/application/recipients",
    "/contact",
    "/phase",
    "/broker",
    "/container",
    "/add-country",
    "/currency",
    "/distributiontemperature",
    "/importerOfRecord",
    "/incoterm",
    "/shippingType",
    "/title",
    "/units",
    "/logistics",
  ]
  const { t, i18n } = useTranslation();
  useEffect(() => {
    return () => {
      if (pathProcessShippingURLs.includes(window.location.pathname)) {
        actions.setProcessShippingTableOptions({});
        actions.setProcessShippingSearchData({});
      }
    };
  }, []);
  const dispatchProcessShippingOptions = (event, value) => {
    if (event.target === "page") {
      return actions.setProcessShippingTableOptions({
        pageNumber: value.pageNumber,
        pageSize: value.pageSize,
        orderBy: tableProcessShippingOptions.orderBy
      });
    }
    if (event.target === "sort") {
      return actions.setProcessShippingTableOptions({
        pageNumber: 0,
        pageSize: tableProcessShippingOptions.pageSize,
        orderBy: value
      });
    }
  }
  const handleRadiobuttonChange = (data) => {
    setSelected(data);
  };

  const handleCSVExport = async () => {
    let dataForExport = exportDataBuilder();
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Process_Shipping_Requests_Data");
    writeFile(wb, requiredFileName + ".xlsx");
  };

  const exportDataBuilder = () => {
    let copyOfFilteredData;
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    } else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    }

    copyOfFilteredData?.forEach((item) => {
      //changing column names
      if (item.created_on) {
        item.created_on = DateFormat(item.created_on, i18n.resolvedLanguage);
      }
      if (item.shipment_date_mon) {
        item.shipment_date_mon = DateFormat(
          item.shipment_date_mon,
          i18n.resolvedLanguage
        );
      }
      if (item.shipment_date_lev) {
        item.shipment_date_lev = DateFormat(
          item.shipment_date_lev,
          i18n.resolvedLanguage
        );
      }
      if (item.date_material_needed_on_site) {
        item.date_material_needed_on_site = DateFormat(
          item.date_material_needed_on_site,
          i18n.resolvedLanguage
        );
      }
      if (item.regulatory_reviewer1_created_on) {
        item.regulatory_reviewer1_created_on = DateFormat(
          item.regulatory_reviewer1_created_on,
          i18n.resolvedLanguage
        );
      }
      if (item.regulatory_reviewer2_created_on) {
        item.regulatory_reviewer2_created_on = DateFormat(
          item.regulatory_reviewer2_created_on,
          i18n.resolvedLanguage
        );
      }
      if (item.close_date) {
        item.close_date = DateFormat(item.close_date, i18n.resolvedLanguage);
      }
      if (item.materialvalue) {
        item.materialvalue =
          item.materialvalue === null || item.materialvalue === undefined
            ? 0
            : parseInt(item?.materialvalue);
      }

      item["Shipping Request #"] = item["internal_distribution_number"];
      item["Create Date"] = item["created_on"];
      item["Created User"] = item["created_by"];
      item["Recipient "] =
        (item.firstname === null && item.lastname === null) ||
        (item.firstname === undefined && item.lastname === undefined)
          ? ""
          : item["firstname"] + " " + item["lastname"];
      item["Country of Destination"] =
        item["name_english"] === " ()" ? "" : item["name_english"];
      item["Dispatch Date - Elanco"] = item["shipment_date_mon"];
      item["Dispatch Date - Logistics Partner"] = item["shipment_date_lev"];
      item["Tracking Number"] = item["tracking_number"];
      item["Status"] = t("status." + item["status"]);
      item["Study Number "] = item["study_number"];
      item["Type of Shipment"] = shippingType.find(
        (x) => x.id === item["type_of_shipment"]
      )?.name;
      item["Delivered Date"] = item["close_date"];
      item["Shipper Company/University"] =
        item["destination_company_university"];
      item["Shipper Country"] =
        item["country_of_shipper"] === " ()" ? "" : item["country_of_shipper"];

      item["Destination Company/University"] = item["entity"];
      item["Destination Country"] =
        item["name_english"] === " ()" ? "" : item["name_english"];

      item["Regulatory Reviewer (1)"] =
        item["regulatory_reviewer1_approved_by"];
      item["Regulatory Reviewer (2)"] =
        item["regulatory_reviewer2_approved_by"];

      item["Regulatory Reviewer (1) Comments"] =
        item["regulatory_reviewer1_comments"];
      item["Regulatory Reviewer (2) Comments"] =
        item["regulatory_reviewer2_comments"];
      item["Total Value of Material"] =
        item?.usdvalue === null || item?.usdvalue === undefined
          ? item?.materialvalue === null || item?.materialvalue === undefined
            ? 0
            : parseInt(item?.materialvalue)
          : parseInt(item?.usdvalue) + parseInt(item?.restofworldvalue);


      item["Shipping Reviewer"] = item["shipping_reviewer_user"];
      item["Project"] = item["project_name"];

      delete item["usdvalue"];
      delete item["restofworldvalue"];
      delete item["materialvalue"];

      item["Material Name"] = item["description"];
      item["Batch Number"] =
        item?.batch === ";" || item?.batch === ";;" ? "" : item["batch"];

      item["Regulatory Reviewer (1) Approval Date"] =
        item["regulatory_reviewer1_created_on"];
      item["Regulatory Reviewer (2) Approval Date"] =
        item["regulatory_reviewer2_created_on"];

      item["Date Material Needed On Site"] =
        item["date_material_needed_on_site"];
        item["Under Shipping Review"]= item["review_by_shipping"];
      //   item["Shipping Reviewer"] = item["name_english"];
      delete item["study_number"];
      delete item["reciever_country"];
      delete item["origin_country"];
      delete item["label_inst_comments"];
      delete item["label_text"];
      delete item["is_special_label_req"];
      delete item["rec_country_comments"];
      delete item["reg_req_rec_country_met"];
      delete item["org_country_comments"];
      delete item["reg_req_org_country_met"];
      delete item["review_by_shipping"];
      delete item["is_shippment_hazardous"];
      delete item["shippment_hazardous_comments"];

      delete item["country_of_shipper"];
      delete item["destination_company_university"];
      delete item["name_english"];
      delete item["regulatory_reviewer1_approved_by"];
      delete item["regulatory_reviewer2_approved_by"];
      delete item["regulatory_reviewer1_comments"];
      delete item["regulatory_reviewer2_comments"];
      delete item["description"];

      delete item["batch"];
      delete item["regulatory_reviewer1_created_on"];
      delete item["regulatory_reviewer2_created_on"];
      delete item["date_material_needed_on_site"];

      delete item["shipper_locked_data"];
      delete item["address_recipient_locked_data"];

      delete item["shipping_req_id"];
      delete item["consignor_type"];
      delete item["country_of_shipper"];
      delete item["logistics_partner"];
      delete item["id"];
      delete item["address_id"];
      delete item["close_date"];
      delete item["distributiontemperature_id"];
      delete item["sap_delivery_number"];
      delete item["shipment_date_lev"];
      delete item["shipment_date_mon"];
      delete item["status"];
      delete item["tracking_number"];
      delete item["created_by"];
      delete item["first_name"];
      delete item["last_name"];
      delete item["firstname"];
      delete item["lastname"];
      delete item["form_of_address"];
      delete item["sapnumber"];
      delete item["name_english"];
      delete item["created_on"];
      delete item["handling_type"];

      delete item["recipient_id"];
      delete item["shipper_id"];
      delete item["c_comment"];
      delete item["id_old"];
      delete item["id_old_request"];
      delete item["internal_distribution_number"];
      delete item["shipment_constraint_date"];
      delete item["shipment_constraint_type"];
      delete item["status_comment"];
      delete item["valid"];
      delete item["c_comment"];
      delete item["updated_on"];
      delete item["updated_by"];
      delete item["is_active"];
      delete item["title_id"];
      delete item["approved"];
      delete item["email"];
      delete item["phone"];
      delete item["submitted"];
      delete item["country_id"];
      delete item["building"];
      delete item["city"];
      delete item["department"];
      delete item["entity"];
      delete item["postcode"];
      delete item["street"];
      delete item["code"];
      delete item["name"];
      delete item["attachment_comment"];
      delete item["inco_term"];
      delete item["cost_center_shipment"];
      delete item["type_of_shipment"];
      delete item["entity_name"];
      delete item["importer_records_address"];
      delete item["shipment_no"];
      delete item["is_reg_review_req"];
      delete item["prp_use_of_material"];
      delete item["pack_tot_weight"];
      delete item["pack_height"];
      delete item["pack_width"];
      delete item["pack_length"];
      delete item["is_mat_client_used_animal"];
      delete item["is_mat_food_produced_animal"];
      delete item["date_material_available_to_ship"];
      delete item["date_material_needed_on_site"];
      delete item["state"];

      delete item["importer_of_records"];
      delete item["state"];

      delete item["broker_id"];
      delete item["reviewer_comment"];
      delete item["shipping_id"];
      delete item["study_title"];
      delete item["person_responsible"];
      delete item["animal_designation"];
      delete item["regulatory_standard"];
      delete item["animals_required"];
      delete item["project_name"];
      delete item["activity_type"];
      delete item["intended_for_pivotal_evidence"];
      delete item["study_comment"];
      delete item["shipping_reviewer_user"];
      delete item["shipper_address_id"];
      delete item["review_by"];
      delete item["prefered_reviewer"];

      for (let key in item) {
        if (item[key] === null) {
          item[key] = "";
        }
      }
    });
    return _.cloneDeep(copyOfFilteredData);
  };
  const ExportSelectedBtn = () => {
    window.location.href =
      config.baseURL +
      `/api/exportSelectedData/` +
      selected.id +
      "/" +
      requiredFileName +
      "/Process_Shipping_Requests_Data";
  };
  // const handleStatusChange = async (status) => {
  //   if (selected?.id) {
  //     try {
  //       await shippingService.setShippingStatus({
  //         id: selected.id,
  //         status,
  //       });
  //       await actions.getProcessShippmentList();
  //       setSelected({ ...selected, status });
  //       toast.success(t("commonlabels.savedSuccessfullyMsg"));
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   }
  // };

  const getStatusFlow = () => {
    if (actionName === INSPECT) {
      return (
        <StatusFlow
          row={shippingDetail}
          actionType={INSPECT}
          roleName={PROCESS_MANAGER}
          goBack={"/process/shipping"}
        />
      );
    }
  }
  
  const viewShipment = async (rowData) => {
    setActionName(INSPECT);
    setShippingDetail(rowData);
    actions.redirectAction("/process/shipping");
  }

  const editShippment = async (row) => {
    actions.redirectAction("/process/shipping");
    actions.getShippingRequestDetails(row);
    actions.getDeputyShippingDetails(row);
    actions.getMaterialShippingDetails(row);
    actions.getShippingActionType("EDIT");
    actions.clearShippingMaterialSelectedValue();
    actions.addShippingMaterialSelect([]);
    actions.getShippingAttachment(row);
    actions.getShipperDetails(row);
    actions.getAllShippingData();
    actions.saveTableData(row);
    history.push("/shipping");
  };
  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };
  const ExportBtn = () => {
    return (
      <button
        className="mr-1"
        onClick={() => {
          setIsExportModalVisible(true);
        }}
      >
        {t("export.exportall")}
      </button>
    );
  };
  const ExportSelectedBtnForSingle = () => {
    return (
      <button
        disabled={selected === ""}
        onClick={(e) => {
          setIsExportModalVisibleSingle(true);
        }}
      >
        {t("export.exportselected")}
      </button>
    );
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport();
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>

        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportModalBodySingleData = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const validateRevokebutton = () =>{
    return get(selected, "id", 0) === 0 ||
    ![STATUS.READY_FOR_DELIVERY].includes(selected?.status)
    setSelected("")

  }

  const exportModalFooterSingleData = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            ExportSelectedBtn();
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Export
        </button>
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };

  return (
    <>
      {
        Object.keys(shippingDetail).length > 0 &&
          shippingDetail.id !== 0 &&
          getStatusFlow()
      }
      <div className="row m0 no_padding container-fluid ">
        <div className="no_padding col-12 mt-3 mb-2 d-flex justify-content-end">
          {userType.includes(USERROLES.PROCESSMANAGER) === true ? (
            <div className="buttonsec_animalmanagment">
              <DropdownButton
                title={t("export.export")}
                className="mr-1 exportdropdown"
              >
                <Dropdown.Item>{ExportBtn()}</Dropdown.Item>
                <Dropdown.Item>{ExportSelectedBtnForSingle()}</Dropdown.Item>
              </DropdownButton>

              <button
                className="btn btn-primary new_button mr-1"
                disabled={get(selected, "id", 0) === 0 ? true : false}
                onClick={() => {
                  history.push(`/shippings/log/${selected?.id}`);
                  actions.saveTableData(selected);
                }}
              >
                {t("shippingrequest.log")}
              </button>

              <button
                className="btn btn-primary new_button"
                disabled={ validateRevokebutton()
                    ? true
                    : false
                }
                onClick={async () => {
                  try {
                    setIsLoading(true);
                    await actions.revertApproval(selected.id,selected.status,userId);
                    await actions.getProcessShippmentList();
                    handleRadiobuttonChange(null)
                  } catch (e) {}
                  setIsLoading(false);
                }}
              >
                {t("regulatoryReview.revoke_approval")}
              </button>
            </div>
          ) : (
            <div className="buttonsec_animalmanagment">
              <button
                className="btn btn-primary new_button"
                disabled={get(selected, "id", 0) === 0 ? true : false}
                onClick={() => handlerShippingInspect(selected)}
              >
                {t("shippingrequest.inspect")}
              </button>
            </div>
          )}
        </div>
      </div>
      <Card variant="outlined">
      {filteredData?.length>0 &&
        <CardHeader
          title={t("approve_recipient.search_text")}
          style={cardTheme.cardHeaderStylePref}
        />}
      
        <Divider />
        <CardContent>
          <div style={{ paddingBottom: "2%" }}>
          {filteredData?.length>0 &&
            <MaterialTable
            onPageChange={(page, itemsPerPage) => {
              dispatchProcessShippingOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
            }}
            onOrderCollectionChange={(orderBy) => {
              dispatchProcessShippingOptions({ target: "sort" }, orderBy);
            }}
              columns={[
                {
                  width: "4%",
                  render: (rowData) => (
                    <input
                      type="radio"
                      value={get(selected, "id", "")}
                      name="radiovalues"
                      className={classes.tableRadio}
                      onChange={(e) => handleRadiobuttonChange(rowData)}
                    />
                  ),
                  sorting: false,
                },
                {
                  title: <span>{t("shippingrequest.internalorderid")}</span>,
                  field: `internal_distribution_number`,
                  customSort: (d1,d2)=>{
                    let n1 = d1?.internal_distribution_number.split(/[_ -]/)
                    let n2 = d2?.internal_distribution_number.split(/[_ -]/)
                    if(n1[2] != n2[2]){
                      return parseInt(n1[2]) - parseInt(n2[2]);
                    }
                    else if(n1[1] != n2[1] && months.hasOwnProperty(n1[1]) && months.hasOwnProperty(n2[2])){
                      return ++months[n1[1]] - ++months[n2[2]];
                    }
                    else{
                      return parseInt(n1[0]) - parseInt(n2[0])
                    }
                  }
                },
                {
                  title: <span>{t("materials.create_date")}</span>,
                  render: (rowData) => (
                    <span>{dateLocalization(rowData.created_on)}</span>
                  ),
                  customSort: (ct1,ct2)=>{
                    let n1 = ct1?.created_on.split(/[- T]/)
                    let n2 = ct2?.created_on.split(/[ - T]/)
                    if(n1[0]!=n2[0]){
                      return parseInt(n1[0]) - parseInt(n2[0]);
                    }else if(n1[1] != n2[1] && months.hasOwnProperty(n1[1]) && months.hasOwnProperty(n2[2])){
                      return ++months[n1[1]] - ++months[n2[2]];
                    }else{
                      return parseInt(n1[2]) - parseInt(n2[2])
                    }
                    
                  }
                },
                {
                  title: <span>{t("materials.create_user")}</span>,
                  field: "created_by",
                  customSort: (crt1,crt2)=>{
                    let ncrt1=crt1.created_by.split(/[@]/)
                    let ncrt2=crt2.created_by.split(/[@]/)
                    if(ncrt1[0]!=ncrt2[0])
                    return ncrt1[0].localeCompare(ncrt2[0])
                  }
                },
                {
                  title: <span>{t("shippingrequest.finalrecipient")}</span>,
                  customSort: (a, b) => {
                    if (
                      (a.firstname + a.lastname).toString().toLowerCase() >
                      (b.firstname + b.lastname).toString().toLowerCase()
                    ) {
                      return -1;
                    } else if (
                      (a.firstname + a.lastname).toString().toLowerCase() <
                      (b.firstname + b.lastname).toString().toLowerCase()
                    ) {
                      return 1;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    (rowData.firstname ? rowData.firstname : "") +
                    +" " +
                    (rowData.lastname ? rowData.lastname : ""),
                  customSort: (a, b) => {
                    if (
                      (a.firstname + a.lastname).toString().toLowerCase() >
                      (b.firstname + b.lastname).toString().toLowerCase()
                    ) {
                      return -1;
                    } else if (
                      (a.firstname + a.lastname).toString().toLowerCase() <
                      (b.firstname + b.lastname).toString().toLowerCase()
                    ) {
                      return 1;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    (rowData.firstname ? rowData.firstname : "") +
                    " " +
                    (rowData.lastname ? rowData.lastname : ""),
                },

                {
                  title: <span>{t("shippingrequest.addreesedcountry")}</span>,
                  render: (rowData) => {
                    if (rowData.name_english === " ()") {
                      return "";
                    } else {
                      return rowData.name_english;
                    }
                  },
                  customSort: SortFunctionNew("name_english"),
                },
                {
                  title: <span>{t("shippingrequest.country_of_shipper")}</span>,
                  render: (rowData) => {
                    if (rowData.country_of_shipper === " ()") {
                      return "";
                    } else {
                      return rowData.country_of_shipper;
                    }
                  },
                  customSort: SortFunctionNew("country_of_shipper"),
                },
                {
                  title: <span>{t("title.ShipmentType")}</span>,
                  field: "type_of_shipment",
                  render: (rowData) => (
                    rowData.type_of_shipment==="INNER_EU"?"WITHIN EU":rowData.type_of_shipment==="DOMESTIC"?"DOMESTIC US":rowData.type_of_shipment
                  ),
                  customSort: (spt1,spt2)=>{
                      let types = {
                        "INNER_EU":"WITHINEU","DOMESTIC" :"DOMESTICUS ","INTERNATIONAL": "INTERNATIONAL"
                      }
                      let vals = Object.keys(types);
                      if(vals.includes(spt1.type_of_shipment) &&  vals.includes(spt2.type_of_shipment)){
                        return types[spt1.type_of_shipment].localeCompare(types[spt2.type_of_shipment])
                      }

                  }
                },
                {
                  title: <span>{t("shippingrequest.dispatchdate(mon)")}</span>,
                  render: (rowData) => (
                    <span>
                      {rowData.shipment_date_mon &&
                        dateLocalization(rowData.shipment_date_mon)}
                    </span>
                  ),
                  customSort: dateSortFunction("shipment_date_mon"),
                },
                {
                  title: <span>{t("shippingrequest.dispatchdate(lev)")}</span>,
                  render: (rowData) => (
                    <span>
                      {rowData.shipment_date_lev &&
                        dateLocalization(rowData.shipment_date_lev)}
                    </span>
                  ),
                  customSort: dateSortFunction("shipment_date_lev"),
                },
                {
                  title: <span>{t("shippingrequest.trackingnumber")}</span>,
                  field: "tracking_number",
                  customSort: SortFunctionNew("tracking_number"),
                },
                {
                  title: <span>{t("shippingrequest.status")}</span>,
                  render: (rowData) => t("status." + rowData.status),
                  customSort: (st1,st2)=>{
                    let nst1=st1.status.trim()
                    let nst2=st2.status.trim()
                    return status[nst1].localeCompare(status[nst2])
                  }
                  
                },
                {
                  title: <span>{t("shipping_request.assigned_shipping_review")}</span>,
                  field: "review_by_shipping",
                  customSort: sortFunction("review_by_shipping"),
                },
                // {
                //   title: <span>{t("shippingrequest.sapnumber")}</span>,
                //   field: "sap_delivery_number",
                //   customSort: SortFunctionNew("sap_delivery_number"),
                // },
                // {
                //   title: <span>Id</span>,
                //   field: "id",
                //   customSort: (a, b) => {
                //     if (parseInt(a.id) > parseInt(b.id)) {
                //       return -1;
                //     } else if (parseInt(a.id) < parseInt(b.id)) {
                //       return 1;
                //     } else {
                //       return 0;
                //     }
                //   },
                // },
                {
                  title: <span></span>,
                  sorting: false,
                  render: (rowData) =>
                    userType.includes(USERROLES.PROCESSMANAGER) === true ? (
                      rowData.status === STATUS.READY_FOR_DELIVERY || rowData.status === STATUS.INBOX ? (
                        <span>
                          <Link onClick={() => editShippment(rowData)}>
                            <img alt="t" className="" src={editIcon}></img>{" "}
                          </Link>
                        </span>
                      ) : (
                        <span>
                          <Link onClick={() => viewShipment(rowData)}>
                            <img alt="t" className="iconsize" src={viewIcon}></img>{" "}
                          </Link>
                        </span>
                      )
                    ) : (
                      <span></span>
                    ),
                },
              ]}
              data={(filteredData)}
              title="Render Image Preview"
              icons={tableIcons}
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage"
                  ),
                },
              }}
              options={{
                thirdSortClick: false,
                draggable: false,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: tableRowFormatter({
                  height: "30px",
                  padding: "0px",
                }),
                initialPage: parseInt(tableProcessShippingOptions?.pageNumber ? tableProcessShippingOptions?.pageNumber : 0),
                sortIcon: true,
                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,
                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: parseInt(tableProcessShippingOptions?.pageSize ? tableProcessShippingOptions?.pageSize : 10),
                defaultOrderByCollection: tableProcessShippingOptions?.orderBy ? tableProcessShippingOptions?.orderBy : "",
               
                pageSizeOptions: [10, 20, 30, 40,50],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />
          }
          {!filteredData?.length &&
            <MaterialTable
              columns={[
                {
                  width: "4%",
                  render: (rowData) => (
                    <input
                      type="radio"
                      value={get(selected, "id", "")}
                      name="radiovalues"
                      className={classes.tableRadio}
                      onChange={(e) => handleRadiobuttonChange(rowData)}
                    />
                  ),
                  sorting: false,
                },
                {
                  title: <span>{t("shippingrequest.internalorderid")}</span>,
                  
                },
                {
                  title: <span>{t("materials.create_date")}</span>,
                  
                },
                {
                  title: <span>{t("materials.create_user")}</span>,
                 
                },
                {
                  title: <span>{t("shippingrequest.finalrecipient")}</span>,
                 },

                {
                  title: <span>{t("shippingrequest.addreesedcountry")}</span>,
                
                },
                {
                  title: <span>{t("shippingrequest.country_of_shipper")}</span>,
                 
                },
                {
                  title: <span>{t("title.ShipmentType")}</span>,
                  
                },
                {
                  title: <span>{t("shippingrequest.dispatchdate(mon)")}</span>,
                  
                },
                {
                  title: <span>{t("shippingrequest.dispatchdate(lev)")}</span>,
                 
               
                },
                {
                  title: <span>{t("shippingrequest.trackingnumber")}</span>,
                 
                },
                {
                  title: <span>{t("shippingrequest.status")}</span>,
                 
                },
              ]}
              data={(filteredData)}
              title="Render Image Preview"
              icons={tableIcons}
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage"
                  ),
                },
              }}
              options={{
                thirdSortClick: false,
                draggable: false,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: tableRowFormatter({
                  height: "30px",
                  padding: "0px",
                }),
                sortIcon: true,
                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,
                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40,50],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />
          }
          
          </div>
        </CardContent>
      </Card>
      {userType.includes(USERROLES.PROCESSMANAGER) === true ? (
        <div className="no_padding col-12 ">
          <div className={classes.actionButtonsTop}>
            <button
              className="btn btn-primary new_button mr-2"
              disabled={!(selected?.id && ["INBOX"].includes(selected?.status))}
              onClick={() => handleHandBack(selected)}
            >
              {t("approve_materials.handBack")}
            </button>

            {/* <button
              className="btn btn-primary new_button mr-2"
              disabled={get(selected, "id", 0) === 0 ? true : false}
              onClick={() => handleCloseRequest(selected)}
            >
              {t("approve_materials.close")}
            </button> */}

            <button
              className="btn btn-primary new_button"
              disabled={get(selected, "id", 0) === 0 ? true : !["INBOX", "READY_FOR_DELIVERY"].includes(selected?.status) ? true : false}
              onClick={() => handleCancelRequest(selected)}
            >
              {t("approve_materials.cancelRequest")}
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <Model
        showheader={isExportModalVisible}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBody()}
        modalfooter={exportModalFooter()}
      ></Model>
      <Model
        showheader={isExportModalVisibleSingle}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBodySingleData()}
        modalfooter={exportModalFooterSingleData()}
      ></Model>
    </>
  );
};

export default ProcessShippingRequestListingComponent;
