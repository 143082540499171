import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import {GetSecrets} from './logkey';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
let key=GetSecrets.getloggingkey();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:key,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };