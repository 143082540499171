import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ModalCloseIcon from "../../assests/images/ModalCloseIcon.png";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useCommentsModalStyles } from "./CommentsStyles";
import { red } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { appInsights } from "../../insightIogging";

export default function CommentsReplyComponent(props) {
  const {
    showModalForReply,
    isModalSaveRunning,
    saveMethod,
    closeReplyModal,
    handleChangeComments,
    miniSpinner,
    selectedRowData,
    AlertCommentsList,
    handleChange,
    allUsersList
  } = props;
  const { t } = useTranslation();

  const [replyComments, setReplyComments] = useState("");
  const [tagvalue, setTagValue] = useState([]);
  const classes = useCommentsModalStyles();

  const rowRepliesList = AlertCommentsList.filter(
    (c) =>
      c.parent_comment_id != null && c.parent_comment_id === selectedRowData.id
  );

  const handleTagInput = (e, newValue) => {
    handleChange(e, newValue);
    setTagValue(newValue);
  };

  const handlereplyChangeComments = (e) => {
    setReplyComments(e.target.value);
    handleChangeComments(e);
  };

  const closeCommentsModal = (e, row) => {
    setReplyComments("");
    setTagValue([]);
    closeReplyModal(e, row);
  };

  const saveReplyMethod = (e, row) => {
    saveMethod(e, row);
    setReplyComments("");
    setTagValue([]);    
  }

  return (
    <div>
      {/* Model for reply to the commnet  */}
      <Modal dialogClassName="my-modal" centered show={showModalForReply}>
        <Modal.Header centered="true" className="bg-theme-blue btn-primary">
          <h5 className="modal-title">{t("comments_alert.title")}</h5>
          <a id="closemodal" onClick={(e) => closeCommentsModal(e, selectedRowData)}>
            <img style={{ width: "15px" }} src={ModalCloseIcon}></img>{" "}
          </a>

        </Modal.Header>
        <Modal.Body className={classes.modalHeight}>
          <div className="row mb-4 m0">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="form-group row">
                  <label class="col-sm-8 col-form-label">
                    <span class="text-primary"> {t("comments_alert.shipping_request")} </span> <br />
                    {selectedRowData.shipment_no}
                  </label>
                  <div className=" col-sm-4 ">
                    <label class="col-form-label">
                      <span class="text-primary">{t("comments_alert.from")} </span>     <br />
                      {selectedRowData.first_name + ' ' + selectedRowData.last_name}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row">
                  <label class="col-sm-12 col-form-label">
                    <span class="text-primary"> {selectedRowData.first_name + ' ' + selectedRowData.last_name} </span><br />
                    {selectedRowData.comment}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-12 p-0">
              <div className="form-group">
                {rowRepliesList.map((userReply, index) => {
                  return (
                    <div key={userReply.id}>                      
                      <div className="d-flex">
                      <label class="text-primary"> {userReply.first_name + ' ' + userReply.last_name}</label>
                        {
                          true ? (userReply.is_viewed ? "" : <Stack direction="row" spacing={6}>
                            <Avatar sx={{ fontSize: "small", bgcolor: "transparent", color: red[500], marginTop: "-15px" }}>
                              <NotificationsIcon />
                            </Avatar>
                          </Stack>)
                            : ""}
                      </div>
                      <label id={userReply.id} > {userReply.comment === null ? "" : userReply.comment}
                        </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-12 p-0">
              <div className="form-group">
                <label htmlFor="" className="col-form-label">
                  {t("comments_alert.taguser")}
                </label>
                <Autocomplete
                  multiple
                  autoHighlight
                  id="tag-users"
                  options={allUsersList}
                  value={tagvalue}
                  onChange={handleTagInput}
                  getOptionLabel={(option) => option.email}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select one or more users to tag" />
                  )}
                  sx={{ width: '100%' }}
                />
              </div>
              <div className="form-group mb-0">
                <label className="">
                  {" "}
                  <b>{t("comments_alert.comments")}</b>{" "}
                </label>
                <TextareaAutosize
                  className={classes.textAreaScroll}
                  id="full_description"
                  name="full_description"
                  aria-label="full_description"
                  margin="normal"
                  variant="outlined"
                  size="large"
                  minRows={6}
                  style={{ width: "100%" }}
                  value={replyComments === null ? "" : replyComments}
                  onChange={(e) => handlereplyChangeComments(e)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {isModalSaveRunning && miniSpinner()}
          <button
            type="button"
            id="savemethod"
            disabled={isModalSaveRunning}
            className="btn btn-primary btn-inner-close"
            onClick={(e) => saveReplyMethod(e, selectedRowData)}
          >
             {appInsights.trackTrace({
             message:'comment reply submit button',
            severityLevel:1
             })}

            {t("comments_alert.submit")}
          </button>
          <button
            id="closeButtonForSave"
            type="button"
            disabled={isModalSaveRunning}
            className="btn btn-black btn-inner-close"
            onClick={(e) => closeCommentsModal(e, selectedRowData)}
          >
            {t("comments_alert.cancel")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}