import React, { useState } from 'react';

import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import { EDIT, USERROLES, NAV_KEYS } from "../../constants"
import { useTranslation } from "react-i18next";

import { STATUS, INSPECT, SUBMIT, HAND_BACK, CANCEL_REQUEST, CLOSE } from '../../constants'
import { appInsights } from '../../insightIogging';

appInsights.trackTrace({
    message:" shipping request submit tab",
    severityLevel:1
});

const ShippingRequestSubmit = (props) => {
    const { t } = useTranslation();

 const {
        handleSaveAndClose,
        handleShippingRequstClose,
        shippingActionType,
        shippingRequestDetails,
        location: {
            state: {
                goBack
            } = {}
        } = {},
        mailLinkData,
        actions,
        redirectTo,
        shippingSearchData,
        openCancelRequestModal
    } = props

    const history = useHistory();
    const userType = JSON.parse(localStorage.getItem("userType"));
    const status = shippingRequestDetails.length > 0 && shippingRequestDetails[0].status

    const handlePreview = () => {
        /*Below condition will executes when user comming from Alerts Tab */
        if (redirectTo === "/alerts") {
            actions.clearshippingRequest();
            history.push(redirectTo);
            actions.setNavKey(NAV_KEYS.ALERTS_TAGS);
            return;
        }
        if (mailLinkData?.isAccessedFromMAil) {
            window.location = window.location.origin;
            return;
        }
        if (goBack) {
            history.push(`${goBack}`);
        } else {
            history.push(redirectTo);
        }
    }

    const handleHandBackCancel = () => {
        history.push(`${goBack}`);
    }

    const InputSubmit = () => {
 
        return (
            <div className="col-4 pull-right pt10 pr-0 text-right">
                <Button
                    className="btn btn-primary new_button"
                    variant="contained" size="small"
                    onClick={() => handleSaveAndClose(true, STATUS.REGULATORY_REVIEWER_1)}
                >
                    {t('shipping_request.submit')}
                </Button>&nbsp;

                <Button
                    className="btn btn-secondary cancelbutton"
                    onClick={handlePreview}
                    variant="contained" size="small" >
                    {t('commonlabels.cancelbutton')}
                </Button>

            </div>
        )
    }

    const Submit = () => {
        {appInsights.trackEvent({
            name:"closing request after shippment processing"
            },{properties:shippingRequestDetails[0]?.internal_distribution_number})}
        return (
            <div className='col-md-12 d-flex justify-content-end pr-0 botbuttons'>
                {(userType.includes(USERROLES.PROCESSMANAGER) || userType.includes(USERROLES.APPLICANT)) ?

                    <>
                        {(shippingRequestDetails[0]?.status === STATUS.INPUT || shippingRequestDetails[0]?.status === undefined) ?
                            <Button
                                className='Savebutton mr-1'
                                variant="contained" size="small"

                                onClick={() => handleSaveAndClose(true, STATUS.REGULATORY_REVIEWER_1)}>
                                {t('shipping_request.submit')}
                                {appInsights.trackTrace({
                                message:'submitted for new shipping request',
                                severityLevel:1
                                })}
                                {appInsights.trackEvent({
                                name:"submitted for new shipping request"
                                },{properties:shippingRequestDetails[0]?.internal_distribution_number})}
                            </Button>
                            : <></>}
                        <Button
                            className='Savebutton mr-1'
                            variant="contained" size="small"
                           // disabled={(shippingRequestDetails[0]?.status === STATUS.REGULATORY_REVIEWER_1 || shippingRequestDetails[0]?.status === STATUS.REGULATORY_REVIEWER_2)}
                            onClick={() => handleSaveAndClose(true)}>
                            {t('shipping_request.save_and_close')}
                        </Button>

                        <Button
                            className='Savebutton mr-1'
                            //disabled={(shippingRequestDetails[0]?.status === STATUS.REGULATORY_REVIEWER_1 || shippingRequestDetails[0]?.status === STATUS.REGULATORY_REVIEWER_2)}
                            onClick={() => handleSaveAndClose(false)}>
                            {t('commonlabels.saveButton')}
                        </Button>
                        <Button
                            className='cancelbutton'
                            onClick={() => handleShippingRequstClose(false)}>
                            {t('commonlabels.cancelbutton')}
                        </Button>
                    </>
                    :
                    <>
                        <Button
                            className='mr-1'
                            disabled
                            variant="contained" size="small"
                        >
                            {t('shipping_request.save_and_close')}
                        </Button>

                        <Button
                            className='mr-1'
                            disabled
                            variant="contained" size="small"
                        >
                            {t('commonlabels.saveButton')}
                        </Button>
                        <Button
                            className='cancelbutton'
                            onClick={() => {
                                if (userType.length === 1 && userType.includes(USERROLES.ADMIN)) {
                                    history.push("/approveAddress")
                                } else {
                                    history.push("/regulatoryReviewer")
                                }
                            }}>
                            {t('commonlabels.cancelbutton')}
                        </Button>
                    </>}
            </div>
        )
    }

    const Preview = () => {

        return (
            <div className='col-4 pull-right text-right'>
                <button
                    variant="contained" size="small"
                    onClick={handlePreview}
                    className="btn btn-secondary new_button mr-1"
                >

                    {t('materials.back')}
                </button>
            </div>
        )
    }

    const HandBack = () => {

        return (
            <div className='col-md-12 d-flex justify-content-end pr-0 pt10'>

                <Button
                    className='Savebutton'
                    variant="contained" size="small"
                    onClick={() => handleSaveAndClose(true,'',false)}>
                    {t('approve_materials.handBack')}
                </Button>&nbsp;
                <Button
                    className='cancelbutton'
                    onClick={() => handleHandBackCancel()}>
                    {t('commonlabels.cancelbutton')}
                </Button>
            </div>
        )
    }    

    const openCancelModel = () => {
        openCancelRequestModal();
    }

    const CancelRequest = () => {

        return (
            <div className='col-md-12 d-flex justify-content-end pr-0 pt10'>
                <div>
                    <Button
                        className='Savebutton'
                        variant="contained" size="small"
                        onClick={(e) => openCancelModel(e)}>
                        {t('approve_materials.cancelRequest')}
                    </Button>&nbsp;
                    <Button
                        className='cancelbutton'
                        onClick={() => handleHandBackCancel()}>
                        {t('commonlabels.cancelbutton')}
                    </Button>
                </div>
            </div>
        )
    }

    const Close = () => {

        return (
            <div className='col-md-12 d-flex justify-content-end pr-0 pt10'>

                <Button
                    className='Savebutton'
                    variant="contained" size="small"
                    onClick={() => handleSaveAndClose(true)}>

                    {appInsights.trackEvent({
                    name:"request closed after shipping review"
                    },{properties:shippingRequestDetails[0]?.internal_distribution_number})}

                    {t('approve_materials.close')}
                </Button>&nbsp;
                <Button
                    className='cancelbutton'
                    onClick={() => handleHandBackCancel()}>
                    {t('commonlabels.cancelbutton')}
                </Button>
            </div>
        )
    }

    const ShipperReviewActions = () => {

        return (
            <div className=" pull-right pt10 pr-0 text-right d-flex">

                <Button
                    className="btn btn-primary new_button"
                    variant="contained" size="small"
                    onClick={() => handleSaveAndClose(false, STATUS.INBOX,false)}
                >
                    {t('commonlabels.saveButton')}
                </Button>&nbsp;
                <Button
                    className="btn btn-primary new_button"
                    variant="contained" size="small"
                    onClick={() => handleSaveAndClose(true, STATUS.SHIPER_REVIEW_DONE,true)}
                >
                      {appInsights.trackEvent({
                      name:"shipper review done"
                     },{properties:shippingRequestDetails[0]?.internal_distribution_number})}
                    {t('commonlabels.acceptButton')}
                </Button>&nbsp;
                <Button
                    className="btn btn-secondary cancelbutton"
                    onClick={() => handleSaveAndClose(true, STATUS.INPUT,false)}
                    variant="contained" size="small" >
                    {t('materials.deny_approval')}
                </Button>&nbsp;

                <Button
                    className='btn btn-secondary cancelbutton'
                    variant="contained" size="small"
                    onClick={() => handleShippingRequstClose(false)}>
                    {t('commonlabels.cancelbutton')}
                </Button>

            </div>
        )
    }

    const statusActions = () => {

        if (shippingActionType === SUBMIT && status === STATUS.INPUT) {
            return (
                <InputSubmit />
            )
        }

        if (shippingActionType === EDIT && status === STATUS.INBOX) {
            return (
                <ShipperReviewActions />
            )
        }

        if (shippingActionType === INSPECT) {
            return (
                <Preview />
            )
        }

        if (shippingActionType === HAND_BACK) {
            return (
                <HandBack />
            )
        }

        if (shippingActionType === CANCEL_REQUEST) {
            return (
                <CancelRequest />
            )
        }

        if (shippingActionType === CLOSE) {
            return (
                <Close />
            )
        }

        return (
            <Submit />
        )
    }

    return (
        <div className=' pr-2 pb-1 buttons_container_shippingRequest'>
            {statusActions()}
        </div>
    )
}

export default ShippingRequestSubmit;