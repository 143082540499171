import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { If, Then } from "react-if";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { Alert, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import { EDIT, NAV_KEYS, NEW, USERROLES,INSPECT, SUBMIT } from "../../constants";
import ShippingMaterialDetails from "./ShippingMaterialDetails";
import { Months,WeekDay } from "../../constants";
import ShippingMaterialList from "./ShippingMaterialList";
import ShippingMaterialForm from "./ShippingMaterialForm";
import ErrorMessage from "../common/ErrorMessage";
import TabMenu from "../common/TabMenu";
import ShippingMaterialMoareDetails from "./ShippingMaterialMoareDetails";
import SaveChangesRequest from "../shippingRequest/SaveChangesRequest";
import AlertConfirmation from "./AlertConfirmation";
import LoadingOverlay from "react-loading-overlay";
import _, { get } from "lodash";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import errorActions from "../../redux/errors/actions";
import {
  getAllMaterials,
  setEmptyMaterail,
  updateShippingMaterail,
} from "./common";
import { STATUS } from "../../constants";
import { useTranslation } from "react-i18next";
import {
  disableInputFieldsBySelector,
  enableInputFieldsBySelector,
} from "../common/utils/DomUtil";
import shippingService from "../../Services/shippingService";
import { ifRequestCompletedApprovals } from "../../Services/util";
import { FILE_SIZE_LIMIT } from "../../constants";
import { DateFormat, FormatDate } from "../common/utils/DateUtil";

const styles = {
  header: {
    width: "100%",
    height: 30,
    backgroundColor: "#dfdfdf",
  },
  Addbtn: {
    float: "right",
  },
  TopBtn: {
    float: "left",
  },
};
const ShippingMaterialsComponent = (props) => {
  const [isDangerous, setIsDangerous] = useState(false);
  const [materailName, setMaterailName] = useState("");
  const [amountAndUnit, setAmountAndUnit] = useState("");
  const [valueOfMaterial, setValueOfMaterial] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const userType = JSON.parse(localStorage.getItem("userType"));
  const [aggregation, setAggregation] = useState("");
  const [countryOrigin, setCountryOrigin] = useState("");
  const [comment, setComment] = useState("");
  const [unNo, setUnNo] = useState("");
  const [alertConfirmation, setAlertConfirmation] = useState(false);
  const [actionType, setActionType] = useState("View");
  const [isError, setError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isHazardous, setIsHazardous] = useState(false);
  const [isBlindedMaterial, setIsBlindedMaterial] =useState(false)
  const [hazardousds, setHazardousds] = useState(false);
  const [transportClassification, setTransportClassification] = useState("");
  const [packGroup, setPckGrp] = useState("");
  const [documents, setDocuments] = useState([]);
  const [quantity, setQuantity] = useState(null);
  const [unit, setUnit] = useState("");
  const [unitType, setUnitType] = useState("");
  const [containerType, setContainerType] = useState(null);
  const [curency, setCurency] = useState("USD");
  const [manufactureCountry, setManufactureCountry] = useState("");
  const [fileName, setFileName] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const [htsCode, setHtsCode] = useState(null);
  const [materialSpecificData, setMaterialSpecificData] = useState({});
  const [materialAvailStatus, setMaterialAvailStatus] = useState({
    errorCheck: false,
    message: "",
  });
  const [materialNeedStatus, setMaterialNeedStatus] = useState({
    errorCheck: false,
    message: "",
  });

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const {
    actions,
    classes,
    shippingMaterials = [],
    countries = [],
    shippingMaterialSelect = [],
    materialShippingDetails = [],
    shippingMaterialSelectedValue = {},
    materailIdSelect = "",
    phases = [],
    getShippingResponse = [],
    shippingRequestDetails = [],
    shippingActionType,
    destinationAddrSelect,
    finalRecipientSelect,
    shippingDeputySelect,
    internalOrderIdValue,
    distributionTempValue,
    shippingCreateDateValue,
    shippingCreateCommentValue,
    dispatchMONValue,
    dateOfReceiptValue,
    trackingNumValue,
    statusQuoValue,
    dispatchLEVValue,
    updateShipping,
    redirectTo,
    shipmentSpecificValue,
    sapDeliveryNumValue,
    attachements,
    shipper,
    extra,
    allCurrency,
    allUnits,
    allContainer,
    mailLinkData,
    distributionTemperature,
    shippingType,
    newShippingRequestCommentsList,
   shippingSearchData,
 tableOptions,
    tableAdminShippingOptions,
    clientTimeZone
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editMode, setEditMode] = useState(false);
const [mandatoryCheck, setMandatoryCheck] =useState([]);
const handleTimeZoneOffset = (date) =>{
  let newDate = date;
  if(new Date(date).getTimezoneOffset() < 0){
    let tmp = new Date(date)
    tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
    newDate = tmp
  }
   const utcTime = moment(newDate).utc().format('YYYY-MM-DDTHH:mm:ss');
  return new Date(utcTime)
}
  const currentStatus = _.get(shippingRequestDetails, "[0].status");
  const dispatch = useDispatch();
  const updateErrorTab = errorActions.updateErrorTab;

  useEffect(() => {
    if (editMode) {
      handleMaterialEdit();
      setEditMode(false);
    }
  }, [shippingMaterialSelect]);

  useEffect(()=>{
    if(shippingActionType==='NEW' || (shippingRequestDetails.length  && shippingRequestDetails[0].status === 'INPUT')) {
      
      if(extra.type_of_shipment && extra.date_material_available_to_ship && extra.date_material_needed_on_site){
        validateMaterialNeedDate();
      }
    }
  },[extra.date_material_needed_on_site])
  
  useEffect(() => {
    if(shippingActionType==='NEW' || (shippingRequestDetails.length  && shippingRequestDetails[0].status === 'INPUT')) {
      if(extra.type_of_shipment && extra.date_material_available_to_ship){
        validateMaterialAvailDate();

      }
    }
  }, [extra.date_material_available_to_ship]);
  useEffect(() => {
    if (
      materailName &&
      (materailName.is_hazardous || materailName.is_hazardous === false)
    ) 
      setIsHazardous(get(materailName, "is_hazardous", false));
   if(
    materailName &&
    (materailName.hazardous_sds || materailName.hazardous_sds === false)
   )
   setHazardousds(get(materailName, "hazardous_sds", false));
   if(
    materailName &&
    (materailName.is_blinded_material || materailName.is_blinded_material === false)
   )
   setIsBlindedMaterial(get(materailName, "is_blinded_material", false));
   
   {
      if (get(materailName, "transport_class", null)) {
        setTransportClassification(get(materailName, "transport_class", null));
      } else {
        setTransportClassification("");
      }
     if(get(materailName,"pack_group",null)){ 
      setPckGrp(get(materailName,"pack_group",null));
     }
     else{
      setPckGrp("");
     }
      setUnNo(get(materailName, "un_no", ""));
    }
  }, [materailName]);

  const addDeputiesIntoShipping = async () => {
    shippingDeputySelect.map(async (deputy) => {
      const deputiesShippingRequest = {
        shppping_response: getShippingResponse,
        shippingDeputeId: deputy.id,
        created_by: sessionStorage.getItem("userName"),
      };
      await actions.addDeputiesShippingRequest(deputiesShippingRequest);
    });
  };

  useEffect(() => {
    if (getShippingResponse.length && shippingDeputySelect.length) {
      addDeputiesIntoShipping();
    }
  }, [getShippingResponse, shippingDeputySelect]);

  useEffect(() => {
    actions.getShippingMaterials();
    actions.getCountriesList();
    actions.getPhases();
    actions.getAllCurrency();
    actions.getAllUnits();
    actions.getAllContainer();
    if (actionType === "New") {
      if (!materailIdSelect) {
        const materail = setEmptyMaterail();
        actions.addShippingMaterialSelect([materail]);
        actions.materailIdSelect(materail.id);
      }
    }
  }, [actionType]);

  useEffect(() => {
    if (materialShippingDetails.length > 0) {
      setIsLoading(true);
      const allMaterials = getAllMaterials(materialShippingDetails);
      const materialsWithoutSDS = allMaterials.filter(
        (item) => (item.hazardous_sds  || item.is_hazardous)
      );
      setMandatoryCheck(materialsWithoutSDS)
      const id = allMaterials[0].id;
      const selectedMaterial = shippingMaterialSelect.find((i) => i.id === id);
      if (selectedMaterial && !materailIdSelect) {
        // actions.shippingMaterialSelectedValue(allMaterials[0]);
        actions.shippingMaterialSelectedValue(selectedMaterial);
        actions.materailIdSelect(id);
      }
      setIsLoading(false);
    }
  }, [materialShippingDetails]);

  useEffect(() => {
    if (shippingRequestDetails.length > 0 && materialShippingDetails > 0) {
      setIsLoading(true);
      actions.getMaterialShippingDetails(shippingRequestDetails[0]);
      const allMaterials = getAllMaterials(materialShippingDetails);
      actions.addShippingMaterialSelect(allMaterials);
      actions.shippingMaterialSelectedValue(allMaterials[0]);
      actions.materailIdSelect(allMaterials[0].id);
      setIsLoading(false);
    }
  }, [
    shippingRequestDetails.length > 0 &&
      materialShippingDetails.length > 0 &&
      updateShipping &&
      updateShipping.materialRes &&
      updateShipping.materialRes.length &&
      updateShipping.materialRes[updateShipping.materialRes.length - 1].id,
  ]);

  useEffect(() => {
    const createdMaterial = !!sessionStorage.getItem("newAddedMaterial")
      ? JSON.parse(sessionStorage.getItem("newAddedMaterial"))
      : false;
    if (createdMaterial) {
      
      handleMaterialEdit();
      setMaterailName({
        id: createdMaterial.id,
        description: createdMaterial.description,
        label: createdMaterial.description,
        value: createdMaterial.id,
        approved: createdMaterial.approved,
        is_hazardous: createdMaterial.is_hazardous,
        hazardous_sds:createdMaterial.hazardous_sds,
        transport_class: createdMaterial.transport_class,
        un_no: createdMaterial.un_no,
        pack_group:createdMaterial.pack_group,
      });
      setHtsCode(createdMaterial.statistical_material_number_usa);
      setMaterialSpecificData({
        hts_code_germany: createdMaterial.statistical_material_number_grmny || "",
        hts_code_china: createdMaterial.statistical_material_number_china || ""
      })
      setPckGrp(createdMaterial.pack_group)
      sessionStorage.setItem("newAddedMaterial", "");
    }
  }, []);

  //To set empty material.
  useEffect(() => {
    if (
      materialShippingDetails.length === 0 &&
      shippingMaterialSelect.length === 0
    ) {
      const materail = setEmptyMaterail();
      actions.addShippingMaterialSelect([materail]);
      actions.materailIdSelect(materail.id);
    }
  }, [materialShippingDetails]);

  const isNotEditable = ([
    STATUS.INBOX,
    STATUS.READY_FOR_DELIVERY,
    STATUS.IN_DELIVERY,
    STATUS.REGULATORY_REVIEWER_1,
    STATUS.REGULATORY_REVIEWER_2,
  ].includes(currentStatus) || (shippingActionType === INSPECT || shippingActionType === SUBMIT))

  useEffect(() => {
    if (isNotEditable && currentStatus === STATUS.INBOX) {
      disableInputFieldsBySelector(
        '[data-name="package_details"], [data-name="material_edit_form"]',
      );
      enableInputFieldsBySelector(
        '[data-name="file_upload_field"], [data-name="hts_code_field"], [data-name="hts-fields"],[data-name="quantity_of_container"],[data-name="total_change_material"]',
      );
    } else if (isNotEditable) {
      disableInputFieldsBySelector(`[data-name="package_details"],
                                    [data-name="package_details_text"],
                                    [data-name="material_edit_form"]`);
    }
}, [
    currentStatus,
    actionType,
    materialShippingDetails,
    shippingMaterialSelect,
  ]
);
  const hnaldeChageHazardous = (e) => {
    setIsHazardous(e.target.checked);
  };
  const hnaldeChageHazardousds = (e) => {
    setHazardousds(e.target.checked);
  };
  const handleChangeBlindedMaterial = (e) => {
    setIsBlindedMaterial(e.target.checked);
  };
  const hnaldeChageTransportClassification = (e) => {
    setTransportClassification(e.target.value);
  };
  const hnaldeChageDocuments = (e) => {
    setDocuments(e);
  };
  const hnaldeChageQuantity = (e) => {
    if (Math.abs(e.target.value) > 0) {
      setQuantity(Math.abs(e.target.value));
    } else {
      setQuantity(null);
    }
  };
  const hnaldeChageUnit = (e) => {
    if (Math.abs(e.target.value) > 0) {
      setUnit(Math.abs(e.target.value));
    } else {
      setUnit(null);
    }
  };
  const handleChangeUnitType = (value) => {
    setUnitType(value.target.value);
  };
  const handleChangeContainerType = (value) => {
    setContainerType(value.target.value);
  };
  const handleCurency = (value) => {
    setCurency(value.target.value);
  };
  const hnaldeChageManufactureCountry = (e) => {
    setManufactureCountry(e.target.value);
  };
  const handleMaterialName = (value) => {
    setMaterailName(value);
      setMaterialSpecificData({
        hts_code_germany: value.statistical_material_number_grmny || "",
        hts_code_china: value.statistical_material_number_china || ""
      });
      setHtsCode(value.statistical_material_number_usa || "");
   
  };

  const hnaldeChageAmountAndUnit = (e) => {
    setAmountAndUnit(e.target.value);
  };
  const handleHtsCode = (e) => {
    setHtsCode(e.target.value);
  };
  const handleChageBatchNo = (e) => {
    setBatchNo(e.target.value);
  };

  const handleChangeAggregation = (e) => {
    setAggregation(e.target.value);
  };

  const handleChangeCountryOrigin = (e) => {
    setCountryOrigin(e.target.value);
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const hnaldeChageValueOfMaterial = (e) => {
    if (Math.abs(e.target.value) > 0) {
      setValueOfMaterial(Math.abs(e.target.value));
    } else {
      setValueOfMaterial(null);
    }
  };
  const validateMaterialAvailDate = () => {
    if (
      new Date(extra.date_material_available_to_ship).getTime() <
      new Date().getTime()-24*60*60*1000
    ) {
      toast.error(t("shipping_validation.material_avail_past"));
      setMaterialAvailStatus({
        errorCheck: true,
        message: t("shipping_validation.material_avail_past"),
      });
    } else {
      setMaterialAvailStatus({
        errorCheck: false,
        message: "",
      });
    }
  };


  const checkValidNeedMatDate = ()=>{

    if(typeof(validMatNeedDate) === 'undefined'){
      return false;
    }
    else{
      return (extra.date_material_needed_on_site)?new Date(extra.date_material_needed_on_site).getTime() < extra.validMaterialNeededDate:false 
    }
  }

useEffect(()=>{
  if(shippingActionType==='NEW' || shippingRequestDetails.length  && shippingRequestDetails[0].status === 'INPUT' && shippingActionType==='EDIT' ){
    validateMaterialNeedDate()
  }
},[extra.validMaterialNeededDate])
  const validateMaterialNeedDate = () =>{ 
    if(extra.date_material_available_to_ship && extra.validMaterialNeededDate!= null &&checkValidNeedMatDate())
    {
      let Error = `Please choose Date Material Needed On Site  after ${WeekDay[new Date(extra.validMaterialNeededDate).getDay()]}, ${Months[new Date(extra.validMaterialNeededDate).getMonth()]} ${new Date(extra.validMaterialNeededDate).getDate()}, ${new Date(extra.validMaterialNeededDate).getFullYear()}`
      toast.error(Error)
      setMaterialNeedStatus({
        errorCheck:true,
        message:Error
      })
    }
    else{
      setMaterialNeedStatus({
        errorCheck:false,
        message:""
      })
    }

}
  const checkValidations = (shippingMaterial, type) => {
    const {
      materailName,
      amountAndUnit,
      valueOfMaterial,
      batchNo,
      aggregation,
      countryOrigin,
      isHazardous,
      hazardous_sds,
      isBlindedMaterial,
      documents,
      transportClassification,
      packGroup,
      quantity,
      unit,
      unitType,
      htsCode,
      manufactureCountry,
      curency,
    } = shippingMaterial;

    let errorTab = 0;
    let errorCheck = false;
    let errorList = [];
    if (materialAvailStatus.errorCheck) {
      toast.error(materialAvailStatus.message);
      errorCheck = materialAvailStatus.errorCheck;
      errorList.push(materialAvailStatus.message);
    } else if (materialNeedStatus.errorCheck) {
      toast.error(materialNeedStatus.message);
      errorCheck = materialNeedStatus.errorCheck;
      errorList.push(materialNeedStatus.message);
    }

    if (documents?.length > 0) {
      const totalSize = documents.reduce((acc, file) => acc + Number(file.size), 0);
      if (totalSize >= FILE_SIZE_LIMIT) {
        errorCheck = true;
        errorList.push(t("file_manager.file_limit_message"));
        errorTab = 2
      }
    }

    if (!materailName.id) {
      errorCheck = true;
      errorList.push(t("shipping_material_validations.material_name_req"));
      errorTab = 2;
    }

    if (documents.length === 0) {
      errorCheck = true;
      errorList.push(t("shipping_material_validations.documents_req"));
      errorTab = 2;
    }
    if (
      userType.includes(
        USERROLES.PROCESSMANAGER || userType.includes(USERROLES.ADMIN),
      )
    ) {
      // if (!htsCode) {
      //   errorCheck = true;
      //   errorList.push(t("shipping_material_validations.hts_req"));
      // }
    }

    if (!quantity) {
      errorCheck = true;
      errorList.push(t("shipping_material_validations.quantity_req"));
      errorTab = 2;
    }

    if (!unit) {
      errorCheck = true;
      errorList.push(t("shipping_material_validations.unit_req"));
      errorTab = 2;
    }
    if (!unitType || !allUnits.find(item => item.abbrevaton === unitType && item.isactive === true)) {
      errorCheck = true;
      errorList.push(t("shipping_material_validations.unit_type_req"));
      errorTab = 2;
    }
    if (extra.type_of_shipment && extra.type_of_shipment !== "INNER_EU" &&
      extra.type_of_shipment !== "DOMESTIC" && !amountAndUnit) {
      errorCheck = true;
      errorList.push(t("shipping_material_validations.total_req"));
      errorTab = 2;
    }
    if (extra.type_of_shipment && extra.type_of_shipment !== "INNER_EU" &&
      extra.type_of_shipment !== "DOMESTIC" && (!curency || curency === "0")) {
      errorCheck = true;
      errorList.push(t("shipping_material_validations.currency_req"));
      errorTab = 2;
    }

    if (!aggregation || phases.find(item => item.id === aggregation)?.valid === 0) {
      errorCheck = true;
      errorList.push(t("shipping_material_validations.agg_req"));
      errorTab = 2;
    }

    if (!countryOrigin) {
      errorCheck = true;
      errorList.push(t("shipping_material_validations.country_req"));
      errorTab = 2;
    }
    if (!manufactureCountry) {
      errorCheck = true;
      errorList.push(
        t("process_shipping_request.country_of_manufacturer") +
          " " +
          t("commonlabels.is_req"),
      );
      errorTab = 2;
    }
    if (errorCheck) {
      setError(true);
      setErrorMessages(errorList);
      setActionType(type);
      dispatch(updateErrorTab(errorTab));
      return true;
    }

    return false;
  };

  const closeErrorMessage = () => {
    setError(false);
    dispatch(updateErrorTab(0));
  };

  const handleCloseRedirect = async () => {
    actions.clearshippingRequest();
    actions.setShippingSearchData(shippingSearchData);
    actions.setTableOptions(tableOptions)
    actions.setAdminShippingTableOptions(tableAdminShippingOptions)
    if(mailLinkData?.isAccessedFromMAil){
      window.location = window.location.origin;
      return;
    }
    if (!!redirectTo) {
      history.push(redirectTo);
      if (redirectTo === "/myShippings") {
        actions.setNavKey(NAV_KEYS.MY_SHIPPING_REQUESTS);
      }
    } else {
      history.push("/myShippings");
      actions.setNavKey(NAV_KEYS.MY_SHIPPING_REQUESTS);
    }
  };

  const handleMaterialSaveAndClose = async (newStatus = null, redirectFlag,mandatoryFlag) => {
    if (actionType === "View") {
    
          await handleMaterialSave(newStatus, redirectFlag, mandatoryFlag);
          if(redirectFlag === true || redirectFlag === undefined){
          await handleCloseRedirect();
          }
        }
    else {
      toast.warning(
        'Click on "Save Material" first to save the material in the grid.',
      );
    }
  };

  const handleMaterialSubmit = async () => {
    if (actionType === "View") {
      let errorTab = 0;
      let errorCheck = false;
      let errorList = [];
      if (materialAvailStatus.errorCheck) {
        toast.error(materialAvailStatus.message);
        errorCheck = materialAvailStatus.errorCheck;
        errorList.push(materialAvailStatus.message);
      } else if (materialNeedStatus.errorCheck) {
        toast.error(materialNeedStatus.message);
        errorCheck = materialNeedStatus.errorCheck;
        errorList.push(materialNeedStatus.message);
      }
      if (!distributionTempValue) {
        errorCheck = true;
        errorList.push(
          t("shipping_validation.Distribution_temperature_is_required"),
        );
        errorTab = 1;
      }
      if (!extra?.handling_type) {
        errorCheck = true;
        errorList.push(
          t("shipping_validation.temperature_is_required")
        );
        errorTab = 1;
      }
      if (distributionTemperature.find(item => item.id === distributionTempValue)?.valid === 0) {
        errorCheck = true;
        errorList.push(
          t("shipping_validation.Distribution_temperature_is_required"),
        );
        errorTab = 1;
      }
      if (!extra.type_of_shipment) {
        errorCheck = true;
        errorList.push(t("shipping_validation.Type_of_shipment_is_required"));
        errorTab = 1;
      }
      if (shippingType.find(item => item.id === extra.type_of_shipment)?.isactive === false) {
        errorCheck = true;
        errorList.push(
          t("shipping_validation.Type_of_shipment_is_required"),
        );
        errorTab = 1;
      }
      if (
        _.size(shippingMaterialSelectedValue) &&
        (!shippingMaterialSelectedValue.documents || !_.size(shippingMaterialSelectedValue.documents))
      ) {
        errorCheck = true;
        errorList.push(
          t("shipping_material_validations.doc_required_validation"),
        );
        errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
      }
      if (!extra.date_material_available_to_ship) {
        errorCheck = true;
        errorList.push(
          t("shipping_request.date_material_available_to_ship") +
            " " +
            t("commonlabels.is_req"),
        );
        errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
      }

      if (!extra.date_material_needed_on_site) {
        errorCheck = true;
        errorList.push(
          t("shipping_request.date_material_needed_on_site") +
            " " +
            t("commonlabels.is_req"),
        );
        errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
      }

      if (!shippingDeputySelect.length) {
        errorCheck = true;
        errorList.push(t("shipping_validation.deputy_is_req"));
        errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
      }

      if (!destinationAddrSelect || !destinationAddrSelect.id) {
        errorCheck = true;
        errorList.push(t("shipping_validation.destination_is_req"));
        errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
      }

      if (!finalRecipientSelect || !finalRecipientSelect.id) {
        errorCheck = true;
        errorList.push(t("shipping_validation.final_res_is_req"));
        errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
      }
      if (
        get(extra, "is_mat_food_produced_animal", null) === null ||
        get(extra, "is_mat_client_used_animal", null) === null
      ) {
        errorCheck = true;
        errorList.push(t("shipping_validation.review_questions"));
        errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
      }
      if (!shippingMaterialSelectedValue || !shippingMaterialSelectedValue.id) {
        errorCheck = true;
        errorList.push(t("shipping_validation.material_is_req"));
        errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
      }
      if (extra.prp_use_of_material.trim().length === 0) {
        errorCheck = true;
        errorList.push(t("shipping_validation.prop_use_of_materials_req"));
        errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
      }
      if (
        shipper[0]?.recipient_id === undefined ||
        shipper[0]?.recipient_id === null
      ) {
        errorCheck = true;
        errorList.push(t("shipping_validation.shipper_info_required"));
        errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
      }
      if (extra.type_of_shipment && extra.type_of_shipment !== "INNER_EU" &&
        extra.type_of_shipment !== "DOMESTIC" && !shippingMaterialSelectedValue.amountAndUnit) {
        errorCheck = true;
        errorList.push(t("shipping_material_validations.total_req"));
        errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
      }
      if (extra.type_of_shipment && extra.type_of_shipment !== "INNER_EU" &&
        extra.type_of_shipment !== "DOMESTIC" && (!shippingMaterialSelectedValue.curency || shippingMaterialSelectedValue.curency === "0")) {
        errorCheck = true;
        errorList.push(t("shipping_material_validations.currency_req"));
        errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
      }
      if (attachements && attachements.length > 0) {
        const totalSize = attachements.reduce((acc, file) => acc + Number(file.size), 0);
        if (totalSize >= FILE_SIZE_LIMIT) {
          errorCheck = true;
          toast.error(t("file_manager.file_limit_message"))
          errorList.push(t("file_manager.file_limit_message"));
          errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
        }
      }

      if (errorCheck) {
        setError(true);
        setErrorMessages(errorList);
        dispatch(updateErrorTab(errorTab));
        const containerElem = document.querySelector('[data-testid="wrapper"]');
        containerElem.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setIsLoading(false);
        return true;
      }
      await handleMaterialSave(STATUS.REGULATORY_REVIEWER_1, true);
      await handleCloseRedirect();
    } else {
      toast.warning(
        'Click on "Save Material" first to save the material in the grid.',
      );
    }
  };

  const handleCloseAlertConfirmation = () => {
    setAlertConfirmation(false);
    handleCloseRedirect();
  };

  const handleShippingRequestSaveAndClose = () => {
    setAlertConfirmation(false);
    handleCloseRedirect();
  };

  const handleCancleAlertConfirmation = () => {
    setAlertConfirmation(false);
  };

  const handleChangeId = (data) => {
    actions.clearShippingMaterialSelectedValue();
    const material = shippingMaterialSelect.find((m) => m.id === data.id) || {};
    actions.shippingMaterialSelectedValue(material);
    actions.materailIdSelect(data.id);
    setActionType("View");
  };
  // const compareDates = (d1, d2) => {
  //   let date1 = (Date.parse(d1));
  //   let date2 = (Date.parse(d2));
  //   if (date1 > date2) {
  //     return (date1);
  //   }
  //   else if (date1 < date2) {
  //     return (date2);
  //   }
  //   else {
  //     return date1;
  //   }
  // };

  const validateMaterial = (meterailId) => {
    const shippingMaterial = {
      id: meterailId,
      materailName: {
        id: materailName.id,
        description: materailName.description,
        label: materailName.description,
        value: materailName.id,
        approved: materailName.approved,
        un_no: materailName.un_no,
      },
      un_no: unNo,
      amountAndUnit,
      valueOfMaterial,
      batchNo,
      aggregation,
      countryOrigin,
      comment,
      isHazardous,
      isBlindedMaterial,
      hazardousds,
      transportClassification,
      packGroup,
      documents,
      quantity,
      unit,
      unitType,
      containerType,
      curency,
      manufactureCountry,
      htsCode,
      ...materialSpecificData
    };
    if (checkValidations(shippingMaterial, actionType)) {
      return true;
    }
    return shippingMaterial;
  };

  const handleMaterialEditSave = () => {
    const shippingMaterial = validateMaterial(materailIdSelect);
    if (shippingMaterial === true) {
      return true;
    }
    const updateShippingMaterialList = updateShippingMaterail(
      shippingMaterialSelect,
      materailIdSelect,
      shippingMaterial,
    );
    
    actions.addShippingMaterialSelect(updateShippingMaterialList);
    actions.materailIdSelect(materailIdSelect);
    actions.shippingMaterialSelectedValue(shippingMaterial);

    setActionType("View");
    setError(false);
  };

  const handleMaterialEdit = () => {
   
    setActionType("Edit");
    const material =
      shippingMaterialSelect.find((m) => m.id === materailIdSelect) || {};
   
    actions.shippingMaterialSelectedValue(material);
    setMaterailName(material.materailName);
    setValueOfMaterial(material.valueOfMaterial);
    setCountryOrigin(material.countryOrigin);
    setBatchNo(material.batchNo);
    setAmountAndUnit(material.amountAndUnit);
    setAggregation(material.aggregation);
    setComment(material.comment);
    setIsHazardous(material.isHazardous);
    setIsBlindedMaterial(material.isBlindedMaterial);
    setUnNo(get(material, "un_no", ""));
    setHazardousds(material.hazardousds)
    setTransportClassification(material.transportClassification);
    setPckGrp(material.packGroup);
    setDocuments(material.documents);
    setQuantity(material.quantity);
    setUnit(material.unit);
    setUnitType(material.unitType);
    setCurency(material.curency);
    setManufactureCountry(material.manufactureCountry);
    setFileName(material.hazard_doc_name);
    setFilePath(material.hazard_doc_path);
    setContainerType(material.containerType);
    
   
      setMaterialSpecificData({
        hts_code_germany: material.hts_code_germany,
        hts_code_china: material.hts_code_china,
      });
      setHtsCode(material.htsCode);
    
  
  };
  const handleMaterialNew = () => {
    const allMaterials = getAllMaterials(materialShippingDetails);
    const materail = setEmptyMaterail();
    if (shippingActionType === "EDIT") {
      actions.addShippingMaterialSelect([...shippingMaterialSelect, materail]);
    } else {
      actions.addShippingMaterialSelect([
        // ...allMaterials,
        ...shippingMaterialSelect,
        materail,
      ]);
    }
    actions.shippingMaterialSelectedValue(materail);
    actions.materailIdSelect(materail.id);
    //handleMaterialEditCancel();
    // handleMaterialEdit();
    setEditMode(true);
  };
  const handleMaterialEditCancel = () => {
    setActionType("View");
  };

  const handleMaterialSave = async (newStatus = null, isSubmit = false, mandatoryFlag) => {
    if (actionType !== "View") {
      toast.warning(
        'Click on "Save Material" first to save the material in the grid',
      );
      return;
    }
    setIsLoading(true);
    let status = null;
    try {
      let shipmentSpecificVal = "RECEIVE_DATE";
      if (shipmentSpecificValue === "latestArrivalDate") {
        shipmentSpecificVal = "READY_DATE";
      }

      let createdOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (!!shippingCreateDateValue) {
        createdOn = moment(shippingCreateDateValue).format(
          "YYYY-MM-DD HH:mm:ss",
        );
      }

      let dispatchMONVal = null;
      if (!!dispatchMONValue) {
        dispatchMONVal = dispatchMONValue;
      }

      let dispatchLEVVal = null;
      if (!!dispatchLEVValue) {
        dispatchLEVVal = dispatchLEVValue;
      }

      let dateOfReceiptVal = null;
      if (!!dateOfReceiptValue) {
        dateOfReceiptVal = dateOfReceiptValue;
      }
      const submitted_on_Date = FormatDate(shippingRequestDetails[0]?.submitted_on)
      const payload = {
        internal_distribution_number: internalOrderIdValue,
        distributiontemperature_id: distributionTempValue,
        created_on: createdOn,
        c_comment: shippingCreateCommentValue,
        address_id: Object.keys(destinationAddrSelect).length
          ? destinationAddrSelect.id
          : "",
        recipient_id: Object.keys(finalRecipientSelect).length
          ? finalRecipientSelect.id
          : "",
        shipping_deputies: shippingDeputySelect,
        shippingMaterials: shippingMaterialSelect,
        shipment_constraint_type: shipmentSpecificVal,
        created_by: sessionStorage.getItem("userName"),
        shippingMaterialSelected: shippingMaterialSelectedValue,
        shipment_date_mon: dispatchMONVal,
        shipment_date_lev: dispatchLEVVal,
        // shipment_constraint_date: dateOfReceiptVal,
        shipment_constraint_date: null,
        shipment_close_date: dateOfReceiptVal,
        tracking_number: trackingNumValue,
        status_comment: statusQuoValue,
        sap_delivery_number: sapDeliveryNumValue,
        attachements,
        shipper,
        ...extra,
        date_material_available_to_ship: extra?.date_material_available_to_ship
          ?handleTimeZoneOffset(extra.date_material_available_to_ship)
          : null,
        date_material_needed_on_site: extra?.date_material_needed_on_site
          ? handleTimeZoneOffset(extra.date_material_needed_on_site)
          : null,
          newShippingRequestAlertComments:newShippingRequestCommentsList? newShippingRequestCommentsList: null,
          handling_type:extra?.handling_type,
          is_shippment_hazardous:extra?.is_shippment_hazardous,
          clientTimeZone :clientTimeZone,
          submitted_on_Date:submitted_on_Date
          // shippment_hazardous_comments:extra?.shippment_hazardous_comments
        };

      let shippingRequestData;

      //Handle new create scenarios.
      if (
        Array.isArray(getShippingResponse) &&
        getShippingResponse.length > 0
      ) {
        shippingRequestData = getShippingResponse[0];
      } else if (
        Array.isArray(shippingRequestDetails) &&
        shippingRequestDetails.length > 0
      ) {
        //Handles update scenaios.
        shippingRequestData = shippingRequestDetails[0];
      }

      if (!shippingRequestData) {
        const fields = {
          status: isSubmit ? newStatus : STATUS.INPUT,
        };
        const newPayLoad = { ...payload, ...fields };
        const res = await actions.createShipping(newPayLoad);

        toast.success(t("commonlabels.savedSuccessfullyMsg"));

        await actions.getShippingRequestDetails({ id: res.data[0].id });
        await actions.getMaterialShippingDetails({ id: res.data[0].id });
        await actions.getShippingAttachment({ id: res.data[0].id });
      } else if (shippingRequestData && shippingRequestData.id) {
        const errorMsg=[];
        if((shippingRequestData?.status === STATUS.INBOX && mandatoryFlag === true)){
          const materialsWithoutSDS = shippingMaterialSelect.filter(
            (item) => (item.hazardous_sds  || item.is_hazardous)
          );
         
          if (get(extra, "is_shippment_hazardous") === null) {
            errorMsg.push(t("shipping_validation.dangerous_goods_shipments"));
          }
          if (materialsWithoutSDS?.length>0 &&  get(extra, "is_shippment_hazardous") === false && (extra?.shippment_hazardous_comments?.trim()?.length === 0 || (get(extra, "shippment_hazardous_comments") === null || get(extra, "shippment_hazardous_comments") === undefined))) {
            errorMsg.push(t("shipping_validation.comment_required"));
        }
      }
        if (shippingRequestData?.status === STATUS.READY_FOR_DELIVERY) {
          if (dateOfReceiptValue) {
            if (!extra?.logistics_partner) {
              errorMsg.push(t("shippingrequest.logistic_partner_required"));
            }
            if (!trackingNumValue) {
              errorMsg.push(t("shippingrequest.tracking_number_required"));
            }
            if (!dispatchLEVValue || !dispatchMONValue) {
              errorMsg.push(t("shippingrequest.dispatch_date_required"));
            }
            if (extra?.logistics_partner && trackingNumValue && (dispatchLEVValue || dispatchMONValue)) {
              status = STATUS.IN_DELIVERY;
            }
          }
          else if (!dateOfReceiptValue) {
            status = STATUS.READY_FOR_DELIVERY;
          }
        }

        if ((newStatus === STATUS.READY_FOR_DELIVERY || shippingRequestData?.status === STATUS.INBOX) && isSubmit && mandatoryFlag === true) {
          const materialsWithoutHtsCode = shippingMaterialSelect.filter(
            (item) => !(item.htsCode || item.hts_code_germany || item.hts_code_china)
          );

          if (materialsWithoutHtsCode.length>0 && extra.type_of_shipment === 'INTERNATIONAL') {
            errorMsg.push(t("shippingrequest.hts_required"));
          }
        }
        if(shippingRequestData?.status === STATUS.INBOX){
          //shippingRequestData = {...shippingRequestData,['status']: STATUS.READY_FOR_DELIVERY}
          status = STATUS.READY_FOR_DELIVERY
        }
        if (errorMsg.length>0) {
          setIsLoading(false);
          let error= toast.error( <div>
            <ul>
              {errorMsg.map((point,index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>);
          return error;
        }

        const fields = {
          shippingResponse: [shippingRequestData],
          updated_by: sessionStorage.getItem("userName"),
          updated_on: null,
          status: newStatus
            ? newStatus
            : status
            ? status
            : shippingRequestData.status,
        };

        const newPayLoad = { ...payload, ...fields };
        const statusData = await actions.getShippingStatus(shippingRequestData.id);
        if((get(statusData,"data[0].status",false) === STATUS.READY_FOR_DELIVERY && fields.status===STATUS.READY_FOR_DELIVERY && shippingRequestData?.status === STATUS.INBOX) || 
          (get(statusData,"data[0].status",false) === STATUS.INPUT && fields.status===STATUS.READY_FOR_DELIVERY)||
          (get(statusData,"data[0].status",false) === STATUS.READY_FOR_DELIVERY && fields.status===STATUS.INPUT)||
          (get(statusData,"data[0].status",false) === STATUS.INPUT && fields.status===STATUS.INPUT && shippingRequestData?.status === STATUS.INBOX))
        {
          setIsLoading(false);
          toast.error(t("commonlabels.reviewer_error"));
          return;
        }
        const res = await actions.updateShipping(newPayLoad);
        await actions.getShippingRequestDetails(shippingRequestData);
        toast.success(t("commonlabels.editedSuccessfullyMsg"));

        const id = shippingRequestData.id;

        await actions.getMaterialShippingDetails({ id });
        await actions.getShippingAttachment({ id });
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const handleMaterialCancel = () => {
    actions.clearshippingRequest();
    actions.setShippingSearchData(shippingSearchData);
    actions.setTableOptions(tableOptions)
    actions.setAdminShippingTableOptions(tableAdminShippingOptions)
    if (redirectTo === "/alerts") {
      history.push(redirectTo);
      actions.setNavKey(NAV_KEYS.ALERTS_TAGS);
      return;
  }
    if(mailLinkData?.isAccessedFromMAil){
      window.location = window.location.origin;
      return;
    }
    if (!!redirectTo) {
      history.push(redirectTo);
      if (redirectTo === "/myShippings") {
        actions.setNavKey(NAV_KEYS.MY_SHIPPING_REQUESTS);
      }
    } else if (shippingRequestDetails.length > 0) {
      history.push("/myShipping");
      actions.setNavKey(NAV_KEYS.MY_SHIPPING_REQUESTS);
    } else {
      history.push("/shipping");
    }
  };

  const handleMaterialDelete = (id) => {
    if (shippingMaterialSelect.length > 1) {
      const filterShippingMaterials = shippingMaterialSelect.filter(
        (m) => m.id !== id,
      );
      actions.addShippingMaterialSelect(filterShippingMaterials);
      const nextSelectedMaterial = filterShippingMaterials[0];
      actions.materailIdSelect(nextSelectedMaterial.id);
      //handleChangeId(shippingMaterialSelect[0]);
      actions.shippingMaterialSelectedValue(nextSelectedMaterial);
      actions.materailIdSelect(nextSelectedMaterial.id);
      handleMaterialEditCancel();
    } else {
      const materail = setEmptyMaterail();
      actions.addShippingMaterialSelect([materail]);
      handleChangeId(materail);
      handleMaterialEditCancel();
    }
  };

  const handleMaterialCopy = () => {
    let copiedMaterial = shippingMaterialSelect.find(
      (m) => m.id === materailIdSelect,
    );
    if (copiedMaterial) {
      copiedMaterial = _.cloneDeep(copiedMaterial);
      copiedMaterial.id = new Date().getTime();
      actions.addShippingMaterialSelect([
        ...shippingMaterialSelect,
        copiedMaterial,
      ]);
    }
  };

  // edit material
  function EditMaterialBtn() {
    const isDisable =
      shippingMaterialSelect.length === 0 ||
      (isNotEditable && currentStatus !== STATUS.INBOX);
    return (
      <Button
        disabled={isDisable}
        variant="contained"
        size="small"
        className={isDisable ? "mb-3" : "Savebutton mb-3"}
        onClick={handleMaterialEdit}
      >
        {" "}
        {t("commonlabels.edit")}{" "}
      </Button>
    );
    // }
  }

  // edit material save
  function EditMaterialSaveBtn() {
    return (
      <>
        <If condition={["New", "Edit"].includes(actionType)}>
          <Then>
            <Button
              disabled={
                (isNotEditable && currentStatus !== STATUS.INBOX) ||
                !_([
                  USERROLES.PROCESSMANAGER,
                  USERROLES.APPLICANT,
                  USERROLES.ADMIN,
                ]).intersection(userType)
              }
              variant="contained"
              size="small"
              className="Savebutton mb-3"
              onClick={handleMaterialEditSave}
            >
              {" "}
              {t("material.savematerial")}{" "}
            </Button>
          </Then>
        </If>
      </>
    );
    // }
  }

  // Edit material cancel
  function EditMaterialCancelBtn() {
    return (
      <>
        <If condition={["New", "Edit"].includes(actionType)}>
          <Then>
            <Button
              disabled={
                (isNotEditable && currentStatus !== STATUS.INBOX) ||
                !_([
                  USERROLES.PROCESSMANAGER,
                  USERROLES.APPLICANT,
                  USERROLES.ADMIN,
                ]).intersection(userType)
              }
              variant="contained"
              size="small"
              className="cancelbutton mb-3"
              onClick={handleMaterialEditCancel}
            >
              {" "}
              {t("material.cancel_material")}{" "}
            </Button>
          </Then>
        </If>
      </>
    );
  }

  return (
  <div>
    <div className="tab-bar-styles">
      <TabMenu />
      <SaveChangesRequest
        classes={classes}
        handleMaterialSubmit={handleMaterialSubmit}
        handleMaterialSaveAndClose={handleMaterialSaveAndClose}
        handleMaterialSave={handleMaterialSave}
        handleMaterialCancel={handleMaterialCancel}
        handleCancleAlertConfirmation={handleCancleAlertConfirmation}
        currentStatus={currentStatus}
        shippingActionType={shippingActionType}
      />
    </div>
    <LoadingOverlay active={isLoading} spinner className="custom-overlay-wrapper">
      <Box sx={{ flexGrow: 1 }} m={2} pt={1} className="p-0 mt0">
        {isError && (
          <div>
            <ErrorMessage
              closeErrorMessage={closeErrorMessage}
              errorMessages={errorMessages}
            />{" "}
          </div>
        )}

        {alertConfirmation && (
          <AlertConfirmation
            alertConfirmation={true}
            handleCloseAlertConfirmation={handleCloseAlertConfirmation}
            handleShippingRequestSaveAndClose={
              handleShippingRequestSaveAndClose
            }
            handleCancleAlertConfirmation={handleCancleAlertConfirmation}
            title="Confirmation"
            message="The data was changed. Do you want to save you changes ?"
          />
        )}

        {false &&
          (getShippingResponse.length > 0 ||
            shippingRequestDetails.length > 0) && (
            <ShippingMaterialMoareDetails
              getShippingResponse={getShippingResponse}
              shippingMaterialSelectedValue={shippingMaterialSelectedValue}
            />
          )}

        {![
          STATUS.INPUT,
          STATUS.REGULATORY_REVIEWER_1,
          STATUS.REGULATORY_REVIEWER_2,
        ].includes(currentStatus) &&
        shippingMaterialSelect.length &&
        shippingMaterialSelect?.find(
          (item) =>
            !_.get(item, "materailName.approved", true) ||
            !_.get(item, "approved", true),
        )  &&
        (!ifRequestCompletedApprovals(shippingRequestDetails[0]?.status) || (shippingActionType === INSPECT || shippingActionType === SUBMIT))? (
          <Alert severity="warning">Some materials are not approved.</Alert>
        ) : null}

        <If
          condition={
            [NEW, EDIT].includes(shippingActionType) &&
            (userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.APPLICANT))
          }
        >
          <Then>
            <Alert severity="warning">
              {t("shipping_request.save_shipping_request_alert_of_fixed_bar")}
              <br></br>
              {t("shipping_request.save_mandary_shipping_request_alert")}
              {/* <br></br>
              {t("shipping_request.notice9")} */}
            </Alert>
            <br />
          </Then>
        </If>

        <div className="row border materialcontainer ">
          <div className="col-lg-6 mt-2 mb-2 col-12">
            <Stack direction="row" spacing={1}>
              <EditMaterialSaveBtn />
              <EditMaterialCancelBtn />
            </Stack>
            <Card variant="outlined">
              <CardContent data-name="material_edit_form">
                {actionType !== "View" && (
                  <ShippingMaterialForm
                    classes={classes}
                    shippingMaterials={shippingMaterials}
                    countries={countries}
                    phases={phases}
                    handleMaterialName={handleMaterialName}
                    handleHtsCode={handleHtsCode}
                    htsCode={htsCode}
                    hnaldeChageAmountAndUnit={hnaldeChageAmountAndUnit}
                    handleChageBatchNo={handleChageBatchNo}
                    handleChangeAggregation={handleChangeAggregation}
                    handleChangeCountryOrigin={handleChangeCountryOrigin}
                    handleChangeComment={handleChangeComment}
                    hnaldeChageValueOfMaterial={hnaldeChageValueOfMaterial}
                    shippingMaterialSelectedValue={
                      shippingMaterialSelectedValue
                    }
                    materailName={materailName}
                    amountAndUnit={amountAndUnit}
                    valueOfMaterial={valueOfMaterial}
                    batchNo={batchNo}
                    aggregation={aggregation}
                    countryOrigin={countryOrigin}
                    comment={comment}
                    isHazardous={isHazardous}
                    hazardousds={hazardousds}
                    isBlindedMaterial={isBlindedMaterial}
                    unNo={unNo}
                    setUnNo={setUnNo}
                    hnaldeChageHazardous={hnaldeChageHazardous}
                    hnaldeChageHazardousds={hnaldeChageHazardousds} 
                    handleChangeBlindedMaterial={handleChangeBlindedMaterial}
                    transportClassification={transportClassification}
                    hnaldeChageTransportClassification={
                      hnaldeChageTransportClassification
                    }
                    documents={documents}
                    hnaldeChageDocuments={hnaldeChageDocuments}
                    quantity={quantity}
                    hnaldeChageQuantity={hnaldeChageQuantity}
                    unit={unit}
                    hnaldeChageUnit={hnaldeChageUnit}
                    unitType={unitType}
                    handleChangeUnitType={handleChangeUnitType}
                    curency={curency}
                    handleCurency={handleCurency}
                    manufactureCountry={manufactureCountry}
                    hnaldeChageManufactureCountry={
                      hnaldeChageManufactureCountry
                    }
                    allCurrency={allCurrency}
                    allUnits={allUnits}
                    allContainer={allContainer}
                    fileName={fileName}
                    filePath={filePath}
                    handleChangeContainerType={handleChangeContainerType}
                    containerType={containerType}
                    shippingRequestDetails={shippingRequestDetails}
                    setMaterialSpecificData={setMaterialSpecificData}
                    materialSpecificData={materialSpecificData}
                    setPckGrp={setPckGrp}
                    packGroup = {packGroup}
                  />
                )}

                {actionType === "View" && (
                  <ShippingMaterialDetails
                    shippingMaterialSelectedValue={
                      shippingMaterialSelectedValue
                    }
                    curencys={curency}
                    countries={countries}
                    phases={phases}
                    materialSpecificData={materialSpecificData}
                    shippingRequestDetails={shippingRequestDetails}
                    allUnits={allUnits}
                    allCurrency={allCurrency}
                    allContainer={allContainer}
                  />
                )}
              </CardContent>
            </Card>
          </div>
          <div className="col-lg-6 mt-2 mb-2 col-12">
            <ShippingMaterialList
              shippingMaterialSelect={shippingMaterialSelect}
              materialId={materailIdSelect}
              handleMaterialNew={handleMaterialNew}
              handleMaterialDelete={handleMaterialDelete}
              handleMaterialCopy={handleMaterialCopy}
              actionType={actionType}
              handleChangeId={handleChangeId}
              shippingRequestDetails={shippingRequestDetails}
              countries={countries}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              isNotEditable={isNotEditable}
              editButton={(shippingActionType === INSPECT || shippingActionType === SUBMIT)?"":<EditMaterialBtn />}
              allUnits={allUnits}
              shippingActionType={shippingActionType}
            />
            <div className="col-lg-12 form-label pl-0">
              {t("material.proposed_use_of_materials")}
              <span className="req">*</span>{" "}
              <Tooltip
                title={t("shipping_request.purposed_use_of_material_tooltip")}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>
            <TextareaAutosize
              data-name="package_details"
              disabled={
                !(
                  userType.includes(USERROLES.PROCESSMANAGER) ||
                  userType.includes(USERROLES.APPLICANT) ||
                  userType.includes(USERROLES.ADMIN)
                ) ||
                (shippingActionType === INSPECT || shippingActionType === SUBMIT)
              }
              id="prp_use_of_material"
              name="prp_use_of_material"
              aria-label=" "
              margin="normal"
              variant="outlined"
              size="small"
              minRows={5}
              style={{ width: "100%" }}
              value={extra?.prp_use_of_material || ""}
              onChange={(e) =>
                actions.setExtraData({
                  ...extra,
                  [e.target.name]: e.target.value,
                })
              }
            />
            <div
              className="row col-md-12 pl-0 mb10"
              data-name="package_details"
            >
              <div className="col-md-6">
                1) {t("material.material_q1")}
                <span style={{ color: "red" }}>*</span>
              </div>
              <div className="col-md-6">
                {extra.is_mat_client_used_animal ? (
                  <input
                    type="radio"
                    disabled={
                      !(
                        userType.includes(USERROLES.PROCESSMANAGER) ||
                        userType.includes(USERROLES.APPLICANT) ||
                        userType.includes(USERROLES.ADMIN)
                      )||
                      (shippingActionType === INSPECT || shippingActionType === SUBMIT)
                    }
                    value={extra.is_mat_client_used_animal}
                    name="is_mat_client_used_animal"
                    checked="checked"
                    onChange={(e) =>
                      actions.setExtraData({
                        ...extra,
                        ["is_mat_client_used_animal"]: true,
                      })
                    }
                  />
                ) : (
                  <input
                    type="radio"
                    disabled={
                      !(
                        userType.includes(USERROLES.PROCESSMANAGER) ||
                        userType.includes(USERROLES.APPLICANT) ||
                        userType.includes(USERROLES.ADMIN)
                      )||
                      (shippingActionType === INSPECT || shippingActionType === SUBMIT)
                    }
                    value={extra.is_mat_client_used_animal}
                    name="is_mat_client_used_animal"
                    onChange={(e) =>
                      actions.setExtraData({
                        ...extra,
                        ["is_mat_client_used_animal"]: true,
                      })
                    }
                  />
                )}

                {"  "}
                <span> {t("approve_materials.yes")}</span>
                {"  "}
                {extra.is_mat_client_used_animal === false ? (
                  <input
                    type="radio"
                    disabled={
                      !(
                        userType.includes(USERROLES.PROCESSMANAGER) ||
                        userType.includes(USERROLES.APPLICANT) ||
                        userType.includes(USERROLES.ADMIN)
                      )||
                      (shippingActionType === INSPECT || shippingActionType === SUBMIT)
                    }
                    value={extra.is_mat_client_used_animal}
                    name="is_mat_client_used_animal"
                    checked="checked"
                    onChange={(e) =>
                      actions.setExtraData({
                        ...extra,
                        ["is_mat_client_used_animal"]: false,
                      })
                    }
                  />
                ) : (
                  <input
                    type="radio"
                    disabled={
                      !(
                        userType.includes(USERROLES.PROCESSMANAGER) ||
                        userType.includes(USERROLES.APPLICANT) ||
                        userType.includes(USERROLES.ADMIN)
                      )||
                      (shippingActionType === INSPECT || shippingActionType === SUBMIT)
                    }
                    value={extra.is_mat_client_used_animal}
                    name="is_mat_client_used_animal"
                    onChange={(e) =>
                      actions.setExtraData({
                        ...extra,
                        ["is_mat_client_used_animal"]: false,
                      })
                    }
                  />
                )}
                {"  "}
                <span>{t("approve_materials.no")}</span>
              </div>
            </div>
            <div
              className="row col-md-12 pl-0 mt10"
              data-name="package_details"
            >
              <div className="col-md-6">
                2) {t("material.material_q2")}{" "}
                <span style={{ color: "red" }}>*</span>
              </div>
              <div className="col-md-6">
                {extra.is_mat_food_produced_animal ? (
                  <input
                    type="radio"
                    disabled={
                      !(
                        userType.includes(USERROLES.PROCESSMANAGER) ||
                        userType.includes(USERROLES.APPLICANT) ||
                        userType.includes(USERROLES.ADMIN)
                      )||
                      (shippingActionType === INSPECT || shippingActionType === SUBMIT)
                    }
                    value={extra.is_mat_food_produced_animal}
                    name="is_mat_food_produced_animal"
                    checked="checked"
                    onChange={(e) =>
                      actions.setExtraData({
                        ...extra,
                        ["is_mat_food_produced_animal"]: true,
                      })
                    }
                  />
                ) : (
                  <input
                    type="radio"
                    disabled={
                      !(
                        userType.includes(USERROLES.PROCESSMANAGER) ||
                        userType.includes(USERROLES.APPLICANT) ||
                        userType.includes(USERROLES.ADMIN)
                      )||
                      (shippingActionType === INSPECT || shippingActionType === SUBMIT)
                    }
                    value={extra.is_mat_food_produced_animal}
                    name="is_mat_food_produced_animal"
                    onChange={(e) =>
                      actions.setExtraData({
                        ...extra,
                        ["is_mat_food_produced_animal"]: true,
                      })
                    }
                  />
                )}

                {"  "}
                <span>{t("approve_materials.yes")}</span>
                {"  "}
                {extra.is_mat_food_produced_animal === false ? (
                  <input
                    type="radio"
                    disabled={
                      !(
                        userType.includes(USERROLES.PROCESSMANAGER) ||
                        userType.includes(USERROLES.APPLICANT) ||
                        userType.includes(USERROLES.ADMIN)
                      )||
                      (shippingActionType === INSPECT || shippingActionType === SUBMIT)
                    }
                    value={extra.is_mat_food_produced_animal}
                    name="is_mat_food_produced_animal"
                    checked="checked"
                    onChange={(e) =>
                      actions.setExtraData({
                        ...extra,
                        ["is_mat_food_produced_animal"]: false,
                      })
                    }
                  />
                ) : (
                  <input
                    type="radio"
                    disabled={
                      !(
                        userType.includes(USERROLES.PROCESSMANAGER) ||
                        userType.includes(USERROLES.APPLICANT) ||
                        userType.includes(USERROLES.ADMIN)
                      )||
                      (shippingActionType === INSPECT || shippingActionType === SUBMIT)
                    }
                    value={extra.is_mat_food_produced_animal}
                    name="is_mat_food_produced_animal"
                    onChange={(e) =>
                      actions.setExtraData({
                        ...extra,
                        ["is_mat_food_produced_animal"]: false,
                      })
                    }
                  />
                )}
                {"  "}
                <span>{t("approve_materials.no")}</span>
              </div>
            </div>

            <If
              condition={
                shippingRequestDetails.length > 0 &&
                ([
                  STATUS.IN_PROGRESS,
                  STATUS.READY_FOR_DELIVERY,
                  STATUS.INBOX,
                  STATUS.SHIPER_REVIEW_DONE,
                  STATUS.READY_FOR_PICKUP,
                  STATUS.IN_DELIVERY,
                ].includes(shippingRequestDetails[0].status) ||
                  (shippingRequestDetails[0].status === STATUS.INPUT &&
                    location.pathname === "/shippingRequests/edit") || (shippingActionType === INSPECT))
              }
            >
              <Then>
                <br></br>
                <Card variant="outlined">
                  <CardHeader title={t("material.Package_Details")} />
                  <CardContent>
                    <div
                      className="row col-12 p5"
                      data-name="package_details_text"
                    >
                      <div className="col-lg-4">
                        {t("material.total_weight(lbs)")}{" "}
                        <TextField
                          disabled={
                            !(
                              userType.includes(USERROLES.PROCESSMANAGER) ||
                              userType.includes(USERROLES.APPLICANT) ||
                              userType.includes(USERROLES.ADMIN)
                            )||
                            (shippingActionType === INSPECT)
                          }
                          id="pack_tot_weight"
                          name="pack_tot_weight"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={extra?.pack_tot_weight || ""}
                          onChange={(e) =>
                            actions.setExtraData({
                              ...extra,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-lg-8">
                        {t("material.Package_dimensions")}
                        <div className="row">
                          <div className="col-lg-2">
                            <TextField
                              disabled={
                                !(
                                  userType.includes(USERROLES.PROCESSMANAGER) ||
                                  userType.includes(USERROLES.APPLICANT) ||
                                  userType.includes(USERROLES.ADMIN)
                                )||
                                (shippingActionType === INSPECT)
                              }
                              id="pack_width"
                              name="pack_width"
                              margin="normal"
                              variant="outlined"
                              size="small"
                              placeholder={t("material.width")}
                              fullWidth
                              value={extra?.pack_width || ""}
                              onChange={(e) =>
                                actions.setExtraData({
                                  ...extra,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-lg-3">
                            <TextField
                              disabled={
                                !(
                                  userType.includes(USERROLES.PROCESSMANAGER) ||
                                  userType.includes(USERROLES.APPLICANT) ||
                                  userType.includes(USERROLES.ADMIN)
                                )||
                                (shippingActionType === INSPECT)
                              }
                              id="pack_height"
                              name="pack_height"
                              margin="normal"
                              variant="outlined"
                              size="small"
                              placeholder={t("material.height")}
                              fullWidth
                              value={extra?.pack_height || ""}
                              onChange={(e) =>
                                actions.setExtraData({
                                  ...extra,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-lg-3">
                            <TextField
                              disabled={
                                !(
                                  userType.includes(USERROLES.PROCESSMANAGER) ||
                                  userType.includes(USERROLES.APPLICANT) ||
                                  userType.includes(USERROLES.ADMIN)
                                )||
                                (shippingActionType === INSPECT)
                              }
                              id="pack_length"
                              name="pack_length"
                              margin="normal"
                              variant="outlined"
                              size="small"
                              placeholder={t("material.length")}
                              fullWidth
                              value={extra?.pack_length || ""}
                              onChange={(e) =>
                                actions.setExtraData({
                                  ...extra,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Then>
            </If>

            <br></br>
            
            <If
              condition={
                shippingRequestDetails.length > 0 &&
                ([
                  STATUS.IN_PROGRESS,
                  STATUS.READY_FOR_DELIVERY,
                  STATUS.INBOX,
                  STATUS.SHIPER_REVIEW_DONE,
                  STATUS.READY_FOR_PICKUP,
                  STATUS.IN_DELIVERY,
                ].includes(shippingRequestDetails[0].status) ||
                  ((shippingRequestDetails[0].status === STATUS.INPUT &&
                    location.pathname === "/material/edit")) || (shippingActionType === INSPECT))
              }

            >
              <Then>
                <div className="row col-md-12 pl-0 mt10">
                  <div className="col-md-6">
                    <label>
                      {t("material.dangerous_goods")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-md-6">
                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "20px", display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          disabled={
                            shippingRequestDetails[0]?.status === STATUS.READY_FOR_DELIVERY ||
                            shippingRequestDetails[0]?.status === STATUS.IN_DELIVERY ||
                            (shippingActionType === INSPECT)
                          }
                          value={extra?.is_shippment_hazardous}
                          name="is_shipment_hazardous"
                          //checked="checked"
                         checked={extra?.is_shippment_hazardous === true}
                          onChange={() =>
                            actions.setExtraData({
                              ...extra,
                              ["is_shippment_hazardous"]: true,
                            })
                          }
                        />
                        <span>{t("approve_materials.yes")}</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          disabled={
                            shippingRequestDetails[0]?.status === STATUS.READY_FOR_DELIVERY ||
                            shippingRequestDetails[0]?.status === STATUS.IN_DELIVERY ||
                            (shippingActionType === INSPECT)
                          }
                          value={extra?.is_shippment_hazardous}
                          name="is_shipment_hazardous"
                        //  checked=""
                          checked={extra?.is_shippment_hazardous === false}
                          onChange={() =>
                            actions.setExtraData({
                              ...extra,
                              ["is_shippment_hazardous"]: false,
                            })
                          }
                        />
                        <span>{t("approve_materials.no")}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 p-0">
                    <div className="form-group">
                      <label>
                        <b>
                          {t("comments_alert.label_comment")}{" "}
                          {((mandatoryCheck.length>0) &&  get(extra, "is_shippment_hazardous") === false) ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : null}
                        </b>

                      </label>
                      <TextareaAutosize
                       disabled={
                        shippingRequestDetails[0]?.status === STATUS.READY_FOR_DELIVERY||
                        shippingRequestDetails[0]?.status === STATUS.IN_DELIVERY ||
                        (shippingActionType === INSPECT)
                      }
                        name="shippment_hazardous_comments"
                        aria-label="comments"
                        margin="normal"
                        variant="outlined"
                        size="large"
                        minRows={6}
                        style={{ width: "70%" }}
                        
                        value={extra?.shippment_hazardous_comments || ""}
                        onChange={(e) =>
                          actions.setExtraData({
                            ...extra,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </Then>
            </If>
          </div>
        </div>
      </Box>
    </LoadingOverlay>
  </div>
  
  );
};
export default withStyles(styles)(ShippingMaterialsComponent);
