import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import moment from "moment";

// import 'bootstrap/dist/css/bootstrap.css';
import axios from "../../api.js";
import * as config from "../../config";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import editIcon from "../../assests/images/editIcon.svg";
import disableEditIcon from "../../assests/images/disableEditIcon.svg";
import deleteIcon from "../../assests/images/deleteIcon.svg";
import greyDeleteIcon from "../../assests/images/grey_delete.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MaterialTable from "@material-table/core";
import { AddBox, ArrowDownward } from "@material-ui/icons";
import { forwardRef } from "react";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useLocation, useHistory } from "react-router-dom";
import { STATUS } from "../../constants";
import {
  dateSortFunction,
  sortFunction,
  SortFunctionNew,
  SortStatusFunctionNew,
} from "../../Services/util";
import AddressUtil from "../common/utils/AddressUtil.js";
import {
  tableRowFormatter,
  tableRowOrderManager,
} from "../common/utils/TableUtil.js";
import { DateWithDefaultTime, DateWithEndTime } from "../common/utils/DateUtil.js";


toast.configure();
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const ViewData = (props) => {
  const {
    list,
    handleShippingEdit,
    setIsLoading,
    searchFilter,
    handlerShippingSelectedRow,
    getFirstRowData,
    shippingSelectedRow,
    handleShippingDelete,
    myList = false,
    allMaterials,
    myshipping = false,
    dataSteward = false,
    tableOptions,
    actions,
    tableAdminShippingOptions
  } = props;

  const [valid, setvalid] = useState(null);
  const [showmodal, setshowmodal] = useState(false);
  const [flagForDelete, setflagForDelete] = useState("");
  const [selectedanimalLength1, setselectedanimalLength1] = useState([]);
  const [selectedanimalLength, setselectedanimalLength] = useState([]);
  const [selectedroomname, setroomname] = useState("");
  const [id, setid] = useState(null);
  const [showmodaldelete, setshowmodaldelete] = useState(false);
  const [showmodalforAdd, setshowmodalforAdd] = useState(false);
  const [speciesdropdown, setspeciesdropdown] = useState([]);
  const [filteredData, setfilteredData] = useState(list);
  const [filteredAdminData, setfilteredAdminData] = useState(list);
  const location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [formErrors, setFormErrors] = useState({
    studyCodeErrMsg: "",
  });
  const pathMyShippingURLs = [
    "/process/shipping",
    "/requestByDelegate",
    "/approveAddress",
    "/approve/materials",
    "/address",
    "/materials",
    "/recipients",
    "/shippingRequests",
    "/approve/recipients",
    "/regulatoryReviewer",
    "/comments",
    "/alerts",
    "/countrycatalouge",
    "/registereduser",
    "/queriesCountry",
    "/queriesCountryTwoWeeks",
    "/myAddress",
    "/my/application/materials",
    "/my/application/recipients",
    "/contact",
    "/phase",
    "/broker",
    "/container",
    "/add-country",
    "/currency",
    "/distributiontemperature",
    "/importerOfRecord",
    "/incoterm",
    "/shippingType",
    "/title",
    "/units",
    "/logistics",
  ];

  const pathAdminShippingURLs = [
    "/process/shipping",
    "/requestByDelegate",
    "/approveAddress",
    "/approve/materials",
    "/approve/recipients",
    "/address",
    "/materials",
    "/recipients",
    "/regulatoryReviewer",
    "/comments",
    "/alerts",
    "/myShippings",
    "/countrycatalouge",
    "/registereduser",
    "/queriesCountry",
    "/queriesCountryTwoWeeks",
    "/myAddress",
    "/my/application/materials",
    "/my/application/recipients",
    "/contact",
    "/phase",
    "/broker",
    "/container",
    "/add-country",
    "/currency",
    "/distributiontemperature",
    "/importerOfRecord",
    "/incoterm",
    "/shippingType",
    "/title",
    "/units",
    "/logistics",
  ];

  useEffect(() => {
    if (searchFilter) {
      let validSearchKeys = Object.keys(searchFilter)
        .filter((item) => item !== "searchKey")
        .filter((key) => !!searchFilter[key]);

      let filteredData = list.filter((item) => {
        for (let searchParam of validSearchKeys) {
          if (["submitted", "approved", "valid", "review_by_shipping"].includes(searchParam)) {
            if (searchParam === "review_by_shipping" && item[searchParam] !== null) {
              continue;
            }
            if (item[searchParam] !== 1) {
              return false;
            } else {
              continue;
            }
          }

          if (["startDate", "endDate"].includes(searchParam)) {
            if (searchParam === "startDate") {
              const createDate = moment(item.created_on).startOf("day");
              const startDate = moment(searchFilter[searchParam]).startOf("day");
              if (createDate.isBefore(DateWithDefaultTime(startDate))) {
                return false;
              }
              continue;
            } else {
              const createDate = moment(item.created_on).startOf("day");
              const endDate = moment(searchFilter[searchParam]).startOf("day");
              if (createDate.isAfter(DateWithEndTime(endDate))) {
                return false;
              }
              continue;
            }
          }

          if (
            ["shipmentDateLevStart", "shipmentDateLevEnd"].includes(searchParam)
          ) {
            if (searchParam === "shipmentDateLevStart") {
              if (!item.shipment_date_lev) {
                return false;
              }

              const createDate = moment(item.shipment_date_lev).startOf("day");
              const startDate = moment(searchFilter[searchParam]).startOf("day");
              if (createDate.isBefore(DateWithDefaultTime(startDate))) {
                return false;
              }
              continue;
            } else {
              const createDate = moment(item.shipment_date_lev).startOf("day");
              const endDate = moment(searchFilter[searchParam]).startOf("day");
              if (createDate.isAfter(DateWithEndTime(endDate))) {
                return false;
              }
              continue;
            }
          }

          if (
            ["shipmentDateMomStart", "shipmentDateMomEnd"].includes(searchParam)
          ) {
            if (myshipping) {
              if (!item.date_of_recipient) {
                return false;
              }
              const createDate = moment(item.date_of_recipient).startOf("day");
              const date = moment(searchFilter[searchParam]).startOf("day");
              if (searchParam === "shipmentDateMomStart") {
                if (createDate.isBefore(DateWithDefaultTime(date))) {
                  return false;
                }
                continue;
              } else {
                if (createDate.isAfter(date.add(1, "days"))) {
                  return false;
                }
                continue;
              }
            } else {
              if (!item.shipment_date_mon) {
                return false;
              }
              const createDate = moment(item.shipment_date_mon).startOf("day");
              const date = moment(searchFilter[searchParam]).startOf("day");
              if (searchParam === "shipmentDateMomStart") {
                if (createDate.isBefore(DateWithDefaultTime(date))) {
                  return false;
                }
                continue;
              } else {
                if (createDate.isAfter(date.add(1, "days"))) {
                  return false;
                }
                continue;
              }
            }
          }

          if (
            ["dateOfRecipientStart", "dateOfRecipientEnd"].includes(searchParam)
          ) {
            if (!item.date_of_recipient) {
              return false;
            }
            const createDate = moment(item.date_of_recipient).startOf("day");
            const date = moment(searchFilter[searchParam]).startOf("day");
            if (searchParam === "dateOfRecipientStart") {
              if (createDate.isBefore(DateWithDefaultTime(date))) {
                return false;
              }
              continue;
            } else {
              if (createDate.isAfter(date.add(1, "days"))) {
                return false;
              }
              continue;
            }
          }

          if (
            [
              "id",
              "status",
              "entity",
              "created_by",
              "tracking_number",
              "street",
              "postcode",
              "city",
              "address",
              "final_receipt",
              "sap_delivery_number",
              "description",
              "name_english",
              "internal_distribution_number",
              "country_of_shipper",
              "type_of_shipment",
            ].includes(searchParam)
          ) {
            // if (
            // !item[searchParam] ||
            // !(item[searchParam] + "")
            //   .toLowerCase()
            //   .includes(searchFilter[searchParam].toLowerCase())
            // ) {
            //   return false;
            // }

            if (
              searchParam == "internal_distribution_number" && searchFilter[searchParam].length > 0 && (
            !item[searchParam] ||
            !(item[searchParam] + "")
              .toLowerCase()
              .includes(searchFilter[searchParam].toLowerCase())
                
              )
            ) {
              return false;
            }
            if (
              searchParam == "status" && searchFilter[searchParam].length > 0 && !(
                searchFilter[searchParam].includes(item[searchParam])

              )
            ) {
              return false;
            }
            if (
              searchParam == "name_english" && searchFilter[searchParam].length > 0 && !(
                searchFilter[searchParam].includes(item[searchParam])

              )
            ) {
              return false;
            }
            if (
              searchParam == "final_receipt" && searchFilter[searchParam].length > 0 && !(
                searchFilter[searchParam].includes(item[searchParam])

              )
            ) {
              return false;
            }
            if (
              searchParam == "city" && !(
                searchFilter[searchParam].includes(item[searchParam])
              )
            ) {
              return false;
            }
          }
        }
        return true;
      });

      filteredData = filteredData.reduce((a, val) => {
        if (!a.find((item) => item.id == val.id)) {
          a.push({
            ...val,
            ...val.address_recipient_locked_data,
            ...(val.address_recipient_locked_data
              ? {
                final_receipt: `${val.address_recipient_locked_data.first_name} ${val.address_recipient_locked_data.last_name}`,
              }
              : null),
          });
        }
        return a;
      }, []);

      setfilteredData(filteredData);
    }
  }, [searchFilter, list]);

  useEffect(() => {
    if (searchFilter) {
      let validSearchKeys = Object.keys(searchFilter)
        .filter((item) => item !== "searchKey")
        .filter((key) => !!searchFilter[key]);

      let filteredData = list.filter((item) => {
        for (let searchParam of validSearchKeys) {
          if (["submitted", "approved", "valid", "review_by_shipping"].includes(searchParam)) {
            if (searchParam === "review_by_shipping" && item[searchParam] !== null) {
              continue;
            }
            if (item[searchParam] !== 1) {
              return false;
            } else {
              continue;
            }
          }

          if (["startDate", "endDate"].includes(searchParam)) {
            if (searchParam === "startDate") {
              const createDate = moment(item.created_on).startOf("day");
              const startDate = moment(searchFilter[searchParam]).startOf("day");
              if (createDate.isBefore(DateWithDefaultTime(startDate))) {
                return false;
              }
              continue;
            } else {
              const createDate = moment(item.created_on).startOf("day");
              const endDate = moment(searchFilter[searchParam]).startOf("day");
              if (createDate.isAfter(DateWithEndTime(endDate))) {
                return false;
              }
              continue;
            }
          }

          if (
            ["shipmentDateLevStart", "shipmentDateLevEnd"].includes(searchParam)
          ) {
            if (searchParam === "shipmentDateLevStart") {
              if (!item.shipment_date_lev) {
                return false;
              }

              const createDate = moment(item.shipment_date_lev).startOf("day");
              const startDate = moment(searchFilter[searchParam]).startOf("day");
              if (createDate.isBefore(DateWithDefaultTime(startDate))) {
                return false;
              }
              continue;
            } else {
              const createDate = moment(item.shipment_date_lev).startOf("day");
              const endDate = moment(searchFilter[searchParam]).startOf("day");
              if (createDate.isAfter(DateWithEndTime(endDate))) {
                return false;
              }
              continue;
            }
          }

          if (
            ["shipmentDateMomStart", "shipmentDateMomEnd"].includes(searchParam)
          ) {
            if (myshipping) {
              if (!item.date_of_recipient) {
                return false;
              }
              const createDate = moment(item.date_of_recipient).startOf("day");
              const date = moment(searchFilter[searchParam]).startOf("day");
              if (searchParam === "shipmentDateMomStart") {
                if (createDate.isBefore(DateWithDefaultTime(date))) {
                  return false;
                }
                continue;
              } else {
                if (createDate.isAfter(date.add(1, "days"))) {
                  return false;
                }
                continue;
              }
            } else {
              if (!item.shipment_date_mon) {
                return false;
              }
              const createDate = moment(item.shipment_date_mon).startOf("day");
              const date = moment(searchFilter[searchParam]).startOf("day");
              if (searchParam === "shipmentDateMomStart") {
                if (createDate.isBefore(DateWithDefaultTime(date))) {
                  return false;
                }
                continue;
              } else {
                if (createDate.isAfter(date.add(1, "days"))) {
                  return false;
                }
                continue;
              }
            }
          }

          if (
            ["dateOfRecipientStart", "dateOfRecipientEnd"].includes(searchParam)
          ) {
            if (!item.date_of_recipient) {
              return false;
            }
            const createDate = moment(item.date_of_recipient).startOf("day");
            const date = moment(searchFilter[searchParam]).startOf("day");
            if (searchParam === "dateOfRecipientStart") {
              if (createDate.isBefore(DateWithDefaultTime(date))) {
                return false;
              }
              continue;
            } else {
              if (createDate.isAfter(date.add(1, "days"))) {
                return false;
              }
              continue;
            }
          }

          if (
            [
              "id",
              "status",
              "entity",
              "created_by",
              "tracking_number",
              "street",
              "postcode",
              "city",
              "address",
              "final_receipt",
              "sap_delivery_number",
              "description",
              "name_english",
              "internal_distribution_number",
              "country_of_shipper",
              "type_of_shipment",
            ].includes(searchParam)
          ) {
            // if (
            //   !item[searchParam] ||
            //   !(item[searchParam] + "")
            //     .toLowerCase()
            //     .includes(searchFilter[searchParam].toLowerCase())
            // ) {
            //   return false;
            // }
          
            if (
              searchParam == "internal_distribution_number" && searchFilter[searchParam].length > 0 && (
            !item[searchParam] ||
            !(item[searchParam] + "")
              .toLowerCase()
              .includes(searchFilter[searchParam].toLowerCase())
                
              )
            ) {
              return false;
            }
            if (
              searchParam == "status" && searchFilter[searchParam].length > 0 && !(
                searchFilter[searchParam].includes(item[searchParam])
              )
            ) {
              return false;
            }
            if (
              searchParam == "name_english" && searchFilter[searchParam].length > 0 && !(
                searchFilter[searchParam].includes(item[searchParam])

              )
            ) {
              return false;
            }
            if (
              searchParam == "final_receipt" && searchFilter[searchParam].length > 0 && !(
                searchFilter[searchParam].includes(item[searchParam])

              )
            ) {
              return false;
            }
            if (
              searchParam == "country_of_shipper" && searchFilter[searchParam].length > 0 && (
                !searchFilter[searchParam].includes(item[searchParam])

              )
            ) {
              return false;
            }
            if (
              searchParam == "type_of_shipment" && searchFilter[searchParam].length > 0 && item[searchParam] !== "" && item[searchParam] !== null && searchFilter[searchParam] !== "" && searchFilter[searchParam] !== null && (
                !searchFilter[searchParam].map(word => word.toLowerCase()).includes(item[searchParam].toLowerCase())
              )
            ) {
              return false;
            }
            if (
              searchParam == "city" && !(
                searchFilter[searchParam].includes(item[searchParam])
              )
            ) {
              return false;
            }
            if (
              searchParam == "tracking_number" && !(
                searchFilter[searchParam]?.replace(/\s+/g,'') == (item[searchParam]?.replace(/\s+/g,''))
              )
            ) {
              return false;
            }
          }
        }
        return true;
      });

      filteredData = filteredData.reduce((a, val) => {
        if (!a.find((item) => item.id == val.id)) {
          a.push({
            ...val,
            ...val.address_recipient_locked_data,
            ...(val.address_recipient_locked_data
              ? {
                final_receipt: `${val.address_recipient_locked_data.first_name} ${val.address_recipient_locked_data.last_name}`,
              }
              : null),
          });
        }
        return a;
      }, []);

      setfilteredAdminData(filteredData);
    }
  }, [searchFilter, list]);

  useEffect(() => {
    actions.setAdminShippingTableOptions(JSON.parse(localStorage.getItem("shippingAdminSortAndPagination")) || {});
    return () => {
      if (pathAdminShippingURLs.includes(window.location.pathname)) {
        actions.clearshippingRequest();
        localStorage.removeItem("shippingAdminSortAndPagination");
        actions.setAdminShippingTableOptions({});
        actions.setShippingSearchData({});
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (pathMyShippingURLs.includes(window.location.pathname)) {
        actions.clearshippingRequest();
        actions.setTableOptions({});
        actions.setShippingSearchData({});
      }
    };
  }, [tableOptions]);



  const dispatchMaterialOptions = (event, value) => {
    if (event.target === "page") {
      return actions.setTableOptions({
        pageNumber: value.pageNumber,
        pageSize: value.pageSize,
        orderBy: tableOptions.orderBy
      });
    }
    if (event.target === "sort") {
      return actions.setTableOptions({
        pageNumber: 0,
        pageSize: tableOptions.pageSize,
        orderBy: value
      });
    }
  }
  const dispatchShippingOptions = (event, value) => {
    if (event.target === "page") {
      return actions.setAdminShippingTableOptions({
        pageNumber: value.pageNumber,
        pageSize: value.pageSize,
        orderBy: tableAdminShippingOptions.orderBy
      });
    }
    if (event.target === "sort") {
      return actions.setAdminShippingTableOptions({
        pageNumber: 0,
        pageSize: tableAdminShippingOptions.pageSize,
        orderBy: value
      });
    }
  }
  const handleDegreeChange = (selectedanimalLength1) => {
    setselectedanimalLength1(selectedanimalLength1);
  };

  const [refresh, setRefresh] = useState(true);
  const [tableFlag, setTableFlag] = useState(true);

  const defaultSelectedRow = (row) => {
    if (row.tableData.id === 0 && shippingSelectedRow.tableData.id === 0) {
      getFirstRowData(row);
      return;
    }
  };
  const dateLocalization = (date) => {
    if (!date) {
      return date;
    }
    let newDate = date;
    if (new Date(date).getTimezoneOffset() < 0) {
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
    return moment(newDate).utc().format("DD-MMM-YYYY");

  };
  const getTableData = () => {
    return (
      <div style={{ paddingBottom: "2%" }}>

        <MaterialTable
          onPageChange={(page, itemsPerPage) => {
            // sessionStorage.setItem("selectedMaterialAdminPage", page);
            dispatchShippingOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
          }}
          onOrderCollectionChange={(orderBy) => {
            dispatchShippingOptions({ target: "sort" }, orderBy);
          }}
          columns={[
            {
              width: "10%",
              sorting: false,
              render: (rowData) => {
                defaultSelectedRow(rowData);
                return (
                  <input
                    type="radio"
                    checked={
                      rowData.tableData.id === shippingSelectedRow.tableData.id
                        ? true
                        : false
                    }
                    value={shippingSelectedRow}
                    name="radiovalues"
                    onClick={(e) => handlerShippingSelectedRow(rowData)}
                  />
                );
              },
            },
            {
              title: <span>{t("shipping_request.internal_order_id")}</span>,
              field: `internal_distribution_number`,
              customSort: SortFunctionNew("internal_distribution_number"),
            },
            {
              title: <span>{t("shipping_request.status")}</span>,
              render: (rowData) => t("status." + rowData.status),
              customSort: SortStatusFunctionNew("status"),
            },
            {
              title: <span>{t("search_fields.created_by")}</span>,
              field: "created_by",
            },
            {
              title: <span>{t("shipping_request.address")}</span>,
              render: (rowData) => <>{new AddressUtil(rowData).toString()}</>,
              customSort: (a, b) =>
                new AddressUtil(a).compare(new AddressUtil(b)),
            },
            {
              title: <span>{t("shipping_request.final_recipient")}</span>,
              field: "final_receipt",
              customSort: SortFunctionNew("final_receipt"),
            },
            {
              title: <span>{t("shipping_request.create_date")}</span>,
              customSort: (a, b) => {
                let aVal = new Date(a.created_on).getTime();
                let bVal = new Date(b.created_on).getTime();
                return bVal - aVal;
              },
              render: (rowData) =>
                rowData.created_on ? dateLocalization(rowData.created_on) : "",
            },
            {
              title: <span>{t("shipping_request.dispatch_date_mon")}</span>,
              customSort: (a, b) => {
                let aVal = new Date(a.shipment_date_mon).getTime();
                let bVal = new Date(b.shipment_date_mon).getTime();
                return bVal - aVal;
              },
              render: (rowData) =>
                rowData.shipment_date_mon
                  ? dateLocalization(rowData.shipment_date_mon)
                  : "",
            },
            {
              title: <span>{t("shipping_request.dispatch_date_lev")}</span>,
              customSort: (a, b) => {
                let aVal = new Date(a.shipment_date_lev).getTime();
                let bVal = new Date(b.shipment_date_lev).getTime();
                return bVal - aVal;
              },
              render: (rowData) =>
                rowData.shipment_date_lev &&
                dateLocalization(rowData.shipment_date_lev),
            },
            {
              title: <span>{t("shipping_request.date_of_receipt")}</span>,
              customSort: (a, b) => {
                let aVal = new Date(a.close_date).getTime();
                let bVal = new Date(b.close_date).getTime();
                return bVal - aVal;
              },
              render: (rowData) =>
                rowData.close_date && dateLocalization(rowData.close_date),
            },
            {
              title: <span> {t("shipping_request.assigned_shipping_review")}</span>,
              field: "review_by_shipping",
              customSort: SortFunctionNew("review_by_shipping"),
            },
            // {
            //   title: <span>{"Sap Number"}</span>,
            //   field: "sap_delivery_number",
            // },
            // {
            //   title: <span>{"Id"}</span>,
            //   field: "id",
            // },
            {
              title: <span>{"Valid"}</span>,
              field: "valid",
              render: (rowData) =>
                rowData.valid === 1 ? (
                  <span>
                    <div>
                      <input type="checkbox" disabled defaultChecked={true} />
                    </div>
                  </span>
                ) : (
                  <div>
                    {" "}
                    <span>
                      <div>
                        <input
                          type="checkbox"
                          disabled
                          defaultChecked={false}
                        />
                      </div>
                    </span>
                  </div>
                ),
            },
            {
              title: <span></span>,
              sorting: false,
              hidden: dataSteward,
              render: (rowData) => {
                if (
                  rowData.status === "IN_PROGRESS" ||
                  rowData.status === "CANCELLED" ||
                  rowData.status === "CLOSE" ||
                  rowData.status === "INBOX" ||
                  rowData.status === "REGULATORY_REVIEW" ||
                  rowData.status === "READY_FOR_PICKUP" ||
                  rowData.status === "IN_DELIVERY" ||
                  rowData.status === "READY_FOR_DELIVERY" ||
                  rowData.status === "Regulatory Reviewer 1" ||
                  rowData.status === "Regulatory Reviewer 2" ||
                  rowData.status === STATUS.SHIPER_REVIEW_DONE
                ) {
                  return (
                    <span>
                      <a
                        href="javascript:void(0)"
                        style={{
                          color: "red",
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                        id="openmodalForEdit"
                      >
                        <img alt="t" className="" src={disableEditIcon}></img>{" "}
                      </a>
                    </span>
                  );
                } else {
                  return (
                    <span>
                      <a
                        href="#"
                        style={{
                          color: "red",
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                        id="openmodalForEdit"
                        onClick={(e) => {
                          handleShippingEdit(rowData);
                        }}
                      >
                        <img alt="t" className="" src={editIcon}></img>{" "}
                      </a>
                    </span>
                  );
                }
              },
            },
            {
              title: <span></span>,
              sorting: false,
              hidden: dataSteward,
              render: (rowData) => {
                if (rowData.status === "INPUT") {
                  return (
                    <span>
                      <a
                        href="#"
                        style={{
                          color: "red",
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                        id="openmodalForDelete"
                        onClick={(e) => {
                          // openmodal(rowData, rowData.animal_Rates, rowData.roomname, rowData.valid, rowData.room_id);
                          handleShippingDelete(rowData);
                        }}
                      >
                        <img alt="t" className="" src={deleteIcon}></img>{" "}
                      </a>
                    </span>
                  );
                } else {
                  return (
                    <span>
                      <a
                        href="javascript:void(0)"
                        style={{
                          color: "#000",
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                        id="openmodalForDelete"
                      >
                        <img alt="t" className="" src={greyDeleteIcon}></img>{" "}
                      </a>
                    </span>
                  );
                }
              },
            },
          ]}
          data={(filteredAdminData)}
          title="Render Image Preview"
          icons={tableIcons}
          localization={{
            pagination: {
              labelRowsSelect: t("commonlabels.labelRowsSelect"),
              firstAriaLabel: t("commonlabels.firstAriaLabel"),
              firstTooltip: t("commonlabels.firstTooltip"),
              previousAriaLabel: t("commonlabels.previousAriaLabel"),
              previousTooltip: t("commonlabels.previousTooltip"),
              nextAriaLabel: t("commonlabels.nextAriaLabel"),
              nextTooltip: t("commonlabels.nextTooltip"),
              lastAriaLabel: t("commonlabels.lastAriaLabel"),
              lastTooltip: t("commonlabels.lastTooltip"),
            },
            body: {
              emptyDataSourceMessage: t("commonlabels.emptyDataSourceMessage"),
            },
          }}
          options={{
            thirdSortClick: false,
            draggable: false,
            headerStyle: {
              backgroundColor: "#cce3f5",
              fontWeight: "bold",
              height: "10px",
            },
            rowStyle: tableRowFormatter({
              height: "30px",
              padding: "0px",
            }),
            initialPage: parseInt(tableAdminShippingOptions?.pageNumber ? tableAdminShippingOptions?.pageNumber : 0),
            pageSize: parseInt(tableAdminShippingOptions?.pageSize ? tableAdminShippingOptions?.pageSize : 10),
            defaultOrderByCollection: tableAdminShippingOptions?.orderBy ? tableAdminShippingOptions?.orderBy : "",

            sortIcon: true,
            maxBodyHeight: "90%",
            tableLayout: "auto",
            sorting: true,
            search: false,
            selection: false,
            showTitle: false,
            toolbar: false,
            paging: true,
            paginationType: "stepped",

            pageSizeOptions: [10, 20, 30, 40, 50],
            emptyRowsWhenPaging: false,
            showSelectAllCheckbox: false,
          }}
        />

      </div>
    );

    //  }
  };

  const getMyShippingTableData = () => {
    return (
      <div style={{ paddingBottom: "2%" }}>
        <MaterialTable
          onPageChange={(page, itemsPerPage) => {
            // sessionStorage.setItem("selectedMaterialAdminPage", page);
            dispatchMaterialOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
          }}
          onOrderCollectionChange={(orderBy) => {
            dispatchMaterialOptions({ target: "sort" }, orderBy);
          }}
          columns={[
            {
              width: "10%",
              sorting: false,
              render: (rowData) => {
                defaultSelectedRow(rowData);
                return (
                  <input
                    type="radio"
                    checked={
                      rowData?.id === shippingSelectedRow?.id ? true : false
                    }
                    value={shippingSelectedRow}
                    name="radiovalues"
                    onClick={(e) => {
                      handlerShippingSelectedRow(rowData);
                    }}
                  />
                );
              },
            },
            {
              title: <span>{t("shipping_request.create_date")}</span>,
              customSort: (a, b) => {
                let aVal = new Date(a.created_on).getTime();
                let bVal = new Date(b.created_on).getTime();
                return bVal - aVal;
              },
              render: (rowData) =>
                rowData.created_on && dateLocalization(rowData.created_on),
            },
            {
              title: <span> {t("shipping_request.internal_order_id")}</span>,
              field: "internal_distribution_number",
              customSort: SortFunctionNew("internal_distribution_number"),
            },
            // {
            //     title: <span> {t("shipping_request.material")}</span>,
            //     field: 'description'
            // },
            {
              title: <span> {t("shipping_request.final_recipient")}</span>,
              field: "final_receipt",
              customSort: SortFunctionNew("final_receipt"),
            },
            // {
            //   title: <span> {t("Material")}</span>,
            //   render: (rowData) => rowData?.description?.join(", "),
            //   customSort: (a, b) => {
            //     const str1 = a?.description?.join(", ") || "";
            //     const str2 = b?.description?.join(", ") || "";
            //     return str2.localeCompare(str1);
            //   },
            // },
            {
              title: <span> {t("shipping_request.address")} </span>,
              render: (rowData) => <>{new AddressUtil(rowData).toString()}</>,
              customSort: (a, b) =>
                new AddressUtil(a).compare(new AddressUtil(b)),
            },
            {
              title: <span> {t("shipping_request.date_of_receipt")}</span>,
              customSort: dateSortFunction("date_of_recipient"),
              render: (rowData) =>
                rowData.date_of_recipient &&
                dateLocalization(rowData.date_of_recipient),
            },
            {
              title: <span> {t("shipping_request.status")}</span>,
              customSort: SortStatusFunctionNew("status"),
              render: (rowData) => t("status." + rowData.status),
            },
            {
              title: <span>{t("shipping_log_fields.editDate")}</span>,
              customSort: dateSortFunction("updated_on"),
              render: (rowData) =>
                rowData.updated_on && dateLocalization(rowData.updated_on),
            },
            {
              title: <span> {t("shipping_request.assigned_shipping_review")}</span>,
              field: "review_by_shipping",
              customSort: SortFunctionNew("review_by_shipping"),
            },
            {
              title: <span></span>,
              sorting: false,
              render: (rowData) => {
                if (
                  rowData.status === "IN_PROGRESS" ||
                  rowData.status === "AWAITING_FOR_APPROVAL" ||
                  rowData.status === "PROCESSING" ||
                  rowData.status === "CANCELLED" ||
                  rowData.status === "CLOSE" ||
                  rowData.status === "NEW" ||
                  rowData.status === "INBOX" ||
                  rowData.status === "REGULATORY_REVIEW" ||
                  rowData.status === "READY_FOR_PICKUP" ||
                  rowData.status === "IN_DELIVERY" ||
                  rowData.status === "READY_FOR_DELIVERY" ||
                  rowData.status === "Regulatory Reviewer 1" ||
                  rowData.status === "Regulatory Reviewer 2" ||
                  rowData.status === STATUS.SHIPER_REVIEW_DONE
                ) {
                  return (
                    <span>
                      <a
                        href="javascript:void(0)"
                        style={{
                          color: "red",
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                        id="openmodalForEdit"
                      >
                        <img alt="t" className="" src={disableEditIcon}></img>{" "}
                      </a>
                    </span>
                  );
                } else {
                  return (
                    <span>
                      <a
                        href="#"
                        style={{
                          color: "red",
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                        id="openmodalForEdit"
                        onClick={(e) => {
                          handleShippingEdit(rowData);
                        }}
                      >
                        <img alt="t" className="" src={editIcon}></img>{" "}
                      </a>
                    </span>
                  );
                }
              },
            },
            {
              title: <span></span>,
              sorting: false,
              render: (rowData) => {
                if (rowData.status === "INPUT") {
                  return (
                    <span>
                      <a
                        href="#"
                        style={{
                          color: "red",
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                        id="openmodalForDelete"
                        onClick={(e) => {
                          // openmodal(rowData, rowData.animal_Rates, rowData.roomname, rowData.valid, rowData.room_id);
                          handleShippingDelete(rowData);
                        }}
                      >
                        <img alt="t" className="" src={deleteIcon}></img>{" "}
                      </a>
                    </span>
                  );
                } else {
                  return (
                    <span>
                      <a
                        href="javascript:void(0)"
                        style={{
                          color: "#000",
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                        id="openmodalForDelete"
                      >
                        <img alt="t" className="" src={greyDeleteIcon}></img>{" "}
                      </a>
                    </span>
                  );
                }
              },
            },
          ]}
          data={filteredData}
          title="Render Image Preview"
          icons={tableIcons}
          localization={{
            pagination: {
              labelRowsSelect: t("commonlabels.labelRowsSelect"),
              firstAriaLabel: t("commonlabels.firstAriaLabel"),
              firstTooltip: t("commonlabels.firstTooltip"),
              previousAriaLabel: t("commonlabels.previousAriaLabel"),
              previousTooltip: t("commonlabels.previousTooltip"),
              nextAriaLabel: t("commonlabels.nextAriaLabel"),
              nextTooltip: t("commonlabels.nextTooltip"),
              lastAriaLabel: t("commonlabels.lastAriaLabel"),
              lastTooltip: t("commonlabels.lastTooltip"),
            },
            body: {
              emptyDataSourceMessage: t("commonlabels.emptyDataSourceMessage"),
            },
          }}
          options={{
            thirdSortClick: false,
            draggable: false,
            headerStyle: {
              backgroundColor: "#cce3f5",
              fontWeight: "bold",
              height: "10px",
            },
            rowStyle: tableRowFormatter({
              height: "30px",
              padding: "0px",
            }),
            initialPage: parseInt(tableOptions?.pageNumber ? tableOptions?.pageNumber : 0),
            sortIcon: true,
            maxBodyHeight: "90%",
            tableLayout: "auto",
            sorting: true,
            search: false,
            selection: false,
            showTitle: false,
            toolbar: false,
            paging: true,
            paginationType: "stepped",
            pageSize: parseInt(tableOptions?.pageSize ? tableOptions?.pageSize : 10),
            defaultOrderByCollection: tableOptions?.orderBy ? tableOptions?.orderBy : "",
            pageSizeOptions: [10, 20, 30, 40, 50],
            emptyRowsWhenPaging: false,
            showSelectAllCheckbox: false,
          }}
        />
      </div>
    );
  };

  const validate = () => {
    let isValid = true;
    if (selectedroomname === undefined || selectedroomname === "") {
      document.getElementById("RoomName").classList.add("error-border");
      isValid = false;
    }
    return isValid;
  };
  // validation when no customer is selected
  const validateSelect = () => {
    let isValid = true;
    document
      .querySelectorAll(".error-border")
      .forEach((obj) => obj.classList.remove("error-border"));
    if (selectedanimalLength1.length === 0) {
      document.getElementById("Species").classList.add("error-border");
      // toast.error('Please fill the species');
      isValid = false;
    }
    return isValid;
  };
  const saveMethod = () => {
    document
      .querySelectorAll(".error-border")
      .forEach((obj) => obj.classList.remove("error-border"));
    if (!validate()) {
      toast.error(t("validationmsg"));
      return false;
    }
    if (!validateSelect()) {
      toast.error("Please fill the species");
      return false;
    }
    const indx = list.findIndex((d) => d.roomname === selectedroomname);
    if (indx != -1) {
      toast.error("This room name already exists ");
    }
    // e.preventDefault();
    // // const editorvalue = this.state.editorValue;
    // //const body = this.state;
    // let isValid = formValid(); //this.studyFormValidation();
    // if (isValid) {
    let req = {};
    req.roomname = selectedroomname;
    req.animal = selectedanimalLength1;
    setTableFlag(false);
    //call the post api
    //call the get api for table data and settableflag as true
    //  setroomname("");
    //  setselectedanimalLength1("");
    // }
  };
  const openmodal = (rowdata, animal_dropdown, roomname, validFlag, id) => {
    if (showmodal === false) setshowmodal(true);

    let selectedcustomerOption = {
      label: rowdata.animal_Rates,
      value: rowdata.animal_Rates,
    };
    setselectedanimalLength1(selectedcustomerOption);
    // setselectedanimalLength1(animal_dropdown);
    setroomname(roomname);
    setvalid(validFlag);
    setid(id);
  };
  const closemodal = () => {
    setshowmodal(false);
    setroomname("");
    setselectedanimalLength1([]);
    setid(null);
  };
  // const openmodalForDelete = (rowdata, flag, id) => {
  //     if (showmodaldelete === false)
  //         setshowmodaldelete(true);
  //     setflagForDelete(flag);
  //     setid(id);
  // };
  const closemodalForDelete = () => {
    setshowmodaldelete(false);
    setroomname("");
    setselectedanimalLength1([]);
    setid(null);
  };
  const openmodalForNew = () => {
    setshowmodalforAdd(true);

    let req = {};
    req.roomname = selectedroomname;
    req.animal_dropdown = selectedanimalLength1;
  };
  const closemodalForNew = () => {
    setshowmodalforAdd(false);
    setroomname("");
    setselectedanimalLength1([]);
    setid(null);
  };
  const UpdateMethod = () => {
    let req = {};
    req.roomname = selectedroomname;
    req.animal = selectedanimalLength1;
    req.id = id;
    setTableFlag(false);
    setshowmodal(false);
    setroomname("");
    setselectedanimalLength1("");
    setid(null);
  };
  const DeleteMethod = () => {
    let req = {};
    //  req.active_Flag = flagForDelete === "0" ? "1" : "0";
    req.id = id;
    setshowmodaldelete(false);
    axios
      .post(config.baseURL + "deteleRoom", req)
      .then((response) => {
        // handle success

        toast.success("Registration is Successful");
        //  apicalls();
      })
      .catch((error) => {
        // handle error

        toast.error("Registration Failed");
        // this.setState({ isLoading: false });
      });
  };
  const handleCheckboxChange = (event, datarow) => {
    //  this.setState({ checked: event.target.checked })

    let value = event.target.checked;

    if (event.target.checked) {
      value = true;
      setvalid(value);
    } else {
      value = false;
      setvalid(value);
    }
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let fullDate = `${month}/${year}`;
    datarow.roomname = datarow.roomname + " " + "until" + " " + fullDate;
    // setroomname(concatenate);
    //    const indx = filteredData.findIndex((d) => d.roomname === datarow.roomname);
    //    if (indx != -1) {
    //       for (let index = 0; index < array.length; index++) {
    //           const element = array[index];

    //       }
    //    }
    setvalid(event.target.value);
  };
  // const handlenumberofanimalslengthChange = (selectedanimalLength) => {
  //     // this.setState({ selectedanimalLength1: selectedanimalLength.label })
  //     setselectedanimalLength1(selectedanimalLength.label);
  // };
  const handleChangeValue = (event) => {
    if (event.target.name === "RoomName") {
      setroomname(event.target.value);
    } else if (event.target.name === "Species") {
      setspeciesdropdown(event.target.value);
    }
    //    setValueandValidations(event.target.name, event.target.value);
  };
  return (
    <div>
      <Card variant="outlined">
        <CardHeader
          className="searchresult_header"
          title={t("phase.searchresults")}
        />
        <Divider />
        {location.pathname === "/shippingRequests"
          ? getTableData()
          : getMyShippingTableData()}
        <CardContent></CardContent>
      </Card>

      {/* Model for Edit  */}
      <Modal
        dialogClassName="modal-90w"
        show={showmodal}
        centered
        onHide={() => setshowmodal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title"> {t("Edit")} </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-4 m0">
            <div className="col-12">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="" className="col-form-label">
                      {t("NameoftheroomLabel")} <span className="req">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form-control"
                      id="RoomName"
                      name="RoomName"
                      value={selectedroomname}
                      onChange={(e) => handleChangeValue(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="" className="col-form-label">
                      {t("PermittedanimalspeciesLabel")}
                      <span className="req">*</span>
                    </label>
                    <Select
                      name="Species"
                      data-test="Species"
                      id="Species"
                      value={selectedanimalLength1}
                      onChange={handleDegreeChange}
                      options={speciesdropdown}
                      isMulti={true}
                    />
                  </div>
                  {/* <div className="form-group">
                                        <label htmlFor="" className="col-form-label">
                                            {t('ValidLabel')}
                                        </label>
                                        <input type="checkbox" id="checkBox" defaultChecked={valid === 0 ? false : true} onChange={e => handleCheckboxChange(e, "1")} />

                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            id="updateMethodButton"
            className="btn btn-primary btn-inner-close"
            onClick={(e) => UpdateMethod(e)}
          >
            {t("Edit")}
          </button>
          <button
            type="button"
            id="closeUpdateMethodButton"
            className="btn btn-black btn-inner-close"
            onClick={(e) => closemodal(e)}
          >
            {t("closeButton")}
          </button>
        </Modal.Footer>
      </Modal>
      {/* Model for Delete  */}
      {/* <Modal
                dialogClassName="my-modal"
                centered
                show={showmodaldelete}
            >
                <Modal.Header closeButton centered="true" className="bg-theme-blue">
                    <h5 className="modal-title">{t('Delete')} </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-4 m0">
                        <div className="col-12">
                            <div className="row align-items-center">
                                <label id="deleteText"> {t('DeleteConfirmationText')}</label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button
                        id="deleteMethod"
                        type="button"
                        className="btn btn-primary btn-inner-close"
                        onClick={(e) => DeleteMethod(e)}
                    >
                        {t('yesButton')}
                    </button>
                    <button
                        id="closeDeleteMethod"
                        type="button"
                        className="btn btn-black btn-inner-close"
                        onClick={(e) => closemodalForDelete(e)}
                    >
                        {t('closeButton')}
                    </button>
                </Modal.Footer>
            </Modal> */}

      {/* Model for New  */}
      <Modal dialogClassName="my-modal" centered show={showmodalforAdd}>
        <Modal.Header closeButton centered="true" className="bg-theme-blue">
          <h5 className="modal-title"> {t("newButton")} </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-4 m0">
            <div className="col-12">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="" className="col-form-label">
                      {t("NameoftheroomLabel")}
                      <span className="req">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form-control"
                      id="RoomName"
                      name="RoomName"
                      value={selectedroomname}
                      onChange={(e) => handleChangeValue(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="" className="col-form-label">
                      {t("PermittedanimalspeciesLabel")}
                      <span className="req">*</span>
                    </label>
                    <Select
                      name="Species"
                      data-test="Species"
                      id="Species"
                      value={selectedanimalLength1}
                      onChange={handleDegreeChange}
                      options={speciesdropdown}
                      isMulti={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            id="savemethod"
            className="btn btn-primary btn-inner-close"
            onClick={(e) => saveMethod(e)}
          >
            {t("saveButton")}
          </button>
          <button
            id="closeButtonForSave"
            type="button"
            className="btn btn-black btn-inner-close"
            onClick={(e) => closemodalForNew(e)}
          >
            {t("closeButton")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewData;
