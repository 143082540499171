/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import moment, { now } from "moment";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import _, { get } from "lodash";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { DateFormat, FormatDate } from "../common/utils/DateUtil";
import ApplicantComponent from "./ApplicantComponent";
import DeputiesComponent from "./DeputiesComponent";
import GeneralInformation from "./GeneralInformationCompoent";
import DestinationComponent from "./DestinationAddress";
import FinalRecipient from "./FinalRecipient";
import ErrorMessage from "../common/ErrorMessage";
import ConsignorComponent from "./consignorComponentContainer";
import { shippingRequestEdit } from "./shippingRequest";
import StatusInformation from "./StatusInformation";
import TabMenu from "../common/TabMenu";
import holidays from "date-holidays";
import CustomizedSnackbar from "../common/CustomizedSnackbar";
import {
  STATUS,
  EDIT,
  NEW,
  SUBMIT,
  HAND_BACK,
  CANCEL_REQUEST,
  CLOSE,
  INSPECT,
  NAV_KEYS,
  TYPE_OF_SHIPMENT,
} from "../../constants";
import ShippingRequestSubmit from "./ShippingRequstSubmit";
import ShippingMaterailCard from "./preview/ShippingMaterailCard";
import { pathURLs } from "../common/utils";
import AdditionalData from "./AdditionalData";
import { toast } from "react-toastify";
import { authContext } from "../../adalConfig";
import { If, Then } from "react-if";
import AttachDocuments from "./AttachDocuments";
import ImporterOfRecord from "./ImporterOfRecord";
import { USERROLES } from "../../constants";
import ShipmentReviewerSection from "./ShipmentReviewerSection";
import RegulatoryInfo from "./regulatoryReviewer";
import OverAllComments from "./OverAllComments";
import {
  disableInputFieldsBySelector,
  enableInputFieldsBySelector,
} from "../common/utils/DomUtil";
import { Alert } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import shippingService from "../../Services/shippingService";
import { useDispatch } from "react-redux";
import errorActions from "../../redux/errors/actions";
import { ifRequestCompletedApprovals } from "../../Services/util";
import { Months, WeekDay, EUCountries } from "../../constants";
import Holidays from "date-holidays";
import Model from "../shared/model";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useRef } from 'react';
import { appInsights } from "../../insightIogging";
import { FILE_SIZE_LIMIT } from "../../constants";

const ShippingRequest = (props) => {
  const [isError, setError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [loader, setLoader] = useState(true);
  const [stackOpen, setStackOpen] = useState(false);
  const [respError, setRespError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [checkIfRequestIsValid, setCheckIfRequestIsValid] = useState(false);
  const { instance } = useMsal();
  const [materialAvailStatus, setMaterialAvailStatus] = useState({

    errorCheck: false,
    message: "",
  });
  const [materialNeedStatus, setMaterialNeedStatus] = useState({
    errorCheck: false,
    message: "",
  });
  const [USHolidays,setUSHolidays] = useState([])
  const [EUHolidays,setEuHolidays] = useState([])
  const [validMatNeedDate,SetValidMatNeedDate] = useState()
  const [handleEntry,setHandleEntry] = useState()
  const [excludeDates,setExcludeDates] = useState([])
  const [reviewBy, setReviewBy] = useState('');
  const [checkboxState, setCheckBoxState] = useState(false);
  const userId = instance.getActiveAccount().username;
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const updateErrorTab = errorActions.updateErrorTab;
  let shippingRequestData;
  const { shippingRequestIdCurrent } = useParams();
  const urlParams = new URLSearchParams(props?.location?.search);

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [comments, setComments] = useState("");
  const saveCommentsReqRef = useRef(null);
 
  const {
    actions,
    distributionTemperature,
    destinationAddrSelect,
    finalRecipients,
    finalRecipientSelect,
    shippingDeputySelect,
    internalOrderIdValue,
    shipmentSpecificValue,
    shippingCreateDateValue,
    distributionTempValue,
    shippingCreateCommentValue,
    shippingMaterialSelectedValue,
    shippingMaterialSelect,
    getShippingResponse = [],
    shippingRequestDetails,
    deputyShippingDetails,
    materialShippingDetails,
    shippingActionType,
    sapDeliveryNumValue,
    dispatchMONValue,
    dispatchLEVValue,
    dateOfReceiptValue,
    trackingNumValue,
    statusQuoValue,
    redirectTo,
    attachements,
    shipper,
    extra,
    brokers,
    allRecipients,
    allAddressList,
    shippingType,
    incoterms,
    impoter,
    regulatoryReviewer1List,
    mailLinkData,
    phases,
    shipperCountry,
    setValidMaterialNeedDate,
    newShippingRequestCommentsList,
    shippingSearchData,
    tableOptions,
    tableAdminShippingOptions,
    logisticPartnerDictionaryData,
    shippingUnderReviewRequestDetails,
    allContainerList,
    clientTimeZone
  } = props;
  appInsights.trackTrace({
    message:" shipping request component-requesting for new shipment",
    severityLevel:1
});
  const userType = JSON.parse(localStorage.getItem("userType"));
  const handleTimeZoneOffset = (date) =>{
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
     const utcTime = moment(newDate).utc().format('YYYY-MM-DDTHH:mm:ss');
    return new Date(utcTime)
  }
  useEffect(() => {
    actions.getAllCurrency();
    actions.getAllUnits();
    actions.getShippingType();
    actions.getCountries().then(() => setLoader(false));
    actions.getDistributionTemperature().then(() => setLoader(false));
    actions.getAllBrokers();
    actions.getPhases();
    actions.getAllContainerList();
    actions.getAllrecipients();
    actions.getAllAddressList();
    actions.getMyFinalRecipients(userId);
    actions.getIncoterm();
    actions.getImpoter();
    actions.getRegulatoryReviewer1List();
    actions.getLogisticsParterDictionaryData();
    actions.shippingUnderReviewRequestDetails();
    if (
      destinationAddrSelect &&
      Object.keys(destinationAddrSelect).length !== 0
    ) {
      if (destinationAddrSelect.country_id && destinationAddrSelect.id) {
        actions.getFinalRecipients(destinationAddrSelect.id);
      }
    }
    sessionStorage.setItem("newAddedMaterial", "");
  }, []);

  useEffect(() => {
    return () => {
      if (pathURLs.includes(window.location.pathname)) {
        actions.clearshippingRequest();
        actions.setShippingSearchData(shippingSearchData)
        actions.setTableOptions(tableOptions)
        actions.setAdminShippingTableOptions(tableAdminShippingOptions)
      }
    };
  }, []);

  useEffect(() => {
    if (
      !shippingRequestIdCurrent &&
      !Number.isSafeInteger(Number(shippingRequestIdCurrent))
    ) {
      return;
    }
  
    const row = { id: shippingRequestIdCurrent };
    actions.getShippingRequestDetails(row);
    actions.getDeputyShippingDetails(row);
    actions.getMaterialShippingDetails(row);
    // actions.getShippingActionType("EDIT");
    actions.clearShippingMaterialSelectedValue();
    actions.addShippingMaterialSelect([]);
    actions.getShippingAttachment(row);
    actions.getShipperDetails(row);
    actions.getAllShippingData();
    // actions.saveTableData(row);
    actions.setMailLinkData({
      isAccessedFromMAil: true,
    });

    const mode = urlParams.get("viewMode");
    if (mode === INSPECT) {
      actions.getShippingActionType(INSPECT);
    }
  }, [shippingRequestIdCurrent]);

  useEffect(async () => {
    const id = _.get(shippingRequestDetails, "[0].id", null);
    if (id) {
      const data = await shippingService.checkIfShippmentIsValid(id);
      setCheckIfRequestIsValid(data.approvalCompleted);
    }
  }, [shippingRequestDetails]);

  const addDeputiesIntoShipping = async () => {
    shippingDeputySelect.map(async (deputy) => {
      const deputiesShippingRequest = {
        shppping_response: getShippingResponse,
        shippingDeputeId: deputy.id,
        created_by: sessionStorage.getItem("userName"),
      };
      await actions.addDeputiesShippingRequest(deputiesShippingRequest);
    });
  };

  useEffect(() => {
    if (getShippingResponse.length && shippingDeputySelect.length) {
      addDeputiesIntoShipping();
    }
  }, [getShippingResponse, shippingDeputySelect]);

  useEffect(() => {
    if (shippingRequestDetails.length) {
      shippingRequestEdit(shippingRequestDetails, props);
     //await actions.searchByStudyNumber(shippingRequestDetails[0]?.study_number)
    }

    if (shippingRequestDetails.length && deputyShippingDetails.length) {
      if (shippingDeputySelect.length === 0) {
        actions.addShippingDeputies(deputyShippingDetails);
      } else {
        const updateDeputies = shippingDeputySelect.map((deputy) => {
          return {
            shppping_response: shippingRequestDetails,
            deputy_id: deputy.id,
            id: deputy.id,
            cwid: deputy.cwid,
            full_name: get(deputy, "full_name", null),
            phone_no: get(deputy, "phone_no", null),
            mobile_no: get(deputy, "mobile_no", null),
            department: get(deputy, "department", null),
          };
        });
        const uniqueDeputies = [
          ...new Map(updateDeputies.map((item) => [item["id"], item])).values(),
        ];

        actions.addShippingDeputies(uniqueDeputies);
      }
    }
  }, [
    shippingRequestDetails.length && shippingRequestDetails[0].id,
    deputyShippingDetails.length,
  ]);
  let shippingRequestId;
  let currentStatus = null;

  //Handle new create scenarios.
  if (Array.isArray(getShippingResponse) && getShippingResponse.length > 0) {
    shippingRequestId = getShippingResponse[0].id;
    currentStatus = getShippingResponse[0].status;
  } else if (
    Array.isArray(shippingRequestDetails) &&
    shippingRequestDetails.length > 0
  ) {
    //Handles update scenaios.
    shippingRequestId = shippingRequestDetails[0].id;
    currentStatus = shippingRequestDetails[0].status;
  }

  const handleDistributionTemperatureChange = (e) => {
    actions.getDistributionTempValue(e.target.value);
  };


  // useEffect(()=>{
  //   actions.setExtraData({
  //     ...extra,
  //     ["date_material_needed_on_site"]: "",
  //   })
  // },[extra.type_of_shipment])
  const isWeekend = (date) => {
    if (new Date(date).getDay() == 0 || new Date(date).getDay() == 6) {
      return true;
    }
  }

  useEffect(() => {
    if (shippingActionType === 'NEW' || shippingRequestDetails.length && shippingRequestDetails[0].status === 'INPUT' && shippingActionType === 'EDIT') {
      caliculateVaildMateNeedDate();
    }
  }, [extra.type_of_shipment])

  useEffect( () => {
    const id = _.get(shippingRequestDetails, "[0].id", null);
      if (id) {
     const review_by_shipping = shippingUnderReviewRequestDetails?.find(
          (item) => item?.id === id
        )
      if(review_by_shipping){
      setReviewBy(review_by_shipping?.review_by_shipping);
      setCheckBoxState(review_by_shipping?.review_by_shipping ? true : false);
      }
      else{
        setReviewBy(shippingRequestDetails[0]?.review_by_shipping);
        setCheckBoxState(shippingRequestDetails[0]?.review_by_shipping ? true : false);
      }
     }
  }, [shippingRequestDetails]);

  const caliculateVaildMateNeedDate = () => {
    let dates = [];
    setExcludeDates([]);
    if (extra.date_material_available_to_ship) {
      switch (extra.type_of_shipment) {
        case "INTERNATIONAL": {
          {
            if (extra.date_material_available_to_ship) {
              let currMaterialAvailDate = new Date(
                extra.date_material_available_to_ship
              ).getTime();
              dates.push(new Date(currMaterialAvailDate));
              let count = 0;
              while (count < 21) {
                currMaterialAvailDate =
                  currMaterialAvailDate + 24 * 60 * 60 * 1000;
                if (
                  !isWeekend(currMaterialAvailDate)
                ) {
                  dates.push(new Date(currMaterialAvailDate));
                  count = count + 1;
                }
              }
              SetValidMatNeedDate(currMaterialAvailDate);
              setExcludeDates(dates);

            }
          }
        }
          break;
        case "DOMESTIC":
          {
            if (extra.date_material_available_to_ship) {
              let currMaterialAvailDate = new Date(extra.date_material_available_to_ship).getTime();
              dates.push(new Date(currMaterialAvailDate));
              let count = 0;
              while (count < 4) {
                currMaterialAvailDate =
                  currMaterialAvailDate + 24 * 60 * 60 * 1000;
                if (
                  !isWeekend(currMaterialAvailDate)
                ) {
                  dates.push(new Date(currMaterialAvailDate));
                  count = count + 1;
                }
              }
              SetValidMatNeedDate(currMaterialAvailDate);
              setExcludeDates(dates);
            }
          }
          break;
        case "INNER_EU":
          {
            if (extra.date_material_available_to_ship) {
              let currMaterialAvailDate = new Date(extra.date_material_available_to_ship).getTime();
              dates.push(new Date(currMaterialAvailDate));
              let count = 0;
              while (count < 4) {
                currMaterialAvailDate =
                  currMaterialAvailDate + 24 * 60 * 60 * 1000;
                if (
                  !isWeekend(currMaterialAvailDate)
                ) {
                  dates.push(new Date(currMaterialAvailDate));
                  count = count + 1;
                }
              }
              SetValidMatNeedDate(currMaterialAvailDate);
              setExcludeDates(dates);
            }
          }
          break;
      }
    }
  }

  useEffect(() => {
    if(shippingActionType==='NEW' || shippingRequestDetails.length  && shippingRequestDetails[0].status === 'INPUT' && shippingActionType==='EDIT' ){
      validateMaterialAvailDate();
      caliculateVaildMateNeedDate();
    }

  }, [extra.date_material_available_to_ship]);



useEffect(()=>{
  if(shippingActionType==='NEW' || shippingRequestDetails.length  && shippingRequestDetails[0].status === 'INPUT' && shippingActionType==='EDIT' ){
    validateMaterialNeedDate()
  }
},[validMatNeedDate])

  useEffect(() => {
    if (shippingActionType === 'NEW' || shippingRequestDetails.length && shippingRequestDetails[0].status === 'INPUT' && shippingActionType === 'EDIT') {
      if (extra.type_of_shipment && extra.date_material_available_to_ship && extra.date_material_needed_on_site) {
        validateMaterialNeedDate();
      }
    }

  }, [extra.date_material_needed_on_site])

  const checkValidNeedMatDate = () => {

    if (typeof (validMatNeedDate) === 'undefined') {
      return false;
    }
    else {
      return (extra.date_material_needed_on_site) ? new Date(extra.date_material_needed_on_site).getTime() < validMatNeedDate : false
    }
  }
  const validateMaterialNeedDate = () => {
    if (extra.date_material_available_to_ship && checkValidNeedMatDate()) {
      let Error = `Please choose Date Material Needed On Site  after ${WeekDay[new Date(validMatNeedDate).getDay()]}, ${Months[new Date(validMatNeedDate).getMonth()]} ${new Date(validMatNeedDate).getDate()}, ${new Date(validMatNeedDate).getFullYear()}`
      toast.error(Error)
      setMaterialNeedStatus({
        errorCheck: true,
        message: Error
      })
    }
    else {
      setMaterialNeedStatus({
        errorCheck: false,
        message: ""
      })
    }

  }



  useEffect(() => {
    if (
      destinationAddrSelect &&
      Object.keys(destinationAddrSelect).length !== 0
    ) {
      actions.getFinalRecipients(destinationAddrSelect.id);
    } else {
      actions.getMyFinalRecipients(userId);
    }
    setError(false);
    setErrorMessages([]);
    dispatch(updateErrorTab(0));
  }, [destinationAddrSelect]);

  useEffect(() => {
    const requestDetails = _.get(shippingRequestDetails, "[0]");
    const formRootSelector = `[data-name="shipping_request_form"]`;
    if (requestDetails?.status === STATUS.INBOX) {
      //Disable all fields and enable the reviewer fields only.
      disableInputFieldsBySelector(formRootSelector);
      enableInputFieldsBySelector(`[data-name="shipment_review_section"]`);
      enableInputFieldsBySelector(`[data-name="overall-comments"]`);
    } else if (requestDetails?.status === STATUS.READY_FOR_DELIVERY) {
      //Disable all fields and enable the logistics partner field only.
      disableInputFieldsBySelector(formRootSelector);
      enableInputFieldsBySelector(`[name="logistics_partner"]`);
      enableInputFieldsBySelector(`[data-name="tracking_number"]`);
      enableInputFieldsBySelector(`[data-name="overall-comments"]`);
      enableInputFieldsBySelector(`#dispatch_date_mon, #dispatch_date_lev`);
      enableInputFieldsBySelector(
        `#date_of_receipt, #dispatch_date_mon, #dispatch_date_lev`
      );
    } else if (
      [
        STATUS.READY_FOR_DELIVERY,
        STATUS.REGULATORY_REVIEWER_1,
        STATUS.REGULATORY_REVIEWER_2,
      ].includes(requestDetails?.status)
    ) {
      disableInputFieldsBySelector(formRootSelector);
    } else if (requestDetails?.status === STATUS.IN_DELIVERY) {
      //Disable all fields and enable the reviewer fields only.
      disableInputFieldsBySelector(formRootSelector);
      disableInputFieldsBySelector(`[data-name="tracking_number"]`);
    }
    else {
      enableInputFieldsBySelector();
      disableInputFieldsBySelector("#internal_distribution_number");
    }
  }, [shippingRequestDetails]);

  useEffect(() => {
    const shippingRequestData = _.get(shippingRequestDetails, "[0]");
    const formRootSelector = `[data-name="shipping_request_form"]`;
    if (shippingRequestData?.status === STATUS.INBOX) {
      //Disable all fields and enable the reviewer fields only.
      disableInputFieldsBySelector(formRootSelector);
      enableInputFieldsBySelector(`[data-name="shipment_review_section"]`);
      enableInputFieldsBySelector(`[data-name="overall-comments"]`);
    } else if (shippingRequestData?.status === STATUS.READY_FOR_DELIVERY) {
      //Disable all fields and enable the logistics partner field only.
      disableInputFieldsBySelector(formRootSelector);
      enableInputFieldsBySelector(`[name="logistics_partner"]`);
      enableInputFieldsBySelector(`[data-name="tracking_number"]`);
      enableInputFieldsBySelector(
        `#date_of_receipt, #dispatch_date_mon, #dispatch_date_lev`
      );
      enableInputFieldsBySelector(`[data-name="overall-comments"]`);
    } else if (shippingRequestData?.status === STATUS.IN_DELIVERY) {
      //Disable all fields and enable the tracking number field only.
      disableInputFieldsBySelector(formRootSelector);
      enableInputFieldsBySelector(`[data-name="overall-comments"]`);
    } else if (
      [
        STATUS.READY_FOR_DELIVERY,
        STATUS.REGULATORY_REVIEWER_1,
        STATUS.REGULATORY_REVIEWER_2,
      ].includes(shippingRequestData?.status)
    ) {
      disableInputFieldsBySelector(formRootSelector);
    } else {
      enableInputFieldsBySelector();
      disableInputFieldsBySelector("#internal_distribution_number");
    }
  }, [shippingRequestData?.status]);
  const handleAddDiputies = () => {
    history.push(`/addDelegates`);
  };

  const hanldeInternalOrderId = (e) => {
    actions.getInternalOrderIdValue(e.target.value);
  };

  
  
  const handleSpecial = (value) => {
    //setHandleEntry(parseInt(e.target.value))
    // setHandleEntry(e)
  
  };

  const handleShipmentSpecific = (e) => {
    actions.getShipmentSpecificValue(e.target.value);
  };

  const handleShippingCreateDateChange = (value) => {
    actions.getShippingCreateDateValue(value);
  };

  const hanldeCommentChange = (e) => {
    actions.getShippingCreateCommentValue(e.target.value);
  };

  const handleSapDeliveryNum = (e) => {
    actions.getSapDeliveryNumValue(e?.target?.value);
  };
  const handleDispatchMON = (value) => {
    actions.getDispatchMONValue(value);
  };

  const handleDispatchLEV = (value) => {
    actions.getDispatchLEVValue(value);
  };

  const handleDateOfReceipt = (value) => {
    actions.getDateOfReceiptValue(value);
  };

  const handleTrackingNum = (e) => {
    actions.getTrackingNumValue(e.target.value);
  };

  const handleStatusQuo = (e) => {
    actions.getStatusQuoValue(e.target.value);
  };

  const handleShippingRequstClose = () => {
    handleRedirectTo();
  };

  const handleRedirectTo = () => {
    actions.clearshippingRequest();
    actions.setShippingSearchData(shippingSearchData)
    actions.setTableOptions(tableOptions)
    actions.setAdminShippingTableOptions(tableAdminShippingOptions)
    if (mailLinkData?.isAccessedFromMAil) {
      window.location.href = window.location.origin;
      return;
    }
    if (!!redirectTo) {
      history.push(redirectTo);
      if (redirectTo === "/myShippings") {
        actions.setNavKey(NAV_KEYS.MY_SHIPPING_REQUESTS);
      }
    } else {
      history.push("/myShippings");
      actions.setNavKey(NAV_KEYS.MY_SHIPPING_REQUESTS);
    }
  };
  // const compareDates = (d1, d2) => {
  //   let date1 = (Date.parse(d1));
  //   let date2 = (Date.parse(d2));
  //   if (date1 > date2) {
  //     return (date1);
  //   }
  //   else if (date1 < date2) {
  //     return (date2);
  //   }
  //   else {
  //     return date1;
  //   }
  // };
  const inprogressstatus = [
    null,
    undefined,
    STATUS.INPUT,
    STATUS.AWAITING_FOR_APPROVAL,
  ]
  const validateMaterialAvailDate = () => {
    if (
      new Date(extra.date_material_available_to_ship).getTime() <
      new Date().getTime() - 24 * 60 * 60 * 1000
    ) {
      toast.error(t("shipping_validation.material_avail_past"));
      setMaterialAvailStatus({
        errorCheck: true,
        message: t("shipping_validation.material_avail_past"),
      });
    } else {
      setMaterialAvailStatus({
        errorCheck: false,
        message: "",
      });
    }
  };

  const openCancelRequestModal = () => {
    setIsCancelModalVisible(true);
  }

  const handleSaveAndClose = async (goBack, newStatus,mandatoryFlag) => {
    
    setLoader(true);
    try {
      const destinationAddr =
        (Object.keys(destinationAddrSelect).length !== 0 &&
          destinationAddrSelect.id) ||
        false;
      const shippingDeputy =
        (shippingDeputySelect.length && shippingDeputySelect[0].id) || false;
      const finalRecipient =
        (Object.keys(finalRecipientSelect).length !== 0 &&
          finalRecipientSelect.id) ||
        false;

      let errorTab = 0;
      let errorCheck = false;
      let errorList = [];

      if (materialAvailStatus.errorCheck) {
        toast.error(materialAvailStatus.message);
        errorCheck = materialAvailStatus.errorCheck;
        errorList.push(materialAvailStatus.message);
      } else if (materialNeedStatus.errorCheck) {
        toast.error(materialNeedStatus.message);
        errorCheck = materialNeedStatus.errorCheck;
        errorList.push(materialNeedStatus.message);
      }
      if (attachements?.length > 0) {
        const totalSize = attachements.reduce((acc, file) => acc + Number(file.size), 0);
        if (totalSize >= FILE_SIZE_LIMIT) {
          errorCheck = true;
          toast.error(t("file_manager.file_limit_message"))
          errorList.push(t("file_manager.file_limit_message"));
        }
      }

      if (shippingRequestDetails.length) {
        if (
          shippingActionType === EDIT &&
          newStatus === STATUS.REGULATORY_REVIEWER_1
        ) {
          // only submission
          if (!distributionTempValue) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Distribution_temperature_is_required")
            );
            errorTab = 1;
          }
          if (
            distributionTemperature.find(
              (item) => item.id === distributionTempValue
            )?.valid === 0
          ) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Distribution_temperature_is_required")
            );
            errorTab = 1;
          }
          if (!extra?.handling_type) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.temperature_is_required")
            );
            errorTab = 1;
          }
          if (!extra.type_of_shipment) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Type_of_shipment_is_required")
            );
            errorTab = 1;
          }
          if (
            shippingType.find((item) => item.id === extra.type_of_shipment)
              ?.isactive === false
          ) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Type_of_shipment_is_required")
            );
            errorTab = 1;
          }
          if (
            !shippingMaterialSelectedValue?.documents?.length &&
            !_.size(_.get(shippingMaterialSelect, ["0", "documents"]))
          ) {
            errorCheck = true;
            errorList.push(
              t("shipping_material_validations.doc_required_validation")
            );
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }

          if (!extra?.date_material_available_to_ship) {
            errorCheck = true;
            errorList.push(
              t("shipping_request.date_material_available_to_ship") +
              " " +
              t("commonlabels.is_req")
            );
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!extra?.date_material_needed_on_site) {
            errorCheck = true;
            errorList.push(
              t("shipping_request.date_material_needed_on_site") +
              " " +
              t("commonlabels.is_req")
            );
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!shippingDeputy) {
            errorCheck = true;
            errorList.push(t("shipping_validation.deputy_is_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!destinationAddr) {
            errorCheck = true;
            errorList.push(t("shipping_validation.destination_is_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!finalRecipient) {
            errorCheck = true;
            errorList.push(t("shipping_validation.final_res_is_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          if (
            get(extra, "is_mat_food_produced_animal", null) === null ||
            get(extra, "is_mat_client_used_animal", null) === null
          ) {
            errorCheck = true;
            errorList.push(t("shipping_validation.review_questions"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (!shippingMaterialSelect || !shippingMaterialSelect.length || ((Object.keys(shippingMaterialSelectedValue).length === 0) && shippingMaterialSelect[0].materailName.id.trim() == "")) {
            errorCheck = true;
            errorList.push(t("shipping_validation.material_is_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (extra.prp_use_of_material.trim().length === 0) {
            errorCheck = true;
            errorList.push(t("shipping_validation.prop_use_of_materials_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          if (
            shipper[0]?.recipient_id === undefined ||
            shipper[0]?.recipient_id === null
          ) {
            errorCheck = true;
            errorList.push(t("shipping_validation.shipper_info_required"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          if (
            extra.type_of_shipment &&
            extra.type_of_shipment !== "INNER_EU" &&
            extra.type_of_shipment !== "DOMESTIC" &&
            shippingMaterialSelect &&
            shippingMaterialSelect.length &&
            !shippingMaterialSelect[0].amountAndUnit
          ) {
            errorCheck = true;
            errorList.push(t("shipping_material_validations.total_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (
            extra.type_of_shipment &&
            extra.type_of_shipment !== "INNER_EU" &&
            extra.type_of_shipment !== "DOMESTIC" &&
            shippingMaterialSelect &&
            shippingMaterialSelect.length &&
            (!shippingMaterialSelect[0].curency ||
              shippingMaterialSelect[0].curency === "0")
          ) {
            errorCheck = true;
            errorList.push(t("shipping_material_validations.currency_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
        } else if (
          shippingActionType === SUBMIT &&
          shippingRequestDetails[0].status === STATUS.INPUT
        ) {
          // only submission
          if (!distributionTempValue) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Distribution_temperature_is_required")
            );
            errorTab = 1;
          }
          if (
            distributionTemperature.find(
              (item) => item.id === distributionTempValue
            )?.valid === 0
          ) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Distribution_temperature_is_required")
            );
            errorTab = 1;
          }
          if (!extra?.handling_type) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.temperature_is_required")
            );
            errorTab = 1;
          }

          if (!extra.type_of_shipment) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Type_of_shipment_is_required")
            );
            errorTab = 1;
          }
          if (
            shippingType.find((item) => item.id === extra.type_of_shipment)
              ?.isactive === false
          ) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Type_of_shipment_is_required")
            );
            errorTab = 1;
          }
          if (
            materialShippingDetails.find(
              (material) => !material?.documents?.length
            )
          ) {
            errorCheck = true;
            errorList.push(
              t("shipping_material_validations.doc_required_validation")
            );
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }

          if (
            shippingRequestDetails.length !== 0 &&
            shippingRequestDetails[0].date_material_available_to_ship === null
          ) {
            errorCheck = true;
            errorList.push(
              t("shipping_request.date_material_available_to_ship") +
              " " +
              t("commonlabels.is_req")
            );
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (
            shippingRequestDetails.length !== 0 &&
            shippingRequestDetails[0].date_material_needed_on_site === null
          ) {
            errorCheck = true;
            errorList.push(
              t("shipping_request.date_material_needed_on_site") +
              " " +
              t("commonlabels.is_req")
            );
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!shippingDeputy) {
            errorCheck = true;
            errorList.push(t("shipping_validation.deputy_is_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!destinationAddr) {
            errorCheck = true;
            errorList.push(t("shipping_validation.destination_is_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!finalRecipient) {
            errorCheck = true;
            errorList.push(t("shipping_validation.final_res_is_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          if (
            get(extra, "is_mat_food_produced_animal", null) === null ||
            get(extra, "is_mat_client_used_animal", null) === null
          ) {
            errorCheck = true;
            errorList.push(t("shipping_validation.review_questions"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (!shippingMaterialSelect?.length) {
            errorCheck = true;
            errorList.push(t("shipping_validation.material_is_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (extra.prp_use_of_material.trim().length === 0) {
            errorCheck = true;
            errorList.push(t("shipping_validation.prop_use_of_materials_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          if (
            shipper[0]?.recipient_id === undefined ||
            shipper[0]?.recipient_id === null
          ) {
            errorCheck = true;
            errorList.push(t("shipping_validation.shipper_info_required"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          if (
            extra.type_of_shipment &&
            extra.type_of_shipment !== "INNER_EU" &&
            extra.type_of_shipment !== "DOMESTIC" &&
            shippingMaterialSelect &&
            shippingMaterialSelect.length &&
            !shippingMaterialSelect[0].amountAndUnit
          ) {
            errorCheck = true;
            errorList.push(t("shipping_material_validations.total_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (
            extra.type_of_shipment &&
            extra.type_of_shipment !== "INNER_EU" &&
            extra.type_of_shipment !== "DOMESTIC" &&
            shippingMaterialSelect &&
            shippingMaterialSelect.length &&
            (!shippingMaterialSelect[0].curency ||
              shippingMaterialSelect[0].curency === "0")
          ) {
            errorCheck = true;
            errorList.push(t("shipping_material_validations.currency_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
        } else if (
          shippingActionType === NEW &&
          newStatus === STATUS.REGULATORY_REVIEWER_1
        ) {
          // only submission
          if (!distributionTempValue) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Distribution_temperature_is_required")
            );
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          if (!extra?.handling_type) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.temperature_is_required")
            );
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!extra.type_of_shipment) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Type_of_shipment_is_required")
            );
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          // if (
          //   materialShippingDetails.find(
          //     (material) => !material?.documents?.length,
          //   )
          // ) {
          //   errorCheck = true;
          //   errorList.push(
          //     t("shipping_material_validations.doc_required_validation"),
          //   );
          // }

          if (!extra?.date_material_available_to_ship) {
            errorCheck = true;
            errorList.push(
              t("shipping_request.date_material_available_to_ship") +
              " " +
              t("commonlabels.is_req")
            );
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!extra?.date_material_needed_on_site) {
            errorCheck = true;
            errorList.push(
              t("shipping_request.date_material_needed_on_site") +
              " " +
              t("commonlabels.is_req")
            );
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!shippingDeputy) {
            errorCheck = true;
            errorList.push(t("shipping_validation.deputy_is_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!destinationAddr) {
            errorCheck = true;
            errorList.push(t("shipping_validation.destination_is_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }

          if (!finalRecipient) {
            errorCheck = true;
            errorList.push(t("shipping_validation.final_res_is_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          if (
            get(extra, "is_mat_food_produced_animal", null) === null ||
            get(extra, "is_mat_client_used_animal", null) === null
          ) {
            errorCheck = true;
            errorList.push(t("shipping_validation.review_questions"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (!shippingMaterialSelect || !shippingMaterialSelect.length) {
            errorCheck = true;
            errorList.push(t("shipping_validation.material_is_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (extra.prp_use_of_material.trim().length === 0) {
            errorCheck = true;
            errorList.push(t("shipping_validation.prop_use_of_materials_req"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          if (
            shipper[0]?.recipient_id === undefined ||
            shipper[0]?.recipient_id === null
          ) {
            errorCheck = true;
            errorList.push(t("shipping_validation.shipper_info_required"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          if (
            extra.type_of_shipment &&
            extra.type_of_shipment !== "INNER_EU" &&
            extra.type_of_shipment !== "DOMESTIC" &&
            !shippingMaterialSelectedValue.amountAndUnit
          ) {
            errorCheck = true;
            errorList.push(t("shipping_material_validations.total_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (
            extra.type_of_shipment &&
            extra.type_of_shipment !== "INNER_EU" &&
            extra.type_of_shipment !== "DOMESTIC" &&
            (!shippingMaterialSelectedValue.curency ||
              shippingMaterialSelectedValue.curency === "0")
          ) {
            errorCheck = true;
            errorList.push(t("shipping_material_validations.currency_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
        }
      } else {
        if (shippingActionType === NEW && newStatus !== undefined) {
          if (!distributionTempValue) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Distribution_temperature_is_required")
            );
            errorTab = 1;
          }
          if (!extra?.handling_type) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.temperature_is_required")
            );
            errorTab = 1;
          }
          if (!extra.type_of_shipment) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Type_of_shipment_is_required")
            );
            errorTab = 1;
          }
          if (
            shippingType.find((item) => item.id === extra.type_of_shipment)
              ?.isactive === false
          ) {
            errorCheck = true;
            errorList.push(
              t("shipping_validation.Type_of_shipment_is_required")
            );
            errorTab = 1;
          }
          // if (
          //   materialShippingDetails.find(
          //     (material) => !material?.documents?.length,
          //   )
          // ) {
          //   errorCheck = true;
          //   errorList.push(
          //     t("shipping_material_validations.doc_required_validation"),
          //   );
          // }

          if (!extra?.date_material_available_to_ship) {
            errorCheck = true;
            errorList.push(
              t("shipping_request.date_material_available_to_ship") +
              " " +
              t("commonlabels.is_req")
            );
            errorTab = 1;
          }

          if (!extra?.date_material_needed_on_site) {
            errorCheck = true;
            errorList.push(
              t("shipping_request.date_material_needed_on_site") +
              " " +
              t("commonlabels.is_req")
            );
            errorTab = 1;
          }

          if (!shippingDeputy) {
            errorCheck = true;
            errorList.push(t("shipping_validation.deputy_is_req"));
            errorTab = 1;
          }

          if (!destinationAddr) {
            errorCheck = true;
            errorList.push(t("shipping_validation.destination_is_req"));
            errorTab = 1;
          }

          if (!finalRecipient) {
            errorCheck = true;
            errorList.push(t("shipping_validation.final_res_is_req"));
            errorTab = 1;
          }
          if (
            get(extra, "is_mat_food_produced_animal", null) === null ||
            get(extra, "is_mat_client_used_animal", null) === null
          ) {
            errorCheck = true;
            errorList.push(t("shipping_validation.review_questions"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (!shippingMaterialSelect || !shippingMaterialSelect.length || ((Object.keys(shippingMaterialSelectedValue).length === 0) && shippingMaterialSelect[0].materailName.id.trim() == "")) {
            errorCheck = true;
            errorList.push(t("shipping_validation.material_is_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (extra.prp_use_of_material.trim().length === 0) {
            errorCheck = true;
            errorList.push(t("shipping_validation.prop_use_of_materials_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (
            shipper[0]?.recipient_id === undefined ||
            shipper[0]?.recipient_id === null
          ) {
            errorCheck = true;
            errorList.push(t("shipping_validation.shipper_info_required"));
            errorTab = errorTab === 2 || errorTab === 3 ? 3 : 1;
          }
          if (
            extra.type_of_shipment &&
            extra.type_of_shipment !== "INNER_EU" &&
            extra.type_of_shipment !== "DOMESTIC" &&
            !shippingMaterialSelectedValue.amountAndUnit
          ) {
            errorCheck = true;
            errorList.push(t("shipping_material_validations.total_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
          if (
            extra.type_of_shipment &&
            extra.type_of_shipment !== "INNER_EU" &&
            extra.type_of_shipment !== "DOMESTIC" &&
            (!shippingMaterialSelectedValue.curency ||
              shippingMaterialSelectedValue.curency === "0")
          ) {
            errorCheck = true;
            errorList.push(t("shipping_material_validations.currency_req"));
            errorTab = errorTab === 1 || errorTab === 3 ? 3 : 2;
          }
        }
      }
      if (errorCheck) {
        setError(true);
        setErrorMessages(errorList);
        dispatch(updateErrorTab(errorTab));
        const containerElem = document.querySelector('[data-testid="wrapper"]');
        containerElem.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setLoader(false);
        return true;
      }

      if (!errorCheck) {
        setError(false);
        dispatch(updateErrorTab(0));
        setLoader(true);
        let shipmentSpecificVal = "RECEIVE_DATE";
        if (shipmentSpecificValue === "latestArrivalDate") {
          shipmentSpecificVal = "READY_DATE";
        }

        let createdOn = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (!!shippingCreateDateValue) {
          createdOn = moment(shippingCreateDateValue).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }

        let dispatchMONVal = null;
        if (!!dispatchMONValue) {
          dispatchMONVal = dispatchMONValue;
        }

        let dispatchLEVVal = null;
        if (!!dispatchLEVValue) {
          dispatchLEVVal = dispatchLEVValue;
        }

        let dateOfReceiptVal = null;
        if (!!dateOfReceiptValue) {
          dateOfReceiptVal = dateOfReceiptValue;
        }

        let statusType = null;
        let status = null;
        if (shippingActionType === SUBMIT) {
          statusType = SUBMIT;
          status = STATUS.INBOX;
        }

        if (shippingActionType === HAND_BACK) {
          statusType = HAND_BACK;
          status = STATUS.INPUT;
        }

        if (shippingActionType === CANCEL_REQUEST) {
          statusType = CANCEL_REQUEST;
          status = STATUS.CANCELLED;
        }

        if (shippingActionType === CLOSE) {
          statusType = CLOSE;
          status = STATUS.CLOSE;
        }

        let commentsReq = newShippingRequestCommentsList? newShippingRequestCommentsList : saveCommentsReqRef.current;
        
        const payload = {
          internal_distribution_number: internalOrderIdValue,
          distributiontemperature_id: distributionTempValue,
          created_on: createdOn,
          c_comment: shippingCreateCommentValue,
          address_id: Object.keys(destinationAddrSelect).length
            ? destinationAddrSelect.id
            : "",
          recipient_id: Object.keys(finalRecipientSelect).length
            ? finalRecipientSelect.id
            : "",
          shipping_deputies: shippingDeputySelect,
          shippingMaterials: shippingMaterialSelect,
          shipment_constraint_type: shipmentSpecificVal,
          created_by: sessionStorage.getItem("userName"),
          shippingMaterialSelected: shippingMaterialSelectedValue,
          shipment_date_mon: dispatchMONVal,
          shipment_date_lev: dispatchLEVVal,
          // shipment_constraint_date: createdOn,
          shipment_constraint_date: null,
          shipment_close_date: dateOfReceiptVal,
          tracking_number: trackingNumValue,
          status_comment: statusQuoValue,
          sap_delivery_number: sapDeliveryNumValue,
          attachements,
          shipper,
          ...extra,
          date_material_available_to_ship:
            extra?.date_material_available_to_ship
              ? handleTimeZoneOffset(extra.date_material_available_to_ship)
              : null,
          date_material_needed_on_site: extra?.date_material_needed_on_site
            ? handleTimeZoneOffset(extra.date_material_needed_on_site)
            : null,
          newShippingRequestAlertComments: commentsReq,
          handling_type:extra?.handling_type,
          clientTimeZone:clientTimeZone,
          submitted_on_Date:submitted_on_Date
        };

        //Handle new create scenarios.
        if (
          Array.isArray(getShippingResponse) &&
          getShippingResponse.length > 0
        ) {
          shippingRequestData = getShippingResponse[0];
        } else if (
          Array.isArray(shippingRequestDetails) &&
          shippingRequestDetails.length > 0
        ) {
          //Handles update scenaios.
          shippingRequestData = shippingRequestDetails[0];
        }

        if (!shippingRequestData) {
          const fields = {
            status: STATUS.INPUT,
            submitted_on_Date:submitted_on_Date
          };
          const newPayLoad = { ...payload, ...fields };
          const res = await actions.createShipping(newPayLoad);

          // toast.success(t("commonlabels.savedSuccessfullyMsg"));
          await actions.getShippingRequestDetails({ id: res?.data[0]?.id });
          await actions.getMaterialShippingDetails({ id: res?.data[0]?.id });
          await actions.getShipperDetails({ id: res?.data[0]?.id });
          await actions.getShippingAttachment({ id: res?.data[0]?.id });

          if (newStatus === STATUS.REGULATORY_REVIEWER_1) {
            let payloadNewSubmit = {
              shippingID: res?.data[0]?.id,
            };
            await actions.SubmitUpdate(payloadNewSubmit);
          }
        } else if (shippingRequestData && shippingRequestData.id) {
          const errorMsg = [];
          if (shippingActionType === CLOSE) {
            if (
              shippingRequestData?.status === STATUS.SHIPER_REVIEW_DONE &&
              (statusType !== CANCEL_REQUEST || status !== STATUS.CANCELLED)
            ) {
              if (dateOfReceiptValue) {

                if (!extra?.logistics_partner) {
                  errorMsg.push(t("shippingrequest.logistic_partner_required"));
                }
                if (!trackingNumValue) {
                  errorMsg.push(t("shippingrequest.tracking_number_required"));
                }
                if (!dispatchLEVValue && !dispatchMONValue) {
                  errorMsg.push(t("shippingrequest.dispatch_date_required"));
                }
                if (extra?.logistics_partner && trackingNumValue && (dispatchLEVValue && dispatchMONValue)) {
                  status = STATUS.IN_DELIVERY;
                }
              }
              else if (!dateOfReceiptValue) {
                shippingRequestData = { ...shippingRequestData, ['status']: STATUS.READY_FOR_DELIVERY }
              }
            }

            if (
              ( newStatus === STATUS.READY_FOR_DELIVERY || shippingRequestData?.status === STATUS.INBOX) && goBack && mandatoryFlag === true
            ) {
              const materialsWithoutHtsCode = shippingMaterialSelect.filter(
                (item) =>
                  !(item.htsCode || item.hts_code_germany || item.hts_code_china)
              );

              if (
                materialsWithoutHtsCode.length>0 &&
                extra.type_of_shipment === "INTERNATIONAL"
              ) {
                errorMsg.push(t("shippingrequest.hts_required"));
              }
            }
             
            if((shippingRequestData?.status === STATUS.INBOX && mandatoryFlag === true)){
              const materialsWithSDS = shippingMaterialSelect.filter(
                (item) =>
                  (item.hazardous_sds || item.is_hazardous )
              );
  
              if (get(extra, "is_shippment_hazardous") === null) {
                errorMsg.push(t("shipping_validation.dangerous_goods_shipments"));
              }
              if (materialsWithSDS?.length>0 &&  get(extra, "is_shippment_hazardous") === false && (extra?.shippment_hazardous_comments?.trim()?.length === 0 || (get(extra, "shippment_hazardous_comments") === null || get(extra, "shippment_hazardous_comments") === undefined))) {
                errorMsg.push(t("shipping_validation.comment_required"));
              }
          }
          }
          else {
            if (shippingRequestData?.status === STATUS.READY_FOR_DELIVERY && (statusType !== CANCEL_REQUEST || status !== STATUS.CANCELLED)) {
              if (dateOfReceiptValue) {

                if (!extra?.logistics_partner) {
                  errorMsg.push(t("shippingrequest.logistic_partner_required"));
                }
                if (!trackingNumValue) {
                  errorMsg.push(t("shippingrequest.tracking_number_required"));
                }
                if (!dispatchLEVValue && !dispatchMONValue) {
                  errorMsg.push(t("shippingrequest.dispatch_date_required"));
                }
                if (extra?.logistics_partner && trackingNumValue && (dispatchLEVValue || dispatchMONValue)) {
                  status = STATUS.IN_DELIVERY;
                }
              }
              else if (!dateOfReceiptValue) {
                status = STATUS.READY_FOR_DELIVERY;
              }
            }
          }

          if (
            ( newStatus === STATUS.READY_FOR_DELIVERY || shippingRequestData?.status === STATUS.INBOX) && goBack && mandatoryFlag === true
          ) {
            const materialsWithoutHtsCode = shippingMaterialSelect.filter(
              (item) =>
                !(item.htsCode || item.hts_code_germany || item.hts_code_china)
            );

            if (
              materialsWithoutHtsCode.length>0 &&
              extra.type_of_shipment === "INTERNATIONAL"
            ) {
              errorMsg.push(t("shippingrequest.hts_required"));
            }
          }
          
          if((shippingRequestData?.status === STATUS.INBOX && mandatoryFlag === true)){
            const materialsWithSDS = shippingMaterialSelect.filter(
              (item) =>
                (item.hazardous_sds  || item.is_hazardous)
            );
            if (get(extra, "is_shippment_hazardous") === null) {
              errorMsg.push(t("shipping_validation.dangerous_goods_shipments"));
            }
            if (materialsWithSDS?.length>0 &&  get(extra, "is_shippment_hazardous") === false && (extra?.shippment_hazardous_comments?.trim()?.length === 0 || (get(extra, "shippment_hazardous_comments") === null || get(extra, "shippment_hazardous_comments") === undefined))) {
              errorMsg.push(t("shipping_validation.comment_required"));
            }
        }

          // if (dispatchMONValue && dateOfReceiptValue && dispatchLEVValue) {
          //   if ((compareDates(dispatchMONValue, dispatchLEVValue) > Date.parse(dateOfReceiptValue))) {
          //     errorMsg = t("shippingrequest.date_of_recipient_validation_msg");
          //   }
          // }

          // if (dispatchMONValue && dateOfReceiptValue && !dispatchLEVValue) {
          //   if ((Date.parse(dispatchMONValue) > Date.parse(dateOfReceiptValue))) {
          //     errorMsg = t("shippingrequest.date_of_Elanco_validation_msg");
          //   }
          // }
          // if (dateOfReceiptValue && dispatchLEVValue && !dispatchMONValue) {
          //   if ((Date.parse(dispatchLEVValue) > Date.parse(dateOfReceiptValue))) {
          //     errorMsg = t("shippingrequest.date_of_Logistics_validation_msg");
          //   }
          // }
          if (shippingRequestData?.status === STATUS.INBOX && shippingActionType !== CANCEL_REQUEST) {
            //shippingRequestData = {...shippingRequestData,['status']: STATUS.READY_FOR_DELIVERY}
            status = STATUS.READY_FOR_DELIVERY
          }
          if (errorMsg.length > 0) {
            setLoader(false);
            let error = toast.error(<div>
              <ul>
                {errorMsg.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>);
            return error;
          }

          const fields = {
            shippingResponse: [shippingRequestData],
            updated_by: sessionStorage.getItem("userName"),
            updated_on: null,
            status: newStatus
              ? newStatus
              : status
                ? status
                : shippingRequestData.status,
            statusType,
            isReviewRejected: newStatus === STATUS.INPUT,
          };

          const newPayLoad = { ...payload, ...fields };
          const statusData = await actions.getShippingStatus(
            shippingRequestData.id
          );

          if (
            (get(statusData, "data[0].status", false) ===
              STATUS.READY_FOR_DELIVERY &&
              fields.status === STATUS.READY_FOR_DELIVERY && shippingRequestData?.status === STATUS.INBOX) ||
            (get(statusData, "data[0].status", false) === STATUS.INPUT &&
              fields.status === STATUS.READY_FOR_DELIVERY) ||
            (get(statusData, "data[0].status", false) ===
              STATUS.READY_FOR_DELIVERY &&
              fields.status === STATUS.INPUT) ||
            (get(statusData, "data[0].status", false) === STATUS.INPUT &&
              fields.status === STATUS.INPUT &&
              shippingRequestData?.status === STATUS.INBOX)
          ) {
            setLoader(false);
            toast.error(t("commonlabels.reviewer_error"));
            if (goBack) {
              handleRedirectTo();
            }
            return;
          }

          const res = await actions.updateShipping(newPayLoad);
          await actions.getShippingRequestDetails(shippingRequestData);
          toast.success(t("commonlabels.editedSuccessfullyMsg"));
          const id = shippingRequestData.id;
          await actions.getMaterialShippingDetails({ id });
          await actions.getShipperDetails({ id });
          await actions.getShippingAttachment({ id });
        }
      }

      if (goBack) {
        handleRedirectTo();
      }
    } catch (e) {
      console.error(e);
    }
    setLoader(false);
  };

  const closeErrorMessage = () => {
    setError(false);
    dispatch(updateErrorTab(0));
  };

  const handleStackClose = () => {
    setStackOpen(false);
  };

  const ShowTabMenu = () => {
    if (window.location.pathname.includes("previewShipping")) {
      actions.getShippingActionType(INSPECT)
      return <TabMenu />;
    }
    if (
      shippingActionType === STATUS.IN_PROGRESS &&
      shippingActionType === EDIT
    ) {
      return <></>;
    }

    if (shippingActionType === EDIT || shippingActionType === NEW) {
      return <TabMenu />;
    }

    if (shippingActionType === INSPECT) {
      return <TabMenu />;
    }
    if (shippingActionType === SUBMIT) {
      return <TabMenu />;
    }

    return <></>;
  };

  const handleRedirectToRecipient = () => {
    history.push("/addRecipients", { redirect: true });
    //actions.redirectAction(location.pathname);
  };

  const handleRedirectToAddress = () => {
    history.push("/addAddress", { redirectShipping: true, id: null });
    // actions.redirectAction("/shipping");
  };
  const handleUnderReviewCheckBox = async (event) => {
    setLoader(true);
    let shippingData = {
      id: shippingRequestDetails[0].id,
      name: '',
      updatedBy: userId,
    };

    let underReviewMessage = t("address.reviewReset");

    if (event.target.checked) {
      shippingData.name = userId
      underReviewMessage = t("address.reviewSet");
    }

    setCheckBoxState(event.target.checked);
    setReviewBy(shippingData.name);


    await actions.updateAssignedReviewer(shippingData).then((response) => {
      if (response.data.IsSuccess) {
        actions.shippingUnderReviewRequestDetails();
        toast.success(underReviewMessage);
      }
      setLoader(false);
    });
  }

  const cancelRequestModalBody = () => {
    return (
        <div className="row  m0">
            <div className="col-12">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label> {t("shipping_request.notice1")} </label>
                            <label className="col-form-label"> {t("shipping_request.notice2")} </label>
                            <label> {t("shipping_request.notice3")} </label>
                        </div>
                        <div className="col-lg-12 p-0">
                            <div className="form-group">
                                <label className="">
                                    {" "}
                                    <b>{t("comments_alert.comments")}<span className="req">*</span></b>{" "}
                                </label>
                                <TextareaAutosize
                                    id="comments"
                                    name="comments"
                                    aria-label="comments"
                                    margin="normal"
                                    variant="outlined"
                                    size="large"
                                    minRows={6}
                                    style={{ width: "100%" }}
                                    value={comments === null ? "" : comments}
                                    onChange={(e) => { 
                                        e.preventDefault()
                                        setComments(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const cancelRequestModalFooter = () => {
    return (
        <>
            <button
                type="button"
                id="Yes"
                className="btn btn-primary btn-inner-close"
                onClick={(e) => confirmComments(e)}
            >
                {t("commonlabels.confirmCancelRequest")}
            </button>
            <button
                type="button"
                id="Close"
                className="btn btn-black btn-inner-close cancel_button"
                onClick={(e) => {
                    setIsCancelModalVisible(false);
                    setComments("");
                }}
            >
                {t("commonlabels.noButton")}
            </button>
        </>
    );
};

const confirmComments = (e) => {
    try {
        let valid = true;

        if (comments === "") {
            toast.error(t("comments_alert.validComment"));
            return (valid = false);
        }
        if (valid) {
            const req = {
                shippingid: shippingRequestId,
                comments: comments,
                parentcommentid: null,
                createdon: null,
                createdby: userId,
                taggedusers: [],
            };
            saveCommentsReqRef.current = req; 
            handleSaveAndClose(true);
            setIsCancelModalVisible(false);
            setComments("");
        }
    } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
    }
}
  const submitted_on_Date = FormatDate(shippingRequestDetails[0]?.submitted_on)
  return (
  <div>
    <div className="tab-bar-styles">
      <ShowTabMenu />
      <ShippingRequestSubmit
        handleSaveAndClose={handleSaveAndClose}
        handleShippingRequstClose={handleShippingRequstClose}
        openCancelRequestModal={openCancelRequestModal}
        {...props}
      />
    </div>
    <LoadingOverlay active={loader} spinner className="custom-overlay-wrapper">      
      <Box sx={{ flexGrow: 1 }} m={1} pt={3} className="pt0 mt08">
        {isError && (
          <div className="pt0 mt0">
            <ErrorMessage
              closeErrorMessage={closeErrorMessage}
              errorMessages={errorMessages}
            />
          </div>
        )}

        {stackOpen && (
          <CustomizedSnackbar
            stackOpen={stackOpen}
            handleStackClose={handleStackClose}
            setStackOpen={setStackOpen}
            messageType="success"
            message={alertMessage}
          />
        )}

        {stackOpen && respError && (
          <CustomizedSnackbar
            stackOpen={stackOpen}
            handleStackClose={handleStackClose}
            setStackOpen={setStackOpen}
            messageType="error"
            message="Something went wrong"
          />
        )}
      </Box>
      <Container
        data-name="shipping_request_form"
        maxWidth="xl"
        className="border"
      >
        <Model
          showheader={isCancelModalVisible}
          modalHeader={t("commonlabels.cancelRequestTitle")}
          modalbody={cancelRequestModalBody()}
          modalfooter={cancelRequestModalFooter()}
        ></Model>

        <If condition={currentStatus === STATUS.INBOX && shippingActionType !== INSPECT}>
          <Then>
            <>
              <Alert severity="warning">
                {t("shipping_request.invoice_lock_alert")}
              </Alert>
              <br />
            </>
          </Then>
        </If>

        <If
          condition={
            [NEW, EDIT].includes(shippingActionType) &&
            (userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.APPLICANT))
          }
        >
          <Then>
            <Alert severity="warning">
              {t("shipping_request.save_shipping_request_alert_of_fixed_bar")}
              <br></br>
              {t("shipping_request.save_mandary_shipping_request_alert")}
              {/* <br></br>
              {t("shipping_request.notice9")} */}
            </Alert>
            <br />
          </Then>
        </If>

        <If
          condition={
            (shippingRequestDetails.length > 0 &&
              [

                STATUS.READY_FOR_DELIVERY,
                STATUS.INBOX,

                STATUS.INPUT,
                STATUS.IN_DELIVERY,
                 STATUS.CANCELLED,

                STATUS.REGULATORY_REVIEWER_1,
                STATUS.REGULATORY_REVIEWER_2,
              ].includes(shippingRequestDetails[0].status)) ||
            (shippingRequestDetails.length > 0 &&
              [STATUS.CANCELLED, STATUS.IN_DELIVERY,STATUS.AWAITING_FOR_APPROVAL].includes(
                shippingRequestDetails[0].status
              ) &&
              shippingActionType === INSPECT)
          }
        >
          <Then>
            <StatusInformation
              shippingRequestDetails={shippingRequestDetails}
              handleSapDeliveryNum={handleSapDeliveryNum}
              handleDispatchMON={handleDispatchMON}
              handleDispatchLEV={handleDispatchLEV}
              handleDateOfReceipt={handleDateOfReceipt}
              handleTrackingNum={handleTrackingNum}
              handleStatusQuo={handleStatusQuo}
              dispatchMONValue={dispatchMONValue}
              dispatchLEVValue={dispatchLEVValue}
              dateOfReceiptValue={dateOfReceiptValue}
              trackingNumValue={trackingNumValue}
              statusQuoValue={statusQuoValue}
              sapDeliveryNumValue={sapDeliveryNumValue}
              handleUnderReviewCheckBox={handleUnderReviewCheckBox}
              reviewBy={reviewBy}
              checkboxState={checkboxState}
              logisticPartnerDictionaryData={logisticPartnerDictionaryData}
              clientTimeZone={clientTimeZone}
              {...props}
            />
          </Then>
        </If>

        {shippingRequestDetails.length > 0 &&
          shippingRequestDetails[0].status === STATUS.INPUT &&
          location.pathname !== "/shippingRequests/edit" && (
            <Card className="mb-4 p-2" variant="outlined">
              <CardContent>
                <Stack direction="row" spacing={2}>
                  <div className="col-2">
                    <label className="form-label">
                      {t("search_fields.created_by")}
                    </label>
                    {shippingRequestDetails[0].created_by}
                  </div>
                  <div className="col-2">
                    <label className="form-label">
                      {t("shipping_request.create_date")}
                    </label>
                    {shippingRequestDetails[0].created_on
                      ? DateFormat(
                        shippingRequestDetails[0].created_on,
                        i18n.resolvedLanguage
                      )
                      : ""}
                  </div>
                  <div className="col-2">
                    <label className="form-label">
                      {t("shipping_request.submit_date")}
                    </label>
                    {submitted_on_Date===null?"-/-/-": DateFormat(
                        shippingRequestDetails[0].submitted_on,
                        i18n.resolvedLanguage
                      )
                      }
                  </div>
                  <div className="col-2">
                    <label className="form-label">
                      {t("process_shipping_request.edit_user")}
                    </label>
                    {shippingRequestDetails[0].updated_by}
                  </div>
                  <div className="col-2">
                    <label className="form-label">
                      {t("process_shipping_request.edit_date")}
                    </label>
                    {shippingRequestDetails[0].updated_on
                      ? DateFormat(
                        shippingRequestDetails[0].updated_on,
                        i18n.resolvedLanguage
                      )
                      : null}
                  </div>
                </Stack>
              </CardContent>
            </Card>
          )}

        <GeneralInformation
          handleSpecialMethod={handleSpecial}
          handleChange={handleDistributionTemperatureChange}
          value={distributionTempValue}
          distributionTemperature={distributionTemperature}
          list={distributionTemperature}
          hanldeInternalOrderId={hanldeInternalOrderId}
          internalOrderIdValue={internalOrderIdValue}
          handleShipmentSpecific={handleShipmentSpecific}
          shipmentSpecificValue={shipmentSpecificValue}
          handleShippingCreateDateChange={handleShippingCreateDateChange}
          shippingCreateDateValue={shippingCreateDateValue}
          hanldeCommentChange={hanldeCommentChange}
          shippingCreateCommentValue={shippingCreateCommentValue}
          statusQuoValue={statusQuoValue}
          handleSapDeliveryNum={handleSapDeliveryNum}
          sapDeliveryNumValue={sapDeliveryNumValue}
          handleStatusQuo={handleStatusQuo}
          incoterms={incoterms}
          actions
          regulatoryReviewer1List={regulatoryReviewer1List}
          shipperCountry={shipperCountry}
          shippingType={
            ifRequestCompletedApprovals(
              _.get(shippingRequestDetails, ["0", "status"], undefined)
            )
              ? shippingType
              : shippingType.filter((item) => item.isactive === true)
          }
          shippingRequestDetails={shippingRequestDetails}
          Actions={actions}
          ExcludeDates={excludeDates}
          clientTimeZone={clientTimeZone}
          {...props}
        />

        <div className="row col-12 p-0 mb-4  mt-4 no-marrgin">
          <div className="col-lg-6 p-0 col-12">
            <ApplicantComponent
              shippingRequestDetails={shippingRequestDetails}
            />
          </div>
          <div className="col-lg-6 pl-3 pr-0 col-12">
            <DeputiesComponent
              handleAddDiputies={handleAddDiputies}
              shippingDeputySelect={shippingDeputySelect}
              deputyShippingDetails={deputyShippingDetails}
              {...props}
            />
          </div>
        </div>

        <div className="row col-12 p-0 mt-4 no-marrgin">
          <div className="col-lg-12 p-0 col-12">
            <DestinationComponent
              redirectTo={redirectTo}
              redirectAction={handleRedirectToAddress}
              allAddressList={allAddressList}
              // shippingType={
              //   ifRequestCompletedApprovals(
              //     _.get(shippingRequestDetails, ["0", "status"], undefined)
              //   )
              //     ? shippingType
              //     : shippingType.filter((item) => item.isactive === true)
              // }
              shippingType={
                inprogressstatus.includes(
                  _.get(shippingRequestDetails, ["0", "status"], undefined)
                )
                  ? shippingType.filter((item) => item.isactive === true)
                  : shippingType
              }
              destinationAddrSelect={destinationAddrSelect}
              shippingActionType={shippingActionType}
              shippingRequestDetails={shippingRequestDetails}
              extra={extra}
              actions={actions}
              shipper={shipper}
              allRecipients={allRecipients}
            />
          </div>
          <br />
          <div className="col-lg-12 pl-0 pr-0 col-12 mt20">
            <FinalRecipient
              redirectTo={redirectTo}
              finalRecipients={finalRecipients}
              finalRecipientSelect={finalRecipientSelect}
              redirectAction={handleRedirectToRecipient}
              shippingRequestDetails={shippingRequestDetails}
              shippingActionType={shippingActionType}
              actions={actions}
            />
          </div>
        </div>

        <br />
        <div className="col-lg-12  pr-0 pl-0 col-12">
          <ConsignorComponent
            shipperData={shipper}
            setChange={actions.setShipper}
            IsPreview={![EDIT, NEW].includes(shippingActionType)}
            allRecipients={allRecipients}
            shippingRequestDetails={shippingRequestDetails}
            destinationAddrSelect={destinationAddrSelect}
          />
        </div>

        <div className="col-lg-12  mb-4 col-12 pl-0 pr-0">
          {![EDIT, NEW, SUBMIT,INSPECT].includes(shippingActionType) && (
            <AdditionalData
              {...{ internalOrderIdValue, shippingCreateCommentValue }}
              distributionTempValue={
                ifRequestCompletedApprovals(
                  _.get(shippingRequestDetails, ["0", "status"], undefined)
                )
                  ? distributionTemperature?.find(
                    (item) => item.id == distributionTempValue
                  )?.temperature_description_english
                  : distributionTemperature
                    .filter((x) => x.valid === 1)
                    .find((item) => item.id == distributionTempValue)
                    ?.temperature_description_english
              }
            />
          )}
        </div>

        <If condition={![EDIT, NEW, INSPECT, SUBMIT].includes(shippingActionType)}>
          <Then>
            <div className="col-lg-12  col-12 pl-0 pr-0">
              <ShippingMaterailCard
                {...props}
                phases={
                  ifRequestCompletedApprovals(
                    _.get(shippingRequestDetails, ["0", "status"], undefined)
                  )
                    ? phases
                    : phases.filter((item) => item.valid)
                }
                allContainerList={
                  ifRequestCompletedApprovals(
                    _.get(shippingRequestDetails, ["0", "status"], undefined)
                  )
                    ? allContainerList
                    : allContainerList.filter((item) => item.is_active)
                }
              />
            </div>
          </Then>
        </If>

        <>
          <div className="row col-12 p-0   mt-4 no-marrgin pl-0">
            <AttachDocuments
              tableName="shipping_request"
              attachements={attachements}
              setAttachments={actions.setAttachments}
              readOnly={
                ![STATUS.INPUT, null].includes(currentStatus) ||
                ![EDIT, NEW].includes(shippingActionType)
              }
            />
          </div>

          <ImporterOfRecord
            actions={actions}
            extra={extra}
            readOnly={![EDIT, NEW].includes(shippingActionType)}
            impoter={impoter}
            shippingRequestDetails={shippingRequestDetails}
          />

          <If condition={![STATUS.INPUT, null].includes(currentStatus) || [INSPECT].includes(shippingActionType)}>
            <Then>
              <ShipmentReviewerSection
                actions={actions}
                extra={extra}
                readOnly={![EDIT, NEW].includes(shippingActionType)}
                brokers={brokers}
                currentStatus={currentStatus}
                shippingRequestDetails={shippingRequestDetails}
                attachmentRead={[STATUS.INBOX].includes(currentStatus)}
                shippingActionType={shippingActionType}
              />
              <RegulatoryInfo
                actions={actions}
                id={shippingRequestDetails[0]}
              />

            </Then>
          </If>
        </>
      </Container>
    </LoadingOverlay>
  </div>
  );
};

export default ShippingRequest;
