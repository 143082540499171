import types from "./types";
import fetchData from "../util";

const getCountries = () => {
  return fetchData(types.GET_COUNTRIES, "getCountries");
};
const getCountriesList = () => {
  return fetchData(types.GET_COUNTRIES_LIST, "getCountriesList");
};
const getUserDetails = (...params) => {
  return fetchData(types.GET_DETAILS, "getUserDetails", params);
};
const getCities = (...params) => {
  return fetchData(types.GET_CITIES, "getCities", params);
};
const getShippingStatus = (...params) => {
  return fetchData(types.GET_SHIPPING_STATUS, "getShippingStatus", params);
};
const getDistributionTemperature = () => {
  return fetchData(
    types.GET_DISTRIBUTION_TEMPERATURE,
    "getDistributionTemperature",
  );
};

const getDestinationAddress = (...params) => {
  return fetchData(
    types.GET_DESTINATION_ADDRESS,
    "getDestinationAddress",
    params,
  );
};

const getDestinationAddrList = (...params) => {
  return fetchData(
    types.GET_DESTINATION_ADDR_LIST,
    "getDestinationAddrList",
    params,
  );
};
const saveDistribution = (...params) => {
  return fetchData(types.SAVE_DISTRIBUTION, "saveDistribution", params);
};
const updateDistributionAccess = (...params) => {
  return fetchData(types.SAVE_DISTRIBUTION, "updateDistributionAccess", params);
};

const getFinalRecipients = (...params) => {
  return fetchData(types.GET_FINAL_RECIPIENTS, "getFinalRecipients", params);
};
const getMyFinalRecipients = (...params) => {
  return fetchData(types.GET_FINAL_RECIPIENTS, "getmyFinalRecipients", params);
};

const getDeputies = () => {
  return fetchData(types.GET_DEPUTIES, "getDeputies");
};

const createShipping = (...params) => {
  return fetchData(types.CREATE_SHIPPING, "createShipping", params);
};
const SubmitUpdate = (...params) => {
  return fetchData(types.CREATE_SHIPPING_SUBMIT, "SubmitUpdate", params);
};
const getShippings = () => {
  return fetchData(types.GET_SHIPPINGS, "getShippings");
};

const getMyShippings = (...params) => {
  return fetchData(types.GET_MY_SHIPPINGS, "getMyShippings", params);
};
const exportExcel = () => {
  return fetchData(types.EXPORT_DATA, "exportExcel");
};
const exportSelectedExcel = (...params) => {
  return fetchData(types.EXPORT_SELECTED_DATA, "exportSelectedExcel", params);
};

const getShippingMaterials = () => {
  return fetchData(types.GET_SHIPPING_METERIALS, "getShippingMeterials");
};

const addMaterialShippingRequest = (...params) => {
  return fetchData(
    types.ADD_MATERIAL_SHIPPING_REQUEST,
    "addMaterialShippingRequest",
    params,
  );
};

const addDeputiesShippingRequest = (...params) => {
  return fetchData(
    types.ADD_DEPUTIES_SHIPPING_REQUEST,
    "addDeputiesShippingRequest",
    params,
  );
};
const deleteDeputiesShippingRequest = (...params) => {
  return fetchData(
    types.DELETE_ACTION,
    "deleteDeputiesShippingRequest",
    params,
  );
};

const getShippingRequestDetails = (...params) => {
  return fetchData(
    types.SHIPPING_REQUEST_DETAILS,
    "getShippingRequestDetails",
    params,
  );
};
const shippingUnderReviewRequestDetails = () => {
  return fetchData(
    types.SHIPPING_UNDER_REVIEW_REQUEST_DETAILS,
    "shippingUnderReviewRequestDetails"
  );
};
const getDeputyShippingDetails = (...params) => {
  return fetchData(
    types.DEPUTY_SHIPPING_REQUEST_DETAILS,
    "getDeputyShippingDetails",
    params,
  );
};

const getMaterialShippingDetails = (...params) => {
  return fetchData(
    types.MATERIAL_SHIPPING_REQUEST_DETAILS,
    "getMaterialShippingDetails",
    params,
  );
};

const updateShipping = (...params) => {
  return fetchData(types.UPDATE_SHIPPING, "updateShipping", params);
};

const getPhases = () => {
  return fetchData(types.GET_PHASESES, "getPhases");
};

const updateMaterial = (...params) => {
  return fetchData(types.UPDATE_MATERIAL, "updateMaterial", params);
};

const deleteShipping = (...params) => {
  return fetchData(types.DELETE_SHIPPING, "deleteShipping", params);
};

const destinationAddrSelect = (payload = {}) => {
  return {
    type: types.DESTINATION_ADDR_SELECT,
    payload,
  };
};

const clearDestinationAddrSelect = (payload = {}) => {
  return {
    type: types.CLEAR_DESTINATION_ADDR_SELECT,
    payload,
  };
};

const finalRecipientSelect = (payload = {}) => {
  return {
    type: types.FINAL_RECIPIENT_SELECT,
    payload,
  };
};

const clearFinalRecipientSelect = (payload = {}) => {
  return {
    type: types.CLEAR_FINAL_RECEIPIENT_SELECT,
    payload,
  };
};

const addShippingDeputies = (payload = {}) => {
  return {
    type: types.ADD_SHIPPING_DEPUTIES_SELECT,
    payload,
  };
};
const addShippingDeputiesDetails = (payload = {}) => {
  return {
    type: types.DEPUTY_SHIPPING_REQUEST_DETAILS_SELECTED,
    payload,
  };
};
const clearShippingDeputies = (payload = []) => {
  return {
    type: types.CLEAR_SHIPPING_DEPUTIES_SELECT,
    payload,
  };
};

const getInternalOrderIdValue = (payload = []) => {
  return {
    type: types.GET_INTERNAL_ORDER_ID_VALUE,
    payload,
  };
};

const getShipmentSpecificValue = (payload = "") => {
  return {
    type: types.GET_SHIPMENT_SPECIFIC_VALUE,
    payload,
  };
};

const getShippingCreateDateValue = (payload = "") => {
  return {
    type: types.GET_SHIPPING_CREATE_DATE_VALUE,
    payload,
  };
};

const getDistributionTempValue = (payload = "") => {
  return {
    type: types.GET_DISTRIBUTION_TEMP_VALUE,
    payload,
  };
};

const getShippingCreateCommentValue = (payload = "") => {
  return {
    type: types.GET_SHIPPING_CREATE_COMMENT_VALUE,
    payload,
  };
};

const addShippingMaterialSelect = (payload = {}) => {
  return {
    type: types.ADD_SHIPPING_MATERIAL_SELECT,
    payload,
  };
};

const shippingMaterialSelectedValue = (payload = {}) => {
  return {
    type: types.SHIPPING_MATERIAL_SELECTED_VALUE,
    payload,
  };
};

const getShippingActionType = (payload = "") => {
  return {
    type: types.GET_SHIPPING_ACTION_TYPE,
    payload,
  };
};

const clearShippingMaterialSelectedValue = (payload = {}) => {
  return {
    type: types.CLEAR_SHIPPING_MATERIAL_SELECTED_VALUE,
    payload,
  };
};

const materailIdSelect = (payload = "") => {
  return {
    type: types.MATERIAL_ID_SELECT,
    payload,
  };
};

const getSapDeliveryNumValue = (payload = "") => {
  return {
    type: types.SAP_DELIVERY_NUM_VALUE,
    payload,
  };
};

const getDispatchMONValue = (payload = "") => {
  return {
    type: types.DISPATCH_MOV_VALUE,
    payload,
  };
};

const getDispatchLEVValue = (payload = "") => {
  return {
    type: types.DISPATCH_LEV_VALUE,
    payload,
  };
};

const getDateOfReceiptValue = (payload = "") => {
  return {
    type: types.DATE_OF_RECEIP_VALUE,
    payload,
  };
};

const getTrackingNumValue = (payload = "") => {
  return {
    type: types.TRACKING_NUM_VALUE,
    payload,
  };
};

const getStatusQuoValue = (payload = "") => {
  return {
    type: types.STATUS_QUO_VALUE,
    payload,
  };
};

const clearshippingRequest = (payload = "") => {
  return {
    type: types.CLEAR_SHIPPING_REQUEST,
    payload,
  };
};

const redirectAction = (payload = "") => {
  return {
    type: types.REDIRECT_ACTION,
    payload,
  };
};

const phaseSelectValue = (payload = "") => {
  return {
    type: types.PHASES_SELECT_VALUE,
    payload,
  };
};

const getProcessShippmentList = () => {
  return fetchData(types.PROCESS_SHIPPMENT_LIST, "getProcessShippmentList");
};

const isLoader = (payload = {}) => {
  return {
    type: types.IS_LOADER,
    payload,
  };
};

const setShippingSearchData = (payload = "") => {
  return {
    type: types.SET_SHIPPING_SEARCH_DATA,
    payload,
  };
};

const getShippingHistory = (ids) => {
  return fetchData(types.GET_SHIPPMENT_HISTORY, "getShippingHistory", { ids });
};

const setShippingLogSearchData = (data) => {
  return {
    type: types.SET_SHIPPING_LOG_SEARCH,
    payload: data,
  };
};

const updateShippingRequestValid = (...params) => {
  return fetchData(
    types.UPDATE_SHIPPING_REQUEST_VALID,
    "updateShippingRequestValid",
    params,
  );
};

const getShippingSearchAutocompleteData = () => {
  return fetchData(
    types.SET_SHIPPING_SEARCH_AUTOCOMPLETE_DATA,
    "getShippingSearchAutocompleteData",
  );
};

const getRequestByDeputy = (...params) => {
  return fetchData(types.GET_REQURST_BY_DEPUTY, "requestByDeputy", params);
};

const shippingHistory = (...params) => {
  return fetchData(types.SHIPPING_HISTORY, "shippingHistory", params);
};

const getShippingsCountry = (...params) => {
  return fetchData(types.SHIPPING_COUNTRY, "getShippingsCountry", params);
};
const getShippingsTwoWeeks = () => {
  return fetchData(types.SHIPPING_TWO_WEEKS, "getShippingsTwoWeeks");
};

const setAttachments = (payload) => {
  return {
    type: types.SET_ATTACHMENT,
    payload,
  };
};

const getShippingAttachment = (...params) => {
  return fetchData(types.SET_ATTACHMENT, "getShippingAttachment", params);
};

const setShipper = (payload) => {
  return {
    type: types.SET_SHIPPER,
    payload,
  };
};
const getShipperDetails = (...params) => {
  return fetchData(types.SET_SHIPPER, "getShipperDetails", params);
};

const setExtraData = (payload) => {
  return {
    type: types.SET_EXTRA,
    payload,
  };
};
const setValidMaterialNeedDate = (payload) => {
  return {
    type: types.SET_MATERIAL_VALID_NEED_DATE,
    payload
  }
}

const getAllCurrency = () => {
  return fetchData(types.GET_ALL_CURRENCY, "getAllCurrency");
};

const getAllUnits = () => {
  return fetchData(types.GET_ALL_UNITS, "getAllUnits");
};
const getAllContainer = () => {
  return fetchData(types.GET_ALL_CONTAINER, "getAllContainer");
};
const getAllContainerList = () => {
  return fetchData(types.GET_ALL_CONTAINER_LIST, "getAllContainerList");
};
const getAllBrokers = () => {
  return fetchData(types.GET_ALL_BROKERS, "getAllBrokers");
};
const getIncoterm = () => {
  return fetchData(types.GET_INCOTERMS, "getIncoterm");
};
const getImpoter = () => {
  return fetchData(types.GET_IMPOTER, "getImpoter");
};
const setAddressSearchFields = (payload) => {
  return {
    type: types.SET_ADDRESS_SEARCH_FIELDS,
    payload,
  };
};

const getDestinationAddressSearchOptions = (...params) => {
  return fetchData(
    types.GET_DESTINATION_ADDRESS_SEARCH_OPTIONS,
    "getDestinationAddressSearchOptions",
    params,
  );
};

const getShippingType = () => {
  return fetchData(types.GET_SHIPPINGS_TYPE, "getShippingType");
};
const searchByStudyNumber = (...params) => {
  return fetchData(
    types.GET_STUDY_NUMBER_DETAILS,
    "searchByStudyNumber",
    params,
  );
};
const getReviewerCommentsByShippingRequestId = (...params) => {
  return fetchData(types.SET_OVERALL_COMMENTS, "getReviewerCommentsByShippingRequestId", params);
};
const addReviewerComment = (...payload) => {
  return fetchData(types.SET_OVERALL_COMMENTS, "addReviewerComment", payload);
};
const updateReviewerComment = (...payload) => {
  return fetchData(types.SET_OVERALL_COMMENTS, "updateReviewerComment", payload);
};

const revertApproval = (...payload) => {
  return fetchData(types.REVERT_APPROVAL, "revertApproval", payload);
}

const setMailLinkData = (data) => {
  return {
    type: types.SET_MAIL_LINK_DATA,
    payload: data
  }
}

const setShipperCountry = (data) => {
  return {
    type: types.SET_SHIPPER_COUNTRY,
    payload: data
  }
}

const saveAlertDetails = (...params) => {
  return fetchData(types.SAVE_ALERT_DETAILS, "saveAlertDetails", params);
};

const getAlertCommentsByShippingRequestID = (...params) => {
  return fetchData(types.GET_ALERTCOMMENTS_BY_SHIPPINGREQUEST_ID, "getAlertCommentsByShippingRequestID", params);
};

const getAlertByuserId = (...params) => {
  return fetchData(types.GET_ALERT_BY_USERID, "getAlertByuserId", params);
};



const updateAlertCountByUserID = (...params) => {
  return fetchData(types.UPDATE_COUNT_BY_USERID, "updateAlertCountByUserID", params);
};

const getAllusers = () => {
  return fetchData(types.GET_ALL_USERS, "getAllusers");
};

const setNewShippingRequestCommentsList = (payload) => {
  return { type: types.SET_NEW_SHIPPING_REQUEST_COMMENTS_LIST, payload }
};
const setTableOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_MATERIAL_TABLE_OPTIONS, payload: data });
  };
};
const setAdminShippingTableOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_ADMIN_SHIPPING_TABLE_OPTIONS, payload: data });
  };
};
const getUnreadAlertsList = (...params) => {
  return fetchData(types.GET_UNREAD_ALERTS_LIST, "getUnreadAlertsList", params);
};

const updateAssignedReviewer = (...params) => {
  return fetchData(types.UPDATE_SHIPPING_REVIEW_STATUS, "updateAssignedReviewer", params);
}

const getLogisticsParterDictionaryData = () => {
  return fetchData(types.GET_LOGISTICS_PARTNER_DICTIONARY_DATA, "getLogisticsParterDictionaryData");
}

const setClientTimeZone = (data) =>{
  return {
    type:types.SET_CLIENT_TIMEZONE,
    payload : data
  }
}

const getShippingReviewerList = ()=>{
  return fetchData(types.GET_SHIPPING_REVIEWER_LIST, "getShippingReviewerList")
}
export default {
  setAdminShippingTableOptions,
  setTableOptions,
  getCountries,
  isLoader,
  getCities,
  getUserDetails,
  getDistributionTemperature,
  getDestinationAddress,
  getDestinationAddrList,
  destinationAddrSelect,
  clearDestinationAddrSelect,
  getFinalRecipients,
  getMyFinalRecipients,
  finalRecipientSelect,
  clearFinalRecipientSelect,
  getDeputies,
  addShippingDeputies,
  clearShippingDeputies,
  getInternalOrderIdValue,
  getShipmentSpecificValue,
  getShippingCreateDateValue,
  getDistributionTempValue,
  getShippingCreateCommentValue,
  createShipping,
  SubmitUpdate,
  getShippings,
  exportExcel,
  exportSelectedExcel,
  getShippingMaterials,
  addShippingMaterialSelect,
  getMyShippings,
  shippingMaterialSelectedValue,
  addMaterialShippingRequest,
  addDeputiesShippingRequest,
  getShippingRequestDetails,
  shippingUnderReviewRequestDetails,
  getDeputyShippingDetails,
  getMaterialShippingDetails,
  getShippingActionType,
  updateShipping,
  clearShippingMaterialSelectedValue,
  materailIdSelect,
  getSapDeliveryNumValue,
  getDispatchMONValue,
  getDateOfReceiptValue,
  getDispatchLEVValue,
  getTrackingNumValue,
  getStatusQuoValue,
  getPhases,
  phaseSelectValue,
  updateMaterial,
  saveDistribution,
  deleteShipping,
  getProcessShippmentList,
  setShippingSearchData,
  getShippingHistory,
  setShippingLogSearchData,
  clearshippingRequest,
  updateShippingRequestValid,
  getRequestByDeputy,
  getShippingSearchAutocompleteData,
  shippingHistory,
  updateDistributionAccess,
  redirectAction,
  getShippingsCountry,
  getShippingsTwoWeeks,
  deleteDeputiesShippingRequest,
  setAttachments,
  getShippingAttachment,
  setShipper,
  getShipperDetails,
  setExtraData,
  getAllCurrency,
  getAllUnits,
  getAllBrokers,
  setAddressSearchFields,
  addShippingDeputiesDetails,
  getDestinationAddressSearchOptions,
  getAllContainer,
  getAllContainerList,
  searchByStudyNumber,
  getShippingType,
  getIncoterm,
  getImpoter,
  addReviewerComment,
  updateReviewerComment,
  getReviewerCommentsByShippingRequestId,
  setMailLinkData,
  getShippingStatus,
  revertApproval,
  getCountriesList,
  setShipperCountry,
  setValidMaterialNeedDate,
  saveAlertDetails,
  getAlertCommentsByShippingRequestID,
  getAlertByuserId,
  setNewShippingRequestCommentsList,
  updateAlertCountByUserID,
  getAllusers,
  getUnreadAlertsList,
  updateAssignedReviewer,
  getLogisticsParterDictionaryData,
  setClientTimeZone,
  getShippingReviewerList
};
