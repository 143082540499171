import React from "react";
import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import LoadingOverlay from "react-loading-overlay";
import BasicTabs from "../common/TabMenu";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import CommentsReplyComponent from "./CommentsReplyComponent";
import AlertCommentsTable from "./AlertCommentsTable";
import AddAlertModalComponent from "./AddAlertModalComponent";
import { lowerCase } from "lodash";
import { appInsights } from "../../insightIogging";

export default function CommentsComponent(props) {
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const { actions, AlertCommentsList, shippingRequestDetails, allUsers } = props;
  const shippingRequestId =
    shippingRequestDetails.length > 0 ? shippingRequestDetails[0].id : null;
  const [isLoading, setIsLoading] = useState(true);
  const [allusersList, setAllUsersList] = useState([]);
  const [isModalSaveRunning, setIsModalSaveRunning] = useState(false);
  const { t } = useTranslation();

  const [showmodalforAddAlert, setshowmodalforAdd] = useState(false);
  const [showModalForReply, setShowModalForReply] = useState(false);

  const [value, setValue] = useState([]);
  const [comments, setComments] = useState("");
  const [selectedRowData, setSelectedRowData] = useState({});
  appInsights.trackTrace({
    message:" comments tab",
    severityLevel:1
});
  useEffect(() => {
    actions.getAllusers();
    if (shippingRequestId) {
      actions
        .getAlertCommentsByShippingRequestID({ shippingRequestId })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    let selectedUsers = [{'email': userId}];//Add here to remove current user from the list    
    const filteredResult = allUsers.filter(
      (x) => !selectedUsers.filter((y) => lowerCase(y.email) === lowerCase(x.email)).length
    );
    setAllUsersList(filteredResult);    
  }, [allUsers, userId]); 

  const miniSpinner = () => {
    return (
      <div>
        <ReactLoading
          type="spinningBubbles"
          color="#0072ce"
          display="inline"
          width="34px"
          height="34px"
        />
      </div>
    );
  };

  // Handle Functions Start
  const handleChange = (e, newValue) => {
    setValue(newValue);   
  };

  const handleChangeComments = (e) => {
    setComments(e.target.value);
  };

  const saveMethod = (e) => {
    try {
      let valid = true;

      if (comments === "") {
        toast.error(t("comments_alert.validComment"));
        return (valid = false);
      }

      const req = {
        shippingid: shippingRequestId,
        comments: comments,
        parentcommentid: selectedRowData ? selectedRowData.id : null,
        createdon: null,
        createdby: userId,
        taggedusers: value,
        archive:false
      };
      if (comments.length !== 0) {
        setIsModalSaveRunning(true);
        actions.saveAlertDetails(req).then((res) => {
          toast.success(t("commonlabels.savedSuccessfullyMsg"));
          setIsModalSaveRunning((prevBool) => !prevBool);
          closemodal();
          if (shippingRequestId) {
            actions.getAlertCommentsByShippingRequestID({
              shippingRequestId,
            });
          }
        });
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };
  // Handle Functions End

  /* Modal Popup Start*/
  const openmodalForNew = () => {
    setshowmodalforAdd(true);
  };

  const closemodal = () => {
    setValue([]);
    setComments("");
    setSelectedRowData({});
    setshowmodalforAdd(false);
    closeReplyModal();
  };

  const openReplyModal = (e, rowData) => {
    setSelectedRowData(rowData);
    setShowModalForReply(true);
  };

  const closeReplyModal = () => {
    setShowModalForReply(false);
  };
  return (
    <LoadingOverlay active={isLoading} spinner>
      <BasicTabs />
      <div className="container-fluid  ">
        <div className="row mlr15 border ">
          <div className="col-12">
            <div className="form-group row mt-3">
              <label class="col-sm-10 col-form-label">
                {t("comments_alert.alert/tag")}
              </label>
              <div className=" col-sm-2 text-right">
                <button
                  type="button"
                  id="AddTag"
                  className="btn btn-primary btn-inner-close"
                  onClick={(e) => openmodalForNew(e)}
                >
                  {appInsights.trackEvent({
                  name:"comments added "
                  },{properties:shippingRequestDetails[0]?.internal_distribution_number})}
                  {t("comments_alert.add_alert_btn")}
                </button>
              </div>
            </div>
          </div>
          <div className="col-12"> </div>
          <div className="col-12">
            {/* Alert Comments Grid */}
            <AlertCommentsTable
              fromNavbar={false}
              actions={actions}
              AlertCommentsList={AlertCommentsList.sort()}
              openReplyModal={openReplyModal}
            ></AlertCommentsTable>
            {/* Alert Comments Grid */}
          </div>
        </div>
        {/*Add Alert Comment Model*/}
        <AddAlertModalComponent
          showmodalAlert={showmodalforAddAlert}
          isModalSaveRunning={isModalSaveRunning}
          saveMethod={saveMethod}
          closeReplyModal={closemodal}
          handleChangeComments={handleChangeComments}
          miniSpinner={miniSpinner}
          handleChange={handleChange}
          allUsersList= {allusersList}
        ></AddAlertModalComponent>
         {/*Add Alert Comment Model*/}

        {/*Reply Modal Compnent */}
        <CommentsReplyComponent
          actions={actions}
          showModalForReply={showModalForReply}
          isModalSaveRunning={isModalSaveRunning}
          saveMethod={saveMethod}
          closeReplyModal={closemodal}
          handleChangeComments={handleChangeComments}
          miniSpinner={miniSpinner}
          selectedRowData={selectedRowData}
          AlertCommentsList={AlertCommentsList}
          handleChange={handleChange}
          allUsersList= {allusersList}
        ></CommentsReplyComponent>
         {/*Reply Modal Compnent */}
      </div>
    </LoadingOverlay>
  );
}