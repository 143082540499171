import { getAllMaterials } from "../../components/shippingMaterials/common";
import { get } from "lodash";
import types from "./types";

const initialState = {
  tableAdminShippingOptions: {},
  tableOptions: {},
  countries: [],
  countriesList: [],
  cities: [],
  userData: [],
  distributionTemperature: [],
  destinationAddrList: [],
  destinationAddrSelect: {},
  finalRecipients: [],
  shippingDeputies: [],
  shippingDeputySelect: [],
  finalRecipientSelect: {},
  internalOrderIdValue: "",
  shipmentSpecificValue: "materialavailableDate",
  shippingCreateDateValue: "",
  distributionTempValue: "",
  shippingCreateCommentValue: "",
  getShippingResponse: [],
  shippingList: [],
  export: [],
  exportselected: [],
  shippingMaterials: [],
  shippingMaterialSelect: [],
  myShippings: [],
  shippingMaterialSelectedValue: {},
  materialShippingResponse: [],
  deputiesShippingResponse: [],
  shippingRequestDetails: [],
  shippingUnderReviewRequestDetails: [],
  deputyShippingDetails: [],
  materialShippingDetails: [],
  shippingActionType: "NEW",
  updateShipping: [],
  materailIdSelect: "",
  sapDeliveryNumValue: "",
  dispatchMONValue: "",
  dispatchLEVValue: "",
  dateOfReceiptValue: "",
  trackingNumValue: "",
  statusQuoValue: "",
  phases: [],
  phasesSelectValue: {},
  updateMaterial: [],
  deleteShipping: "",
  processShippmentList: [],
  errorMessage: null,
  loader: true,
  shippingSearchData: {
    id: "",
    id_request_old: "",
    id_old: "",
    status: "",
    entity: "",
    city: "",
    internal_distribution_number: "",
    created_by: "",
    address_id: "",
    address: "",
    final_receipt: "",
    description: "",
    created_on: "",
    tracking_number: "",
    sap_delivery_number: "",
    approved: false,
    country_id: "",
    valid: false,
    submitted: false,
    startDate: "",
    endDate: "",
    shipmentDateLevStart: "",
    shipmentDateLevEnd: "",
    shipmentDateMomStart: "",
    shipmentDateMomEnd: "",
    dateOfRecipientStart: "",
    dateOfRecipientEnd: "",
    name_english: "",
    country_of_shipper: "",
    type_of_shipment: "",
    prefered_reviewer: "",
  },
  shippmentHistoryLogs: [],
  shippingLogSearchData: {},
  shippingRequestValid: 0,
  requestDeputy: [],
  shippingSearchAutocompleteData: {},
  ShippingsCountry: [],
  ShippingsTwoWeeks: [],
  redirectTo: "",
  attachements: [],
  shipper: [],
  extra: {
    attachment_comment: "",
    inco_term: "",
    type_of_shipment: "",
    importer_of_records: "",
    entity_name: "",
    importer_records_address: "",
    logistics_partner: "",
    prp_use_of_material: "",
    pack_tot_weight: "",
    pack_width: "",
    pack_height: "",
    pack_length: "",
    is_mat_client_used_animal: null,
    is_mat_food_produced_animal: null,
    date_material_available_to_ship: "",
    date_material_needed_on_site: "",
    reviewer_comment: "",
    reviewer_attach_documents: [],
    broker_id: null,
    study_number: "",
    studyNumberDetails: "",
    prefered_reviewer: "",
    validMaterialNeededDate: "",
    handling_type: 0,
    is_shippment_hazardous:null,
    shippment_hazardous_comments:"",
    cost_center_shipment:""
  },
  clientTimeZone:{
    date_material_available_to_ship_string:"",
    date_material_needed_on_site_string:"",
    dispatchMONValue_string:"",
    dispatchLEVValue_string:"",
    dateOfReceiptValue_string:""
  },
  allCurrency: [],
  allUnits: [],
  shippingType: [],
  allContainer: [],
  allContainerList:[],
  brokers: [],
  incoterms: [],
  impoter: [],
  addressSearchFields: {
    state: "",
    entity: "",
  },
  destinationAddressSearchOptions: {
    stateData: [],
    cityData: [],
    entityData: [],
  },
  overallComments: [],
  mailLinkData: null,
  shipperCountry: "",
  AlertCommentsList: [],
  newShippingRequestCommentsList: null,
  allUsers: [],
  logisticPartnerDictionaryData: [],
  shippingReviewersList: [],
};
const shippingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case types.GET_COUNTRIES_LIST:
      return {
        ...state,
        countriesList: action.payload,
      };
    case types.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
      };

    case types.GET_DETAILS:
      return {
        ...state,
        userData: action.payload,
      };

    case types.GET_DISTRIBUTION_TEMPERATURE:
      return {
        ...state,
        distributionTemperature: action.payload,
      };

    case types.GET_DESTINATION_ADDRESS:
      return {
        ...state,
        destinationAddrList: action.payload,
      };

    case types.GET_DESTINATION_ADDR_LIST:
      return {
        ...state,
        destinationAddrList: action.payload,
      };

    case types.DESTINATION_ADDR_SELECT:
      return {
        ...state,
        destinationAddrSelect: action.payload,
      };

    case types.CLEAR_DESTINATION_ADDR_SELECT:
      return {
        ...state,
        destinationAddrSelect: action.payload,
        addressSearchFields: {
          state: "",
          entity: "",
        },
        cities: [],
      };

    case types.GET_FINAL_RECIPIENTS:
      return {
        ...state,
        finalRecipients: action.payload,
      };

    case types.GET_DEPUTIES:
      return {
        ...state,
        shippingDeputies: action.payload,
      };

    case types.FINAL_RECIPIENT_SELECT:
      return {
        ...state,
        finalRecipientSelect: action.payload,
      };

    case types.CLEAR_FINAL_RECEIPIENT_SELECT:
      return {
        ...state,
        finalRecipientSelect: action.payload,
        finalRecipients: [],
      };

    case types.ADD_SHIPPING_DEPUTIES_SELECT:
      return {
        ...state,
        shippingDeputySelect: action.payload,
      };

    case types.CLEAR_SHIPPING_DEPUTIES_SELECT:
      return {
        ...state,
        shippingDeputySelect: action.payload,
      };

    case types.GET_INTERNAL_ORDER_ID_VALUE:
      return {
        ...state,
        internalOrderIdValue: action.payload,
      };

    case types.GET_SHIPMENT_SPECIFIC_VALUE:
      return {
        ...state,
        shipmentSpecificValue: action.payload,
      };

    case types.GET_SHIPPING_CREATE_DATE_VALUE:
      return {
        ...state,
        shippingCreateDateValue: action.payload,
      };

    case types.GET_DISTRIBUTION_TEMP_VALUE:
      return {
        ...state,
        distributionTempValue: action.payload,
      };

    case types.GET_SHIPPING_CREATE_COMMENT_VALUE:
      return {
        ...state,
        shippingCreateCommentValue: action.payload,
      };

    case types.SET_SHIPPING_SEARCH_AUTOCOMPLETE_DATA: {
      return {
        ...state,
        shippingSearchAutocompleteData: action.payload,
      };
    }
    case types.CREATE_SHIPPING:
      return {
        ...state,
        getShippingResponse: action.payload,
      };

    case types.GET_SHIPPINGS:
      return {
        ...state,
        shippingList: action.payload,
      };

    case types.EXPORT_DATA:
      return {
        ...state,
        export: action.payload,
      };

    case types.EXPORT_SELECTED_DATA:
      return {
        ...state,
        exportselected: action.payload,
      };

    case types.GET_SHIPPING_METERIALS:
      return {
        ...state,
        shippingMaterials: action.payload,
      };

    case types.ADD_SHIPPING_MATERIAL_SELECT:
      return {
        ...state,
        shippingMaterialSelect: action.payload,
      };

    case types.GET_MY_SHIPPINGS:
      return {
        ...state,
        myShippings: action.payload,
      };

    case types.SHIPPING_MATERIAL_SELECTED_VALUE:
      return {
        ...state,
        shippingMaterialSelectedValue: action.payload,
      };

    case types.ADD_MATERIAL_SHIPPING_REQUEST:
      return {
        ...state,
        materialShippingResponse: action.payload,
      };

    case types.ADD_DEPUTIES_SHIPPING_REQUEST:
      return {
        ...state,
        deputiesShippingResponse: action.payload,
      };

    case types.SHIPPING_REQUEST_DETAILS:
      // eslint-disable-next-line no-case-declarations
      const {
        attachment_comment,
        inco_term,
        type_of_shipment,
        importer_of_records,
        entity_name,
        importer_records_address,
        logistics_partner,
        prp_use_of_material,
        pack_tot_weight,
        pack_width,
        pack_height,
        pack_length,
        is_mat_client_used_animal,
        is_mat_food_produced_animal,
        date_material_available_to_ship,
        date_material_needed_on_site,
        cost_center_shipment,
        shippingReviewerData: { reviewer_comment, broker_id },
        shippingReviewAttachements,

        recipient_id,
        email,
        first_name,
        last_name,
        title,
        form_of_address,
        phone,

        address_id,
        department,
        building,
        postcode,
        entity,
        sap_delivery_number,
        street,
        name_english,
        city,
        state: country_state,

        study_number,
        study_number_id,
        study_title,
        person_responsible,
        intended_for_pivotal_evidence,
        animal_designation,
        regulatory_standard,
        animals_required,
        project_name,
        activity_type,
        study_comment,
        prefered_reviewer,
        handling_type,
        is_shippment_hazardous,
        shippment_hazardous_comments,
        addressvalid,
        receipvalid,
        countryvalid,
        titlevalid
      } = action.payload[0];
      return {
        ...state,
        shippingRequestDetails: action.payload,
        extra: {
          attachment_comment,
          inco_term,
          type_of_shipment,
          importer_of_records,
          entity_name,
          importer_records_address,
          logistics_partner,
          prp_use_of_material,
          cost_center_shipment,
          pack_tot_weight,
          pack_width,
          pack_height,
          pack_length,
          is_mat_client_used_animal,
          is_mat_food_produced_animal,
          date_material_available_to_ship,
          date_material_needed_on_site,
          reviewer_comment,
          broker_id,
          reviewer_attach_documents: shippingReviewAttachements,
          study_number,
          prefered_reviewer,
          studyNumberDetails: {
            study_number,
            id: study_number_id,
            study_title,
            person_responsible,
            intended_for_pivotal_evidence,
            animal_designation,
            regulatory_standard,
            animals_required,
            project_name,
            activity_type,
            study_comment,
          },
          handling_type,
          is_shippment_hazardous,
          shippment_hazardous_comments,
        },
        finalRecipientSelect: {
          id: recipient_id,
          email,
          first_name,
          last_name,
          title,
          form_of_address,
          phone,
          receipvalid: receipvalid,
          titlevalid:titlevalid
        },
        destinationAddrSelect: {
          id: address_id,
          department,
          building,
          postcode,
          entity,
          sap_delivery_number,
          street,
          name_english,
          city,
          state: country_state,
          addressvalid : addressvalid,
          countryvalid : countryvalid
        },
      };

    case types.SHIPPING_UNDER_REVIEW_REQUEST_DETAILS:
      return {
        ...state,
        shippingUnderReviewRequestDetails:action.payload
      }

    case types.DEPUTY_SHIPPING_REQUEST_DETAILS:
      return {
        ...state,
        deputyShippingDetails: action.payload,
      };
    case types.DEPUTY_SHIPPING_REQUEST_DETAILS_SELECTED:
      return {
        ...state,
        deputyShippingDetails: action.payload,
      };

    case types.MATERIAL_SHIPPING_REQUEST_DETAILS:
      //TODO: Hack to be fixed later
      //There is an issue of material being removed while editing
      //caused by shippingMaterialSelectNotLoading.
      return {
        ...state,
        materialShippingDetails: action.payload,
        shippingMaterialSelect: Array.isArray(action.payload)
          ? getAllMaterials(action.payload)
          : [],
      };

    case types.GET_SHIPPING_ACTION_TYPE:
      return {
        ...state,
        shippingActionType: action.payload,
      };

    case types.UPDATE_SHIPPING:
      return {
        ...state,
        updateShipping: action.payload,
        extra: {
          ...state.extra,
          pack_tot_weight: action.payload.shppingRes[0].pack_tot_weight,
          pack_width: action.payload.shppingRes[0].pack_width,
          pack_height: action.payload.shppingRes[0].pack_height,
          pack_length: action.payload.shppingRes[0].pack_length,
          shippment_hazardous_comments: action.payload.shppingRes[0].shippment_hazardous_comments,
        },
      };

    case types.CLEAR_SHIPPING_MATERIAL_SELECTED_VALUE:
      return {
        ...state,
        shippingMaterialSelectedValue: action.payload,
      };

    case types.MATERIAL_ID_SELECT:
      return {
        ...state,
        materailIdSelect: action.payload,
      };

    case types.SAP_DELIVERY_NUM_VALUE:
      return {
        ...state,
        sapDeliveryNumValue: action.payload,
      };

    case types.DISPATCH_MOV_VALUE:
      return {
        ...state,
        dispatchMONValue: action.payload,
      };

    case types.DISPATCH_LEV_VALUE:
      return {
        ...state,
        dispatchLEVValue: action.payload,
      };

    case types.DATE_OF_RECEIP_VALUE:
      return {
        ...state,
        dateOfReceiptValue: action.payload,
      };

    case types.TRACKING_NUM_VALUE:
      return {
        ...state,
        trackingNumValue: action.payload,
      };

    case types.STATUS_QUO_VALUE:
      return {
        ...state,
        statusQuoValue: action.payload,
      };

    case types.GET_PHASESES:
      return {
        ...state,
        phases: action.payload,
      };

    case types.PHASES_SELECT_VALUE:
      return {
        ...state,
        phasesSelectValue: action.payload,
      };

    case types.UPDATE_MATERIAL:
      return {
        ...state,
        updateMaterial: action.payload,
      };

    case types.ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case types.DELETE_SHIPPING:
      return {
        ...state,
        deleteShipping: action.payload,
      };

    case types.IS_LOADER:
      return {
        ...state,
        isLoader: action.payload,
      };
    case types.PROCESS_SHIPPMENT_LIST: {
      return {
        ...state,
        processShippmentList: action.payload,
      };
    }
    case types.SET_SHIPPING_SEARCH_DATA: {
      return {
        ...state,
        shippingSearchData: action.payload,
      };
    }
    case types.GET_SHIPPMENT_HISTORY: {
      return {
        ...state,
        shippmentHistoryLogs: action.payload,
      };
    }
    case types.SET_SHIPPING_LOG_SEARCH: {
      return {
        ...state,
        shippingLogSearchData: action.payload,
      };
    }

    case types.CLEAR_SHIPPING_REQUEST: {
      return {
        ...state,
        ...initialState,
        brokers: state.brokers,
        countries: state.countries,
        distributionTemperature: state.distributionTemperature,
        incoterms: state.incoterms,
        shippingType: state.shippingType,
        logisticPartnerDictionaryData: state.logisticPartnerDictionaryData,
      };
    }

    case types.UPDATE_SHIPPING_REQUEST_VALID: {
      return {
        ...state,
        shippingRequestValid: action.payload,
      };
    }
    case types.GET_REQURST_BY_DEPUTY: {
      return {
        ...state,
        requestDeputy: action.payload,
      };
    }
    case types.SHIPPING_COUNTRY: {
      return {
        ...state,
        ShippingsCountry: action.payload,
      };
    }
    case types.SHIPPING_TWO_WEEKS: {
      return {
        ...state,
        ShippingsTwoWeeks: action.payload,
      };
    }
    case types.REDIRECT_ACTION: {
      return {
        ...state,
        redirectTo: action.payload,
      };
    }
    case types.SET_ATTACHMENT: {
      return {
        ...state,
        attachements: action.payload,
      };
    }
    case types.SET_SHIPPER: {
      return {
        ...state,
        shipper: action.payload,
      };
    }
    case types.SET_EXTRA: {
      return {
        ...state,
        extra: action.payload,
      };
    }
    case types.SET_MATERIAL_VALID_NEED_DATE: {
      return {
        ...state,
        extra: action.payload,
      };
    }
    case types.GET_ALL_CURRENCY: {
      return {
        ...state,
        allCurrency: action.payload,
      };
    }
    case types.GET_ALL_UNITS: {
      return {
        ...state,
        allUnits: action.payload,
      };
    }
    case types.GET_ALL_CONTAINER: {
      return {
        ...state,
        allContainer: action.payload,
      };
    }
    case types.GET_ALL_CONTAINER_LIST: {
      return {
        ...state,
        allContainerList: action.payload,
      };
    }
    case types.GET_ALL_BROKERS: {
      return {
        ...state,
        brokers: action.payload,
      };
    }
    case types.GET_INCOTERMS: {
      return {
        ...state,
        incoterms: action.payload,
      };
    }
    case types.GET_IMPOTER: {
      return {
        ...state,
        impoter: action.payload,
      };
    }

    case types.SET_ADDRESS_SEARCH_FIELDS: {
      return {
        ...state,
        addressSearchFields: action.payload,
      };
    }
    case types.GET_DESTINATION_ADDRESS_SEARCH_OPTIONS: {
      return {
        ...state,
        destinationAddressSearchOptions: action.payload,
      };
    }
    case types.GET_SHIPPINGS_TYPE: {
      return {
        ...state,
        shippingType: action.payload,
      };
    }
    case types.GET_STUDY_NUMBER_DETAILS: {
      if (get(action, "payload.study_number", false)) {
        return {
          ...state,
          extra: {
            ...state.extra,
            studyNumberDetails: action.payload,
          },
        };
      } else {
        return state;
      }
    }
    case types.SET_OVERALL_COMMENTS: {
      return {
        ...state,
        overallComments: action.payload,
      };
    }
    case types.SET_MAIL_LINK_DATA: {
      return {
        ...state,
        mailLinkData: action.payload,
      };
    }
    case types.SET_SHIPPER_COUNTRY: {
      return {
        ...state,
        shipperCountry: action.payload,
      };
    }
    case types.GET_ALERTCOMMENTS_BY_SHIPPINGREQUEST_ID: {
      return {
        ...state,
        AlertCommentsList: action.payload,
      };
    }
    case types.SET_NEW_SHIPPING_REQUEST_COMMENTS_LIST: {
      return {
        ...state,
        newShippingRequestCommentsList: action.payload,
      };
    }
    case types.GET_ALL_USERS: {
      return {
        ...state,
        allUsers: action.payload,
      };
    }
    case types.SET_MATERIAL_TABLE_OPTIONS: {
      return {
        ...state,
        tableOptions: action.payload,
      };
    }
    case types.SET_ADMIN_SHIPPING_TABLE_OPTIONS: {
      return {
        ...state,
        tableAdminShippingOptions: action.payload,
      };
    }
    case types.GET_LOGISTICS_PARTNER_DICTIONARY_DATA: {
      return {
        ...state,
        logisticPartnerDictionaryData: action.payload,
      };
    }
    case types.SET_CLIENT_TIMEZONE:{
      return {
        ...state,
        clientTimeZone : action.payload
      }
    }
    case types.GET_SHIPPING_REVIEWER_LIST: {
      return {
        ...state,
        shippingReviewersList: action.payload,
      }
    }
    default:
      return state;
  }
};

export default shippingReducer;
